<template>
  <div class="confirmation-screen">
    <h1>
      <!-- LOCALE STRING: As tuas senhas -->
      {{ localeMessages['title'] }}
    </h1>

    <div v-for="(ticket, t) in tickets" :key="'user_ticket_' + t" class="appointment-result">
      <div class="ticket-row space-x-4" :class="{ selected: true, compact: false }">
        <ticket
          :queue="ticket.queueName"
          :ticket="ticket.ticketNumber"
          :wait="true"
          :post="''"
          :white="false"
          class="mr-2"
        ></ticket>
        <div class="p-0.5 font-medium self-center">
          <b>{{ ticket.serviceName }}</b
          ><br />{{ ticket.queueDescription }}
        </div>
      </div>
    </div>

    <l-map id="map" :zoom="zoom" :center="center" :options="mapOptions" style="height: 250px">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker :lat-lng="center"></l-marker>
    </l-map>

    <div class="relative">
      <span class="address font-bold">UC Student Hub</span>
      <button
        class="rounded-full px-2 text-xs font-bold inline-block bg-gray-100 hover:bg-gray-500 hover:text-white text-gray-700 ml-1.5 px-2.5 py-1 cursor-pointer"
        @click="getDirections"
      >
        <!-- LOCALE STRING: Obter Direcções -->
        {{ localeMessages['get_directions'] }}</button
      ><br />
      <span class="address" v-html="address"></span>
    </div>
  </div>
</template>

<script>
import config from '../config'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import Ticket from '../components/Ticket'
export default {
  name: 'ConfirmationScreen',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    ticket: Ticket,
  },
  filters: {},
  data: function() {
    return {
      zoom: 17,
      center: latLng(config.student_hub_gps[0], config.student_hub_gps[1]),
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: config.map_style, //'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      attribution: config.map_copyrights,
      //'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
    }
  },
  computed: {
    localeMessages: function() {
      return this.$store.state.locales['ticket_confirmed']
    },
    address: function() {
      return config.student_hub_address.split('\n').join('<br>')
    },
    config: function() {
      return config
    },
    tickets: function() {
      return this.$store.state.ticketsResult
    },
  },
  mounted() {
    /*let self = this;
    this.$nextTick(function () {
      self.generateMap(config.student_hub_gps[0],config.student_hub_gps[1])
    })*/
  },
  methods: {
    getDirections() {
      let link =
        'https://www.google.com/maps/dir/?api=1&destination=' +
        config.student_hub_gps[0] +
        ',' +
        config.student_hub_gps[1] +
        '&dir_action=navigate'
      window.open(link, '_blank')
    },
  },
}
</script>

<style scoped>
.confirmation-screen #map {
  @apply h-56 rounded-xl overflow-hidden mt-14 mb-4;
}
.confirmation-screen {
  @apply text-left px-2 py-4;
}
.confirmation-screen .time-box {
  @apply font-bold;
}
.confirmation-screen .appointment-result {
  @apply mb-2;
}
.confirmation-screen .input-outside {
  @apply relative;
}
.confirmation-screen textarea,
input {
  @apply w-full px-3 py-2 border-2 border-gray-200 mb-4;
}
.confirmation-screen textarea.prefixed,
input.prefixed {
  @apply pl-14;
}
.confirmation-screen textarea:focus,
input:focus {
  outline-color: rgba(32, 164, 149, 1);
}
.confirmation-screen textarea.invalid:focus,
input.invalid:focus {
  outline-color: #d73939;
}
.confirmation-screen textarea.invalid,
input.invalid {
  outline-color: #d73939;
}
.confirmation-screen h1 {
  @apply text-2xl font-bold mb-10 mt-3 text-center;
}
.confirmation-screen h2 {
  @apply text-lg font-bold mb-1;
}
.email-suggestion {
  @apply rounded-full px-2 py-1 text-xs font-bold inline-block bg-gray-100 text-gray-600 m-1.5 cursor-pointer;
}
.emails-suggestions-box {
  @apply overflow-y-hidden h-10;
}
.contact-input {
  @apply mb-2;
}
.ticket-row {
  @apply flex;
}
.address {
  @apply text-sm;
}
</style>
