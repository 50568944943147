<template>
  <div id="bottom-bar" :class="{ open: modal, close: !modal }">
    <div class="bottom-box" :class="{ modalstd: modal, home: currentPage === 'home', mobile: isMobile }">
      <div v-if="currentModalPage === 'needsAuthentication'" class="attention-stripe"></div>

      <div class="h-full relative">
        <div class="modal-content-std" :class="{ show: modal }">
          <div class="modal-scroll">
            <slot />
          </div>
        </div>
      </div>

      <div class="placeholder sm:h-10 h-32"></div>

      <div class="absolute bottom-4 left-4 right-4">
        <div
          class="bottom-buttons-reactivity"
          :class="{ home: currentModalPage !== 'needsAuthentication' && currentPage === 'home' }"
        >
          <!-- :class="{'prevButton': currentPage!=='home', 'getTicketButton': currentPage==='home'}"-->
          <studenthub-button
            v-if="showBackButton"
            :disabled="networkLoading"
            class="adaptiveButton"
            :class="{ prevButton: currentPage !== 'home' || currentModalPage === 'needsAuthentication' }"
            @click="previous"
          >
            <div
              v-if="currentPage === 'home' && currentModalPage !== 'needsAuthentication' && !isLoggedIn"
              class="lock-indicator"
            >
              <svg
                aria-hidden="true"
                height="12"
                focusable="false"
                data-prefix="fas"
                data-icon="lock"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="fa-lock"
              >
                <path
                  fill="currentColor"
                  d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                  class=""
                ></path>
              </svg>
            </div>

            <span v-if="currentPage === 'home' && currentModalPage !== 'needsAuthentication'">
              <!-- LOCALE STRING: Tirar Senha -->
              {{ localeMessages['get_ticket'] }}
            </span>
            <span v-else>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="arrow-left"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="back-icon"
              >
                <path
                  fill="currentColor"
                  d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
                  class=""
                ></path>
              </svg>
            </span>
          </studenthub-button>
          <studenthub-button :highlight="true" :disabled="disableNext" class="relative" @click="next()">
            <span v-if="currentModalPage === 'needsAuthentication'">
              <!-- LOCALE STRING: Autenticar -->
              {{ localeMessages['authenticate'] }}
            </span>
            <span
              v-else-if="
                currentModalPage === 'appointment_status' ||
                  currentModalPage === 'confirmation_screen' ||
                  currentModalPage === 'confirmation_screen_tickets' ||
                  currentModalPage === 'digital_ticket'
              "
            >
              <!-- LOCALE STRING: Fechar -->
              {{ localeMessages['close'] }}
            </span>
            <span v-else-if="currentPage === 'home'">
              <!-- LOCALE STRING: Fazer Marcação -->
              {{ localeMessages['get_appointment'] }}
            </span>
            <span v-else>
              {{ nextButtonText }}
              <svg
                v-if="showArrow"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                class="next-icon svg-inline--fa fa-chevron-right fa-w-10 fa-3x"
              >
                <path
                  fill="currentColor"
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                  class=""
                ></path>
              </svg>
            </span>
          </studenthub-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Button'
export default {
  name: 'BottomBar',
  components: {
    'studenthub-button': Button,
  },
  props: {
    servicesSelectable: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }
  },
  computed: {
    isMobile() {
      return this.window.width < 640
    },
    showBackButton: function() {
      return (
        this.currentModalPage !== 'confirmation_screen' &&
        this.currentModalPage !== 'confirm_email' &&
        this.currentModalPage !== 'confirmation_screen_tickets' &&
        this.currentModalPage !== 'appointment_status' &&
        this.currentModalPage !== 'digital_ticket'
      )
    },
    nextButtonText: function() {
      return this.$store.getters.nextButtonText
    },
    allowNext() {
      return this.$store.getters.allowPressNext
    },
    disableNext() {
      return !this.$store.getters.allowPressNext
    },
    networkLoading() {
      return this.$store.state.networkLoading
    },
    currentPage() {
      return this.$store.getters.currentPage
    },
    currentModalPage() {
      return this.$store.getters.currentModalPage
    },
    numSelectedServices() {
      return this.$store.state.selectedServices.length
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    showArrow() {
      //return this.nextButtonText === 'Seguinte'
      return !(
        this.currentPage === 'home' ||
        this.currentModalPage === 'needsAuthentication' ||
        this.currentModalPage === 'appointment_contacts' ||
        this.currentModalPage === 'confirm_email' ||
        this.currentModalPage === 'digital_ticket' ||
        this.currentModalPage === 'smartoption_very_urgent'
      )
    },
    localeMessages: function() {
      return this.$store.state.locales['bottom_bar']
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    previous() {
      if (this.networkLoading === false) {
        if (this.currentPage === 'home' && this.isLoggedIn && this.currentModalPage !== 'needsAuthentication') {
          this.$store.dispatch('openPage', 'service_selection_ticket')
        } else if (this.currentPage === 'home' && !this.isLoggedIn && this.currentModalPage !== 'needsAuthentication') {
          //this.$store.dispatch('openPage', 'service_selection_ticket');
          this.$store.commit('modalOpen', 'needsAuthentication')
        } else {
          this.$store.dispatch('goBack')
        }
      }
    },
    next() {
      this.$store.dispatch('nextPage')
      /* if (this.networkLoading === false) {
        if (this.currentModalPage === 'needsAuthentication') {
          this.$store.dispatch('openLoginPage')
        } else if (this.currentPage === 'home') {
          this.$store.dispatch('openPage', 'service_selection_booking')
        } else {
          this.$store.dispatch('nextPage')
        }
      }*/
    },
  },
}
</script>

<style scoped>
.lock-indicator {
  @apply absolute left-3 top-4 bg-gray-200 text-gray-600 rounded-full px-1.5 py-1.5;
}
.attention-stripe {
  width: 120%;
  position: absolute;
  top: 0px;
  height: 14px;
  left: -15px;
  background: repeating-linear-gradient(145deg, #fafafa, #fafafa 17px, #4c4c4cff 17px, #4c4c4cff 34px);
}
.bottom-buttons-reactivity {
  @apply flex space-x-4;
}
.bottom-buttons-reactivity.home {
  @apply flex flex-col space-x-0 space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0;
}
.adaptiveButton {
  @apply w-full sm:w-full h-14 max-h-14 overflow-hidden whitespace-nowrap relative;
  /*
  width: 66px;
  max-width: 66px;
  min-width: 66px;
   */
}
.adaptiveButton.prevButton {
  @apply w-24;
}
.back-icon {
  height: 20px;
  margin-left: auto;
  margin-right: auto;
}
.next-icon {
  height: 1.6rem;
  position: absolute;
  top: 50%;
  margin-top: -0.8rem;
  right: 14px;
}
#bottom-bar {
  @apply fixed bottom-0 left-0 right-0 flex;
  @apply sm:p-6 sm:pb-20 flex flex-col justify-end sm:h-56 h-40;
  transition-property: height;
  transition-timing-function: ease-in-out;
  transition-duration: 0.15s;
  transition-delay: 0s;
  background: rgba(255, 255, 255, 0.93);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9346113445378151) 59%,
    rgba(255, 255, 255, 0.80015756302521) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  /*background-color: rgba(255,255,255,0.93);*/
  /*background: linear-gradient(to top, rgba(255,255,255,0.93) 95%, rgba(255,255,255,0));*/
}
#bottom-bar.close {
  /* transition: max-height 0.15s ease-in-out 0.5s, height 0.15s ease-in-out 0.5s; */
  transition-delay: 0.6s;
}
#bottom-bar.open {
  height: 100%;
  @apply z-50 md:z-0;
}
.selected-services-text {
  @apply text-gray-500 pb-2 text-left;
  @apply hidden sm:block;
}
.selected-services-text.hide {
  @apply hidden sm:hidden;
}

.bottom-box.home.mobile {
  height: 160px;
}

.bottom-box {
  @apply sm:mx-auto sm:max-w-md w-full p-4 bg-white relative;
  @apply sm:shadow-xl sm:rounded-lg shadow;
  @apply text-center border border-gray-200;
  transition: max-height 0.35s ease-in-out 0.25s, height 0.35s ease-in-out 0.25s;
  overflow: hidden;
  /*@apply custom-bottom-bar-height; */
  height: 80px;
}
.bottom-box.home {
  /*sm:custom-bottom-bar-height*/
  /* @apply h-28; */
  height: 80px;
}
.bottom-box.modalstd {
  transition: max-height 0.35s ease-in-out 0s, height 0.35s ease-in-out 0s;
  /* MAX HEIGHT OF MODAL: */
  height: 800px !important;
}
.modal-content-std {
  transition: max-height 0.35s ease-in-out 0s, height 0.35s ease-in-out 0s;
  overflow: hidden;
  max-height: 0px;
}
.modal-content-std.show {
  height: 100%;
  max-height: 2500px;
  @apply sm:pb-16;
}
.modal-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
</style>
