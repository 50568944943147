<template>
  <b-modal
    :active.sync="isOpened"
    :width="640"
    scroll="keep"
    full-screen
    :can-cancel="true"
    class="h-full"
    @close="closeModal"
  >
    <l-map v-if="map !== null" :zoom="map.zoom" :center="map.center" :options="map.options" class="z-10">
      <l-tile-layer :url="map.url" />
      <l-marker :lat-lng="map.withPopup"></l-marker>
    </l-map>
    <div class="absolute bottom-0 left-0 w-full text-center p-3 z-20 flex">
      <fw-button class="m-2" type="black" :expanded="true" @click.native="closeModal">Fechar</fw-button>
      <fw-button class="m-2" type="black" :expanded="true" @click.native="openNativeMapApp"
        >Abrir no dispositivo</fw-button
      >
    </div>
  </b-modal>
</template>

<script>
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

import 'leaflet/dist/leaflet.css'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },

  props: {
    open: {
      type: Boolean,
    },
    title: {
      type: String,
      default: null,
    },
    geoLatLong: {
      type: Object,
      default: function() {
        return {
          lat: 40.2083992,
          long: -8.4239305,
        }
      },
    },
  },
  data() {
    /*const geo = {
      lat: 40.2083992,
      long: -8.4239305,
    }*/
    return {
      assetsPath: process.env.VUE_APP_ROUTER_BASE || '/',
      isOpened: this.open || false,
      map: null,
      lat: 40.2083992,
      long: -8.4239305,
    }
  },

  watch: {
    open(value) {
      this.isOpened = value
    },
  },
  created() {
    this.map = {
      zoom: 13,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: latLng(this.geoLatLong.lat, this.geoLatLong.long),
      withPopup: latLng(this.geoLatLong.lat, this.geoLatLong.long),
      currentZoom: 11.5,
      currentCenter: latLng(this.geoLatLong.lat, this.geoLatLong.long),
      options: {
        zoomSnap: 0.5,
      },
    }
    this.lat = this.geoLatLong.lat
    this.long = this.geoLatLong.long
  },

  methods: {
    closeModal() {
      this.isOpened = false
      this.$emit('close')
    },
    openNativeMapApp() {
      let protocol = this.$device.isiOS ? 'maps' : 'https'
      window.open(protocol + '://maps.google.com/maps?daddr=' + this.lat + ',' + this.long + '&amp;ll=')
    },
  },
}
</script>
