<template>
  <div class="select-time-screen">
    <h2 v-show="false">
      <!-- LOCALE STRING: Todos os horários disponíveis -->
      {{ localeMessages['title'] }}
    </h2>
    <div v-for="(time, t) in timeSlots" :key="'time_' + t">
      <div v-if="typeof timeSlots[t - 1] === 'undefined' || (timeSlots[t - 1] && timeSlots[t - 1].day !== time.day)">
        <h3 class="mt-5 mb-2 opacity-50 font-semibold text-xl">
          {{ time.day | humanDay(languageSelected) }}
        </h3>
        <!-- Day Summary -->
        <div v-if="numSelectedServices > 1 && expanded !== time.day">
          <div v-if="time.holiday" class="time-selector disabled">
            <div class="time-box">
              {{ time.holiday }}
            </div>
          </div>

          <button
            v-for="(day_summary, tsd) in $store.getters.timeSlotsDaySummary(time.day)"
            v-else
            :key="'summary_day_' + tsd"
            class="flex text-left justify-between gap-3 items-center mb-2 bg-gray-100 md:bg-white md:w-full rounded-lg py-2 px-3 md:py-3 md:px-4 cursor-pointer"
            @click="expand(time.day)"
          >
            <div class="flex-1">
              <strong>{{ day_summary.serviceName }}</strong> {{ day_summary.queueName }}
            </div>
            <div>
              <div class="p-2 rounded-lg bg-gray-700 text-white text-center font-semibold text-sm">
                {{ day_summary.counter }}
                <!-- LOCALE STRING: vagas -->
                {{ localeMessages['day_summary_slots'] }}
              </div>
            </div>
          </button>
        </div>
      </div>
      <div
        v-if="time.holiday"
        v-show="numSelectedServices === 1 || (numSelectedServices > 1 && expanded === time.day)"
        class="time-selector cursor-auto disabled"
        :class="{
          compact: numSelectedServices === 1,
          available: time.available,
        }"
      >
        <div class="time-box">
          {{ time.holiday }}
        </div>
        <div v-if="numSelectedServices > 1" class="flex-1 pl-4 font-medium">
          {{ time.queueName }}
        </div>
      </div>
      <div
        v-else
        v-show="numSelectedServices === 1 || (numSelectedServices > 1 && expanded === time.day)"
        class="time-selector"
        :class="{
          disabled: time.disabled === true,
          selected: time.selected === true,
          compact: numSelectedServices === 1,
          available: time.available,
        }"
        @click="selectTimeSlot(t)"
      >
        <div class="time-box" :title="time.isFull ? 'Sem vagas' : ''">
          {{ time.hour.substr(0, 5) }}
        </div>
        <div v-if="numSelectedServices > 1" class="flex-1 pl-4 font-medium">
          {{ time.queueName }}
        </div>
        <div v-if="time.isFull && expanded === time.day" class="pl-4 pr-1 font-medium">
          Sem vagas
        </div>
      </div>

      <div
        v-if="typeof timeSlots[t + 1] === 'undefined' || (timeSlots[t + 1] && timeSlots[t + 1].day !== time.day)"
        class="clear-both"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectTimeScreen',
  filters: {
    /*humanDay: function(date) {
      /*if (!date) return ''
      // Check if it's today
      let todayDate = new Date()
      let today = todayDate.toISOString().slice(0, 10)
      if (date === today) {
        return 'Hoje'
      }
      // Check if it's tomorrow
      const tomorrowDate = new Date(todayDate)
      tomorrowDate.setDate(todayDate.getDate() + 1)
      let tomorrow = tomorrowDate.toISOString().slice(0, 10)
      if (date === tomorrow) {
        return 'Amanhã'
      }
      //Parse other dates
      let months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
      let parts = date.split('-')
      return parts[2] + ' ' + months[Number.parseInt(parts[1]) - 1]*/
    /* return this.$store.getters.humanDay(date)
    },*/
  },
  data: function() {
    return {
      expanded: '',
    }
  },

  computed: {
    languageSelected: function() {
      return this.$store.state.language
    },
    localeMessages: function() {
      return this.$store.state.locales['select_appointments_schedule']
    },
    timeSlots: function() {
      return this.$store.state.timeSlots
    },
    numSelectedServices: function() {
      return this.$store.state.selectedServices.length
    },
  },

  methods: {
    expand(day) {
      this.expanded = day
    },
    selectTimeSlot(i) {
      if (this.timeSlots[i].available) {
        this.$store.dispatch('selectTimeSlot', i)
      }
    },
  },
}
</script>

<style scoped>
.select-time-screen {
  @apply text-left max-w-screen-md mx-auto;
}
.select-time-screen h3 {
  @apply font-bold py-1 text-2xl;
}
.select-time-screen .time-selector {
  @apply flex justify-start bg-white text-gray-800 shadow mb-2 p-4 select-none rounded-lg items-center;
}
.select-time-screen .time-selector.available {
  @apply cursor-pointer;
}
.select-time-screen .time-selector.selected {
  @apply bg-white font-bold shadow-md;
  color: #4fa295;
}
.select-time-screen .time-selector.disabled {
  @apply bg-gray-100 text-gray-400 shadow-none;
}
.select-time-screen .time-selector.compact {
  @apply float-left p-1 h-14 mr-2;
}
.select-time-screen .time-selector .time-box {
  @apply w-16 flex-grow-0 max-w-xs text-center;
}
.select-time-screen .time-selector .big-time-display {
  @apply h-11;
  line-height: 44px;
}
.services_summary {
  @apply bg-white rounded-2xl p-7 mb-3 pb-5 pr-5 relative cursor-pointer shadow-md;
}
.services_summary .expand_icon {
  @apply absolute top-4 right-6 text-xl text-gray-600;
}
.day_summary {
  @apply mb-2 rounded-lg bg-gray-100 inline-block p-1 mr-3 pr-3;
}
.day_summary .summary_title {
  @apply mr-3 bg-gray-700 text-gray-100 px-3 py-1 rounded-full;
}
</style>
