module.exports = Object.freeze({
  endPointURL: 'https://api-dev.uc.pt/qflowplus/1.0',
  endPointURLv2: 'https://api-dev.uc.pt/qflowplus/2.0',
  //selectedServicesIDS: [11, 12, 13, 16],
  /*** Services Location ***/
  student_hub_address: 'Edifício Central Rua Larga\n3004-504 Coimbra ',
  student_hub_gps: [40.208094, -8.423681],
  student_hub_open_hours: [
    /***
     * A ser preenchido em intervalos de tempo (número par de inputs por dia!)
     * Exemplo:
     * Para segunda-feira: ['9:00', '17:00']
     * Quer dizer que está aberto das 9:00 às 17:00
     * No caso de existir pausa para almoço (ou outras) deve-se definir do
     * seguinte modo as horas de abertura:
     * ['9:00', '12:00', '14:00', '17:00']
     * Esta informação será utilizada para disponibilizar o serviço de tirar senhas remoto
     */
    ['9:30', '13:00', '14:00', '17:00'], //Monday
    ['9:30', '13:00', '14:00', '17:00'], //Tuesday
    ['9:30', '13:00', '14:00', '17:00'], //Wednesday
    ['9:30', '13:00', '14:00', '17:00'], //Thursday
    ['9:30', '13:00', '14:00', '17:00'], //Friday
    [], //Saturday
    [], //Sunday
  ],
  student_hub_image: '/img/studenthub-placeholder.jpeg',
  /*** Link Login Page ***/
  login_url: 'https://apps-dev.uc.pt/login',
  dummyAuthToken: '68604f1f493ab318d2cb95ae690fe39c8093c23d9003fd6d230b602830818001',
  /*** MAP STYLE ***
   * ==== Toner Black Map ====
   * map_copyrights: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
   * map_style: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}'
   * ==== Voyager Map ====
   * map_style: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
   * map_copyrights: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
   */
  map_style: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png',
  map_copyrights:
    'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  /*** Activate/Deactivate Dummy Responses ***/
  dummyConfirmPhoneNumber: 'off', //"success", "error", "off"
  dummyMakeAppointment: 'off', //"success", "error", "off"
  /**
   * E-mail webdomains suggestions
   */
  emailsWebdomainSuggestions: [
    '@student.uc.pt',
    '@uc.pt',
    '@dec.uc.pt',
    '@deec.uc.pt',
    '@dei.uc.pt',
    '@fl.uc.pt',
    '@fd.uc.pt',
    '@fct.uc.pt',
    '@fmed.uc.pt',
    '@gmail.com',
    '@sapo.pt',
    '@yahoo.com',
    '@hotmail.com',
    '@msn.com',
    '@live.com',
    '@me.com',
    '@icloud.com',
    '@yahoo.com.br',
    '@outlook.com',
    '@terra.com.br',
    '@luso.pt',
    '@lusoweb.pt',
    '@megamail.pt',
    '@portugalmail.pt',
    '@portugalmail.com',
    '@mail.pt',
    '@hotmail.es',
    '@hotmail.it',
    '@hotmail.de',
    '@yahoo.es',
    '@yahoo.it',
    '@yahoo.de',
    '@live.nl',
    '@live.it',
    '@yahoo.fr',
    '@hotmail.fr',
  ],
})
