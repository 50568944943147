<template>
  <div class="appointment-confirmation-screen">
    <h1>
      <!-- Confirmado! -->
      {{ localeMessages['title'] }}
    </h1>

    <appointment
      v-for="(appointment, a) in appointements"
      :key="'appointment_' + a"
      :language="languageSelected"
      :day="appointment.day"
      :hour="appointment.hour"
      :service-name="appointment.serviceName"
      :queue-name="appointment.queueName"
      :status="appointment.status"
      :appointment-token="appointment.token"
      :cancelation-reason="''"
      :ticketnumber-desc="
        typeof appointment.queueLetter === 'string'
          ? appointment.queueLetter + 'M' + appointment.ticket_number
          : 'M' + appointment.ticket_number
      "
    />

    <l-map id="map" :zoom="zoom" :center="center" :options="mapOptions" style="height: 250px">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker :lat-lng="center"></l-marker>
    </l-map>

    <div class="relative">
      <span class="address font-bold">UC Student Hub</span>
      <button
        class="rounded-full px-2 text-xs font-bold inline-block bg-gray-100 hover:bg-gray-500 hover:text-white text-gray-700 ml-1.5 px-2.5 py-1 cursor-pointer"
        @click="getDirections"
      >
        <!-- Obter Direcções -->
        {{ localeMessages['get_directions'] }}</button
      ><br />
      <span class="address" v-html="address"></span>
    </div>
  </div>
</template>

<script>
import config from '../config'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import Appointment from '../components/Appointment'
export default {
  name: 'AppointmentConfirmationScreen',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    appointment: Appointment,
  },
  /*filters: {
    humanDay: function(date) {
      /*if (!date) return ''
      // Check if it's today
      let todayDate = new Date()
      let today = todayDate.toISOString().slice(0, 10)
      if (date === today) {
        return this.$store.state.locales['time']['today'] //'Hoje'
      }
      // Check if it's tomorrow
      const tomorrowDate = new Date(todayDate)
      tomorrowDate.setDate(todayDate.getDate() + 1)
      let tomorrow = tomorrowDate.toISOString().slice(0, 10)
      if (date === tomorrow) {
        return this.$store.state.locales['time']['tomorrow'] //'Amanhã'
      }
      //Parse other dates
      let months = this.$store.state.locales['time']['months_abbreviated'] //['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
      let parts = date.split('-')
      return parts[2] + ' ' + months[Number.parseInt(parts[1]) - 1]*/
  /* return this.$store.getters.humanDay(date)
    },
  },*/
  data: function() {
    return {
      zoom: 17,
      center: latLng(config.student_hub_gps[0], config.student_hub_gps[1]),
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: config.map_style, //'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      attribution: config.map_copyrights,
      //'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
    }
  },
  computed: {
    languageSelected: function() {
      return this.$store.state.language
    },
    localeMessages: function() {
      return this.$store.state.locales['appointment_confirmed']
    },
    address: function() {
      return config.student_hub_address.split('\n').join('<br>')
    },
    config: function() {
      return config
    },
    appointements: function() {
      return this.$store.state.appointmentResult
    },
  },
  methods: {
    addZero(number) {
      return number < 10 ? '0' + number : number
    },
    addToGoogleCalendar(appointement) {
      if (typeof appointement !== 'undefined' && appointement !== null) {
        let location = encodeURIComponent('UC Student Hub - ' + config.student_hub_address.split('\n').join(' '))
        let date = new Date(appointement.day + 'T' + appointement.hour)
        let str_hour_start = 'T' + this.addZero(date.getUTCHours()) + this.addZero(date.getUTCMinutes()) + '00Z'
        let str_day =
          '' + this.addZero(date.getUTCFullYear()) + this.addZero(date.getUTCMonth()) + this.addZero(date.getUTCDate())
        let endDate = new Date(date.getTime() + 15 * 60000) //add 15 minutes
        let str_hour_end = 'T' + this.addZero(endDate.getUTCHours()) + this.addZero(endDate.getUTCMinutes()) + '00Z'
        let str_date = encodeURIComponent(str_day + str_hour_start + '/' + str_day + str_hour_end)
        let title = encodeURIComponent(appointement.serviceName + ' - ' + appointement.queueName)
        let link =
          'http://www.google.com/calendar/event?action=TEMPLATE&dates=' +
          str_date +
          '&text=' +
          title +
          '&location=' +
          location +
          '&details='
        window.open(link, '_blank')
      }
    },
    getDirections() {
      let link =
        'https://www.google.com/maps/dir/?api=1&destination=' +
        config.student_hub_gps[0] +
        ',' +
        config.student_hub_gps[1] +
        '&dir_action=navigate'
      window.open(link, '_blank')
    },
    /*enerateMap(lat,long) {
      let map = leaflet.map('map', {
        center: [lat, long],
        zoom: 13
      });
      leaflet.marker([lat, long]).addTo(map);
    }*/
  },
}
</script>

<style scoped>
.appointment-confirmation-screen #map {
  @apply h-56 rounded-xl overflow-hidden mt-14 mb-4;
}
.appointment-confirmation-screen {
  @apply text-left px-2 py-4;
}
.appointment-confirmation-screen .time-box {
  @apply font-bold;
}
.appointment-confirmation-screen .appointment-result {
  @apply mb-2;
}
.appointment-confirmation-screen .input-outside {
  @apply relative;
}
.appointment-confirmation-screen textarea,
input {
  @apply w-full px-3 py-2 border-2 border-gray-200 mb-4;
}
.appointment-confirmation-screen textarea.prefixed,
input.prefixed {
  @apply pl-14;
}
.appointment-confirmation-screen textarea:focus,
input:focus {
  outline-color: rgba(32, 164, 149, 1);
}
.appointment-confirmation-screen textarea.invalid:focus,
input.invalid:focus {
  outline-color: #d73939;
}
.appointment-confirmation-screen textarea.invalid,
input.invalid {
  outline-color: #d73939;
}
.appointment-confirmation-screen h1 {
  @apply text-2xl font-bold mb-10 mt-3 text-center;
}
.appointment-confirmation-screen h2 {
  @apply text-lg font-bold mb-1;
}
.email-suggestion {
  @apply rounded-full px-2 py-1 text-xs font-bold inline-block bg-gray-100 text-gray-600 m-1.5 cursor-pointer;
}
.emails-suggestions-box {
  @apply overflow-y-hidden h-10;
}
.contact-input {
  @apply mb-2;
}
.time-row {
  @apply flex;
}
.address {
  @apply text-sm;
}
</style>
