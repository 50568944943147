<template>
  <LayoutDefault :main-sidebar="false" :header-session-nav="true">
    <template #main-content>
      <div class="centered-container">
        <div class="max-w-prose mx-auto">
          <fw-heading size="h1">Perfil</fw-heading>
          <BlockAccountUpdate class="my-5" />
        </div>
      </div>
    </template>
    <template #tapbar>
      <TapbarMain />
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import BlockAccountUpdate from '@/fw-modules/fw-core-vue/id/components/blocks/BlockAccountUpdate'
import TapbarMain from '@/components/tapbars/TapbarMain'

export default {
  components: {
    LayoutDefault,
    TapbarMain,
    BlockAccountUpdate,
  },

  data() {
    return {
      loading: false,
    }
  },
}
</script>
