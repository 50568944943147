var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-time-screen"},[_c('h2',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v(" "+_vm._s(_vm.localeMessages['title'])+" ")]),_vm._l((_vm.timeSlots),function(time,t){return _c('div',{key:'time_' + t},[(typeof _vm.timeSlots[t - 1] === 'undefined' || (_vm.timeSlots[t - 1] && _vm.timeSlots[t - 1].day !== time.day))?_c('div',[_c('h3',{staticClass:"mt-5 mb-2 opacity-50 font-semibold text-xl"},[_vm._v(" "+_vm._s(_vm._f("humanDay")(time.day,_vm.languageSelected))+" ")]),(_vm.numSelectedServices > 1 && _vm.expanded !== time.day)?_c('div',[(time.holiday)?_c('div',{staticClass:"time-selector disabled"},[_c('div',{staticClass:"time-box"},[_vm._v(" "+_vm._s(time.holiday)+" ")])]):_vm._l((_vm.$store.getters.timeSlotsDaySummary(time.day)),function(day_summary,tsd){return _c('button',{key:'summary_day_' + tsd,staticClass:"flex text-left justify-between gap-3 items-center mb-2 bg-gray-100 md:bg-white md:w-full rounded-lg py-2 px-3 md:py-3 md:px-4 cursor-pointer",on:{"click":function($event){return _vm.expand(time.day)}}},[_c('div',{staticClass:"flex-1"},[_c('strong',[_vm._v(_vm._s(day_summary.serviceName))]),_vm._v(" "+_vm._s(day_summary.queueName)+" ")]),_c('div',[_c('div',{staticClass:"p-2 rounded-lg bg-gray-700 text-white text-center font-semibold text-sm"},[_vm._v(" "+_vm._s(day_summary.counter)+" "),_vm._v(" "+_vm._s(_vm.localeMessages['day_summary_slots'])+" ")])])])})],2):_vm._e()]):_vm._e(),(time.holiday)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.numSelectedServices === 1 || (_vm.numSelectedServices > 1 && _vm.expanded === time.day)),expression:"numSelectedServices === 1 || (numSelectedServices > 1 && expanded === time.day)"}],staticClass:"time-selector cursor-auto disabled",class:{
        compact: _vm.numSelectedServices === 1,
        available: time.available,
      }},[_c('div',{staticClass:"time-box"},[_vm._v(" "+_vm._s(time.holiday)+" ")]),(_vm.numSelectedServices > 1)?_c('div',{staticClass:"flex-1 pl-4 font-medium"},[_vm._v(" "+_vm._s(time.queueName)+" ")]):_vm._e()]):_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.numSelectedServices === 1 || (_vm.numSelectedServices > 1 && _vm.expanded === time.day)),expression:"numSelectedServices === 1 || (numSelectedServices > 1 && expanded === time.day)"}],staticClass:"time-selector",class:{
        disabled: time.disabled === true,
        selected: time.selected === true,
        compact: _vm.numSelectedServices === 1,
        available: time.available,
      },on:{"click":function($event){return _vm.selectTimeSlot(t)}}},[_c('div',{staticClass:"time-box",attrs:{"title":time.isFull ? 'Sem vagas' : ''}},[_vm._v(" "+_vm._s(time.hour.substr(0, 5))+" ")]),(_vm.numSelectedServices > 1)?_c('div',{staticClass:"flex-1 pl-4 font-medium"},[_vm._v(" "+_vm._s(time.queueName)+" ")]):_vm._e(),(time.isFull && _vm.expanded === time.day)?_c('div',{staticClass:"pl-4 pr-1 font-medium"},[_vm._v(" Sem vagas ")]):_vm._e()]),(typeof _vm.timeSlots[t + 1] === 'undefined' || (_vm.timeSlots[t + 1] && _vm.timeSlots[t + 1].day !== time.day))?_c('div',{staticClass:"clear-both"}):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }