import CoreActions from '@/fw-modules/fw-core-vue/store/actions'

//import axios from 'axios'
import config from '../config'
import router from '../router'
//import Vue from 'vue'

export default Object.assign(CoreActions, {
  /**
   * !!!DUMMY!!!
   */
  /*toogleDummyAuth(context) {
    if (context.state.auth === null) {
      let token = window.prompt('Tells us your token', config.dummyAuthToken)
      if (token !== null && token.length > 3) {
        context.commit('setAuth', token)
        context.dispatch('setLocalStorage', ['auth', token])
        context.dispatch('loadUserTicketsRemote')

        //Reset navigation
        context.commit('resetNavigation')
      }
    } else {
      context.commit('setAuth', null)
      localStorage.removeItem('auth')
    }
  },*/

  /* SHOW ERROR MESSAGE */
  showError(context, errorMessage) {
    /*if (context.state.errorTimer !== null) {
      clearTimeout(context.state.errorTimer)
    }

    context.commit('setErrorMessage', errorMessage)
    context.commit('setShowError', true)

    //remove error message after 3 seconds
    context.commit(
      'setErrorTimer',
      setTimeout(function() {
        context.commit('setShowError', false)
      }, 3000),
    )*/

    /*if (errorMessage === 'scheduled_call') {
      errorMessage = context.state.locales['error_messages'][errorMessage]
    }*/

    if (context.state.locales['error_messages'][errorMessage]) {
      errorMessage = context.state.locales['error_messages'][errorMessage]
    }

    this._vm.$buefy.toast.open({
      duration: 5000,
      message: errorMessage,
      position: 'is-top',
      type: 'is-danger',
    })
  },
  showSuccess(context, successMessage) {
    this._vm.$buefy.toast.open({
      duration: 5000,
      message: successMessage,
      position: 'is-top',
      type: 'is-primary',
    })
  },

  resetSelectedServices(context) {
    console.log('RESET ALL SERVICES!!!')
    //if(context.state.selectedServices.length>0) {
    //let services = context.state.services;
    /*for (let i = 0; i < services.length; i++) {
        for (let q = 0; q < services[i]['queues']; q++) {
            context.commit('resetSelectService', {q:q,s:i});
            //services[i]['queues'][q]['selected'] = false;
        }
    }*/
    //context.commit('setServices', services);
    context.commit('resetServices')
    context.commit('setSelectedServices', [])
    context.commit('setTimeSlots', [])
    context.commit('setTimeSlotsDayView', [])
    //TODO: reset navigation system

    // }
  },
  /***
   Load user appointments from local storage and delete old ones
   */
  loadUserAppointments(context) {
    console.log('Loading user appointments')
    //load user tickets from localstorage
    if (localStorage.getItem('appointments')) {
      let appointments = JSON.parse(localStorage.getItem('appointments'))

      let now = Date.now()

      let selectedAppointments = []

      //Verify appointments expired
      for (let a = 0; a < appointments.length; a++) {
        let appointmentTimestamp = new Date(appointments[a]['day'] + 'T' + appointments[a]['hour']).getTime()
        if (appointmentTimestamp - now > -720000) {
          //delete appointment
          //We give 12 minutes (720000 milli) tolerance, if an event is 12 minutes old we delete it
          //12 minutes because we try to make the system give a minimum of 15 minutes of interval between events
          selectedAppointments.push(appointments[a])
        }
      }
      context.commit('setUserAppointments', selectedAppointments)
      //To reduce work next time, delete from local storage unecessary data
      context.dispatch('setLocalStorage', ['appointments', selectedAppointments])
    }
  },
  loadUserTicketsRemote(context) {
    if (context.getters.isLoggedIn) {
      context.state.api
        .getUserTickets()
        .then(tickets => {
          console.log('USER TICKETS', tickets)
          context.commit('setUserTickets', tickets)
          //save locally for offline availability
          context.dispatch('setLocalStorage', ['tickets', tickets])
        })
        .catch(e => {
          console.error('There was a problem loading user tickets', e)
        })
    } else {
      console.error('Cannot load user tickets remotely, cause the user is not logged in')
    }
  },
  loadUserTickets(context) {
    if (window.navigator.onLine && context.getters.isLoggedIn) {
      //check if it is online
      //get all new tickets from remote backend
      context.dispatch('loadUserTicketsRemote')
    } else {
      //load user appointments from localstorage
      if (localStorage.getItem('tickets')) {
        let tickets = JSON.parse(localStorage.getItem('tickets'))
        let cleanedTickets = []
        let now = new Date()

        // delete tickets with more than 24h
        for (let i = 0; i < tickets.length; i++) {
          let ticketDate = Date.parse(tickets[i].createdDate)
          let diffTime = Math.abs(ticketDate - now)
          let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
          if (diffDays <= 1) {
            cleanedTickets.push(tickets[i])
          }
        }
        console.log('cleanedTickets', cleanedTickets)
        context.dispatch('replaceDataLocally', ['tickets', cleanedTickets])
        context.commit('setUserTickets', cleanedTickets)
      }
    }
  },
  start(context) {
    //get auth token from localstorage
    /*if (
      localStorage.getItem('auth') !== null &&
      typeof localStorage.getItem('auth') === 'string' &&
      localStorage.getItem('auth').length > 3
    ) {
      let auth = JSON.parse(localStorage.getItem('auth'))
      context.commit('setAuth', auth)
    }*/

    context.dispatch('loadUserAppointments')
    context.dispatch('loadUserTickets')

    //get state from backend
    context
      .dispatch('getServices')
      .then(function() {
        context.dispatch('getQueues')
      })
      .catch(function(err) {
        console.error(err)
      })
  },
  /* NOT WORKING, DONT LIKE THE UX
  selectHomepageService(context, data) {
      if(context.state.homepageServicesSelectable) {
          let selected = context.state.services[data.s]['queues'][data.q].selected;
          if (selected) {
              context.commit('selectService', {
                  s: data.s,
                  q: data.q,
                  selected: false
              })
          } else {
              context.commit('selectService', {
                  s: data.s,
                  q: data.q,
                  selected: true
              })
          }
      }
  },*/
  /* User Clicks */
  selectTimeSlot(context, index) {
    console.log('SELECTING TIME ', index)
    let results = context.state.timeSlots
    results[index].selected = true
    results[index].disabled = false
    let day = results[index].day
    let hour = results[index].hour
    let queueID = results[index].idQueue
    for (let r = 0; r < results.length; r++) {
      if (results[r].idQueue === queueID && index !== r) {
        results[r].selected = false
        results[r].disabled = true
      } else if (results[r].idQueue !== queueID && index !== r && results[r].day === day && results[r].hour === hour) {
        // de-select service at same time
        results[r].selected = false
        results[r].disabled = true
      }
    }
    context.commit('setTimeSlots', results)
  },
  selectAppointmentSubject(context, subject) {
    let selectedSubjects = context.state.selectedSubjects
    let includesIndex = selectedSubjects.indexOf(subject)
    if (includesIndex >= 0) {
      context.commit('removeSelectedSubjects', includesIndex)
    } else {
      context.commit('addSelectedSubjects', subject)
    }
  },
  changeContactTypeAppointment(context, type) {
    if (type === 'email') {
      context.commit('setSMSsent', false)
      context.commit('setSMScode', '')
      context.commit('setSmsConfirmationID', '')
    }
    context.commit('setAppointmentContactValue', '')
    context.commit('setAppointmentContactType', type)
  },

  //NETWORK ACTIONS
  getServices(context) {
    return new Promise((resolve, reject) => {
      context.commit('setNetworkLoading', true)

      context.state.api
        .getServices()
        .then(function(results) {
          let cleanServices = []
          //let selectedServices = config.selectedServicesIDS
          for (let i = 0; i < results.length; i++) {
            let service = results[i]
            //if (selectedServices.includes(service.id)) {
            if (service.active) {
              cleanServices.push({
                name: service.name,
                id: service.id,
                description: service.description,
                contactDescription: service.contactDescription,
                latitude: service.latitude,
                longitude: service.longitude,
                scheduleDescription: service.scheduleDescription,
                workingHours: service.workingHours,
                queues: [],
              })
            }
          }
          context.commit('setServices', cleanServices)
          context.commit('setNetworkLoading', false)
          resolve('ok')
        })
        .catch(function(err) {
          context.commit('setNetworkLoading', false)
          reject(err)
        })
    })
  },
  getQueues(context) {
    return new Promise((resolve, reject) => {
      context.commit('setNetworkLoading', true)

      let promises = []
      for (let s = 0; s < context.state.services.length; s++) {
        let service = context.state.services[s]
        promises.push(context.state.api.getServiceInfo(service.id, true))
      }

      Promise.all(promises)
        .then(function(queuesResults) {
          console.log(queuesResults)
          for (let r = 0; r < queuesResults.length; r++) {
            let queues = queuesResults[r].queues
            // debugger
            console.log(queues)
            let cleanQueues = []
            for (let q = 0; q < queues.length; q++) {
              let queue = queues[q]
              cleanQueues.push({
                id: queue.id,
                order: queue.order,
                name: queue.name, //letra da fila
                description: queue.description,
                allowScheduling: !!queue.allowScheduling,
                slotsFull: !!queue.slotsFull,
                active: !!queue.active,
                allowRemote: !!queue.allowRemote,
                averageCallDurationSeconds: queue.averageCallDurationSeconds,
                currentTicketNumber: queue.currentTicketNumber,
                workstationName: typeof queue.workstationName === 'string' ? queue.workstationName : '', //posto
                workingHours: queue.workingHours,
                //for calling:
                lastAlertDate: queue.lastAlertDate,
                queueWaitingTickets: queue.queueWaitingTickets,
                ticketHash: queue.ticketHash,
                subjects: queue.subjects !== null ? queue.subjects : '',

                //FOR UI
                selected: false,
              })
            }
            context.commit('setQueues', {
              s: r,
              queues: cleanQueues,
            })
          }
          context.commit('setNetworkLoading', false)
          context.commit('systemReady', true)
          resolve('ok')
        })
        .catch(function(err) {
          context.commit('setNetworkLoading', false)
          reject(err)
        })
    })
  },
  getTimeSlots(context) {
    return new Promise(function(resolve, reject) {
      context.commit('setNetworkLoading', true)
      //ir buscar time slots
      let promises = []
      let numServices = context.state.selectedServices.length
      if (numServices === 0) {
        resolve()
      } else {
        for (let i = 0; i < numServices; i++) {
          promises.push(context.state.api.getQueueSlots(context.state.selectedServices[i]['qid']))
        }

        Promise.all(promises)
          .then(function(results) {
            let timeSlots = []
            let dayInfo = []
            //TODO: TRY TO IMPROVE PERFORMANCE!!!
            //loop for each day
            for (let s = 0; s < results[0].slots.length; s++) {
              let day = results[0].slots[s].day
              let numSlotsDay = 0
              let firstSlots = []
              for (let r = 0; r < numServices; r++) {
                //extract time slots for this day in each result
                let serviceData = context.state.selectedServices[r]
                let queueName = serviceData.q_name
                let serviceName = serviceData.s_name
                let idQueue = serviceData.qid
                let queueLetter = serviceData.queueLetter
                // console.log('serviceData', serviceData)

                let daySlotsLen = results[r].slots[s].hours.length
                if (daySlotsLen === 0 && results[r].slots[s].holiday) {
                  let timeSlot = {
                    hour: '00:00:00',
                    holiday: results[r].slots[s].holiday,
                    day: day,
                    queueName: queueName,
                    queueLetter: queueLetter,
                    serviceName: serviceName,
                    idQueue: idQueue,
                    disabled: true,
                    selected: false,
                    selectedSubject: serviceData.selectedSubject,
                    available: false,
                    isFull: false,
                  }
                  if (firstSlots.length < 6) {
                    firstSlots.push(timeSlot)
                  }
                  timeSlots.push(timeSlot)
                }
                // console.log('Day data: ', results[r].slots[s])

                let t = 0
                for (t; t < daySlotsLen; t++) {
                  let timeData = results[r].slots[s].hours[t]
                  // console.log('Time data: ', timeData)
                  let timeSlot = {
                    hour: timeData.hour,
                    day: day,
                    queueName: queueName,
                    queueLetter: queueLetter,
                    serviceName: serviceName,
                    idQueue: idQueue,
                    disabled: !timeData.is_available,
                    selected: false,
                    selectedSubject: serviceData.selectedSubject,
                    available: timeData.is_available,
                    isFull: timeData.is_full,
                  }
                  if (results[r].slots[s].allow_scheduling && timeData.is_available) {
                    numSlotsDay++
                  }
                  if (firstSlots.length < 6) {
                    firstSlots.push(timeSlot)
                  }
                  timeSlots.push(timeSlot)
                }
              }
              //Save day info
              dayInfo.push({
                day: day,
                hours: firstSlots,
                numSlots: numSlotsDay,
              })
            }

            //TODO: ORDER TIME SLOTS!
            timeSlots.sort(function(a, b) {
              return a.day.localeCompare(b.day) || a.hour.localeCompare(b.hour)
            })
            //console.log(timeSlots);
            context.commit('setTimeSlots', timeSlots)
            context.commit('setTimeSlotsDayView', dayInfo)
            context.commit('setNetworkLoading', false)
            resolve('ok')
          })
          .catch(function(err) {
            context.commit('setNetworkLoading', false)
            console.error(err)
            reject(err)
          })
      }
    })
  },
  makeAppointment(context) {
    let errorMessageString = context.state.locales['error_messages']['makeAppointment']

    context.commit('setNetworkLoading', true)

    // let userContact = context.state.appointmentContactValue
    let userContactType = context.state.appointmentContactType
    let details = context.state.appointmentDetails
    let phoneNumber = context.state.appointmentPhoneNumber
    let isLoggedIn = context.getters.isLoggedIn
    let smsCode = context.state.smsCode
    let smsConfirmationID = context.state.smsConfirmationID

    //TODO: Add subjects to the right service!!
    //TODO: Add service name and queue name information
    let subjects = context.state.selectedSubjects //normal flux
    let mainSubjects = context.state.selectedSubjectsMain //normal flux

    if (subjects.length === 0) {
      subjects = ['Outros']
    }

    let selectedTimeSlots = context.state.timeSlots.filter(value => {
      return value.selected
    })

    console.log('selectedTimeSlots', selectedTimeSlots)

    if (mainSubjects.length === 0) {
      for (let i = 0; i < selectedTimeSlots.length; i++) {
        selectedTimeSlots[i].subjects = subjects
      }
    } else {
      for (let i = 0; i < selectedTimeSlots.length; i++) {
        selectedTimeSlots[i].subjects = selectedTimeSlots[i].selectedSubject
      }
    }

    let requestData = {
      appointments: selectedTimeSlots,
      details: details,
      phoneNumber: '+351' + phoneNumber,
    }

    /*let headers = {}
  
    if (isLoggedIn) {
      headers['Authorization'] = 'Token ' + context.state.auth
    }*/
    if (!context.getters.isLoggedIn) {
      requestData['fullName'] = context.state.appointmentFullName
    }
    if (!context.getters.isLoggedIn) {
      requestData['email'] = context.state.appointmentEmail
    }
    if (userContactType === 'phone' && !isLoggedIn) {
      //requestData['confirmation_id'] = smsConfirmationID
      requestData['confirmationId'] = smsConfirmationID
      requestData['confirmationCode'] = smsCode
      //requestData['confirmation_code'] = smsCode
    }

    console.log('SELECTED TIMES: ', selectedTimeSlots)

    if (config.dummyMakeAppointment === 'success') {
      let dummyResults = selectedTimeSlots.map(el => {
        let elem = el
        elem['token'] = '123123123123'
        return elem
      })
      context.commit('setNetworkLoading', false)
      context.commit('setConfirmationResult', dummyResults)
      context.commit('modalOpen', 'confirmation_screen')
      //save in local storage
      context.dispatch('saveBatchDataLocally', ['appointments', dummyResults])
      //Reload user appointments into the UI
      context.dispatch('loadUserAppointments')
    } else if (config.dummyMakeAppointment === 'error') {
      context.commit('setNetworkLoading', false)
      context.dispatch('showError', errorMessageString)
      context.commit('setConfirmationResult', [])
    } else {
      context.state.api
        .addScheduledCalls(requestData)
        .then(result => {
          if (result.status === 'submitted' || result.status === 'confirmed') {
            let appointments = selectedTimeSlots //result.appointments
            let result_appointments = result.appointments
            //console.log('appointments', appointments)
            //console.log('result_appointments', result_appointments)
            // hidrate tokens and groupids
            for (let i = 0; i < appointments.length; i++) {
              let queueID = appointments[i].idQueue
              for (let j = 0; j < result_appointments.length; j++) {
                //console.log(result_appointments[j])
                //find groupid & token
                if (result_appointments[j].id_queue === queueID) {
                  appointments[i]['token'] = result_appointments[j].token
                  appointments[i]['group_id'] = result_appointments[j].group_id
                  appointments[i]['ticket_number'] = result_appointments[j].ticket_number
                  appointments[i]['queue_letter'] = ''
                  appointments[i]['status'] = result_appointments[j].status
                  appointments[i]['subjects'] = result_appointments[j].subjects
                  break
                }
              }
            }

            context.commit('setConfirmationResult', appointments)
            context.dispatch('saveBatchDataLocally', ['appointments', appointments])
            //Reload user appointments into the UI
            context.dispatch('loadUserAppointments')
            //TODO: verificar se um utilizador c/ sessao iniciada precisa de
            //confirmar e-mail
            if (userContactType === 'email') {
              context.commit('modalOpen', 'confirm_email')
            } else {
              context.commit('modalOpen', 'confirmation_screen')
            }
          } else {
            context.dispatch('showError', errorMessageString)
            context.commit('setConfirmationResult', [])
          }
          context.commit('setNetworkLoading', false)
        })
        .catch(e => {
          console.error(e.response.data)
          if (e.response && e.response.data && typeof e.response.data.property === 'string') {
            context.dispatch('showError', e.response.data.property)
          } else {
            context.dispatch('showError', errorMessageString)
          }
          context.commit('setConfirmationResult', [])
          context.commit('setNetworkLoading', false)
        })
    }
  },
  /* makeAppointment_old(context) { */
  //TODO: Alert the user for errors (ex: if he already have an appointment in that queue)
  /*
    message: "Can't have more than one scheduled call in a queue"
    property: "scheduled_call"
    status: 400
     */

  /*
  -> assunto: subjects: "Assunto 1\nAssunto 2\nAssunto 3"
  -> detalhes
  Enviar marcação:
  POST https://api-dev.uc.pt/qflowplus/1.0/add/scheduled_calls.json
  SE AUTENTICADO:
  Authorization: Token 92691ac1110127680bd9e2546d63e491e9a3e6ad722cdfa12576ec7ca9add99d
  SENDS AS FORM DATA: ===
  idQueue: 49
  day: 2021-07-19
  hour: 09:10:00
  subjects: Matriculas
  details: Teste
  userEmail: teste@teste.com   => SE NAO AUTENTICADO
  RECEIVES as JSON: ===
  day: "2021-07-19"
  details: "Teste"
  hour: "09:10:00"
  id: 3144
  id_queue: 49
  status: "submitted"
  subjects: "Matriculas"
  ticket_number: 1
  token: "ebd2c7046abb84911eb5103674ad79019c733493"
  user_email: "teste@teste.com"
  user_number: null
  */
  /*  context.commit('setNetworkLoading', true)
    let promises = []
    let userContact = context.state.appointmentContactValue
    let userContactType = context.state.appointmentContactType
    let details = context.state.appointmentDetails
    let isLoggedIn = context.getters.isLoggedIn
  
    let subjects = context.state.selectedSubjects.join(',')
    let selectedTimeSlots = context.state.timeSlots.filter(value => {
      return value.selected
    }) */
  /*
    day: "2021-07-23"
  disabled: false
  hour: "09:30:00"
  idQueue: 49
  queueName: "Nomes A e B | SEG. 28"
  selected: true
  serviceName: "1.ª Fase |Matrículas UC"
     */
  /*
    console.log('TIME SELECTED: ', selectedTimeSlots)
  
    for (let i = 0; i < selectedTimeSlots.length; i++) {
      let idQueue = selectedTimeSlots[i].idQueue
      let day = selectedTimeSlots[i].day
      let hour = selectedTimeSlots[i].hour
  
      let headers = {}
      let bodyFormData = new FormData()
      bodyFormData.append('idQueue', idQueue)
      bodyFormData.append('day', day)
      bodyFormData.append('hour', hour)
      bodyFormData.append('subjects', subjects)
      bodyFormData.append('details', details)
  
      if (isLoggedIn) {
        headers['Authorization'] = 'Token ' + context.state.auth
      } else {
        if (userContactType === 'phone') {
          //TODO: ou número de telefone + codigo de validação?
          throw new Error('NOT IMPLEMENTED: phone booking')
        } else if (userContactType === 'email') {
          bodyFormData.append('userEmail', userContact)
        } else {
          throw new Error('Contact type not recognized')
        }
      }
  
      promises.push(
        axios.post(config.endPointURL + '/add/scheduled_calls.json', bodyFormData, {
          headers: headers,
        }),
      )
    }
  
    Promise.all(promises)
      .then(results => {
        console.log(results)
        let data = []
        for (let r = 0; r < results.length; r++) {
          let appointmentData = results[r]
          if (isLoggedIn) {
            appointmentData['confirmed'] = true
          } else {
            appointmentData['confirmed'] = false
          }
          data.push(appointmentData)
          //save in vuex store
          context.commit('addUserAppointment', appointmentData)
        }
  
        //save in local storage
        context.dispatch('saveBatchDataLocally', ['appointments', data])
        context.commit('setNetworkLoading', false)
      })
      .catch(err => {
        console.error(err)
        context.commit('setNetworkLoading', false)
      })
  }, */
  sendSMSCode(context, phoneNumber) {
    context.commit('setSmsConfirmationID', '')
    context.commit('setSMScode', '')

    let errorMessageString = context.state.locales['error_messages']['sendSMSCode']
    if (config.dummyConfirmPhoneNumber === 'success') {
      context.commit('setSMSsent', true)
      context.commit('setSmsConfirmationID', '123123')
    } else if (config.dummyConfirmPhoneNumber === 'error') {
      context.commit('setSMSsent', false)
      context.commit('setSmsConfirmationID', '')
      context.dispatch('showError', errorMessageString)
    } else {
      context.state.api
        .verifyPhoneNumber(phoneNumber, true)
        .then(result => {
          if (result.status === 'sent') {
            context.commit('setSMSsent', true)
            context.commit('setSmsConfirmationID', result.confirmation_id)
          } else {
            console.error(result)
            context.commit('setSMSsent', false)
            context.commit('setSmsConfirmationID', '')
            context.dispatch('showError', errorMessageString)
          }
        })
        .catch(e => {
          console.error(e)
          context.commit('setSMSsent', false)
          context.commit('setSmsConfirmationID', '')
          context.dispatch('showError', errorMessageString)
        })
    }
  },
  getTickets(context) {
    let errorMessageString = context.state.locales['error_messages']['getTickets']
    context.commit('setNetworkLoading', true)
    let promises = []

    let services = context.state.selectedServices
    //console.log(services);
    for (let s = 0; s < services.length; s++) {
      promises.push(context.state.api.getTicket(services[s].qid))
    }

    Promise.all(promises)
      .then(response => {
        //
        for (let i = 0; i < response.length; i++) {
          //Because promises keep the order of the request!
          response[i]['serviceName'] = services[i].s_name
        }
        //console.log(response)
        context.commit('setTicketsConfirmation', response)
        context.commit('modalOpen', 'confirmation_screen_tickets')
        context.commit('setNetworkLoading', false)
        // reload dashboard
        context.dispatch('loadUserTickets')
      })
      .catch(e => {
        context.dispatch('showError', errorMessageString)
        console.error(e)
        context.commit('setNetworkLoading', false)
      })
  },
  getTicketQueue(context, queueID) {
    //console.log('getTicketQueue', queueID)
    context.commit('resetServices')
    //console.log('resetServices', queueID)
    let services = context.state.services
    for (let i = 0; i < services.length; i++) {
      let service = services[i]
      let queues = service.queues
      for (let j = 0; j < queues.length; j++) {
        let queue = queues[j]
        if (queue.id === queueID) {
          //console.log('found Queue!', queueID)
          context.commit('selectService', {
            s: i,
            q: j,
            qid: queueID,
            q_name: queue.description,
            s_name: service.name,
          })
        }
      }
    }
    //console.log('getTickets', queueID)
    context.dispatch('getTickets')
  },
  confirmAppointments(context, groupID) {
    let errorMessageString = context.state.locales['error_messages']['confirmAppointments']
    let successMessageString = context.state.locales['success_messages']['confirmAppointments']

    context.commit('setNetworkLoading', true)
    context.state.api
      .confirmScheduledCalls(groupID)
      .then(() => {
        //console.log(result)
        //TODO: Show message that confirm appointments
        context.dispatch('changeStatusOfAppointments', [groupID, 'confirmed'])
        context.dispatch('showAppointmentsConfirmationModal', groupID)
        context.dispatch('showSuccess', successMessageString)
        context.commit('setNetworkLoading', false)
      })
      .catch(e => {
        console.error(e)
        context.dispatch('showError', errorMessageString)
        context.commit('setNetworkLoading', false)
      })
  },
  cancelAppointment(context, appointmentToken) {
    let errorMessageString = context.state.locales['error_messages']['cancelAppointment']
    let successMessageString = context.state.locales['success_messages']['cancelAppointment']

    context.commit('setNetworkLoading', true)
    let allAppointments = context.state.userAppointments
    context.state.api
      .cancelScheduledCall(appointmentToken)
      .then(() => {
        //console.log('cancel result', result)
        //console.log('allAppointments', allAppointments)
        let updateAppointments = []
        for (let i = 0; i < allAppointments.length; i++) {
          if (allAppointments[i].token !== appointmentToken) {
            updateAppointments.push(allAppointments[i])
          }
        }

        //console.log('updateAppointments', updateAppointments)

        context.dispatch('replaceDataLocally', ['appointments', updateAppointments])
        //context.dispatch('loadUserAppointments')
        context.commit('setUserAppointments', updateAppointments)
        context.dispatch('showSuccess', successMessageString)
        context.commit('setNetworkLoading', false)
      })
      .catch(e => {
        console.error(e)
        context.dispatch('showError', errorMessageString)
        context.commit('setNetworkLoading', false)
      })
  },
  getAppointmentStatus(context, appointmentTicketHash) {
    let errorMessageString = context.state.locales['error_messages']['getAppointmentStatus']
    context.commit('setNetworkLoading', true)
    context.commit('setAppointmentStatus', null)
    return context.state.api
      .getAppointmentByTicketHash(appointmentTicketHash)
      .then(result => {
        context.commit('setNetworkLoading', false)
        context.commit('setAppointmentStatus', result)
      })
      .catch(e => {
        console.error(e)
        context.dispatch('showError', errorMessageString)
        context.commit('setNetworkLoading', false)
        context.commit('setAppointmentStatus', null)
      })
  },
  getAppointmentStatusByGroup(context, groupID) {
    let errorMessageString = context.state.locales['error_messages']['getAppointmentStatus']
    context.commit('setNetworkLoading', true)
    context.commit('setAppointmentStatus', null)
    let now = new Date()
    let endDate = new Date(now.setMonth(now.getMonth() + 1))
    let endDateString = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate()
    return context.state.api
      .getAppointmentsByGroupID(groupID, endDateString)
      .then(result => {
        let data = result.scheduled_calls
        //console.log('result Data: ', result)
        if (Array.isArray(data)) {
          // Hidrate with name of service and queue name
          context.commit('setAppointmentStatus', data)
          context.dispatch('hidrateAppointmentStatusWithNames')
        } else {
          context.dispatch('showError', 'Unexpected Data from response')
        }
        context.commit('setNetworkLoading', false)
      })
      .catch(e => {
        console.error(e)
        context.dispatch('showError', errorMessageString)
        context.commit('setNetworkLoading', false)
        context.commit('setAppointmentStatus', null)
      })
  },
  // check if there is new data about called tickets
  //NOTE! OLNY CALL THIS ON THE HOMEPAGE
  //It will reset the selection of services / queues
  refreshServicesNewTickets(context) {
    //loop through services get new tickets
    let promises = []
    //console.log('GETTING NEW TICKETS DATA')
    //if (context.state.windowsFocused) {
    for (let i = 0; i < context.state.services.length; i++) {
      promises.push(context.state.api.getServiceInfo(context.state.services[i].id))
    }
    Promise.all(promises)
      .then(newtickets => {
        //console.log(newtickets)
        for (let i = 0; i < newtickets.length; i++) {
          let queues = newtickets[i].queues
          for (let j = 0; j < queues.length; j++) {
            queues[j].selected = false
          }
          context.commit('setQueues', {
            s: i,
            queues: queues,
          })
          //console.log(queues)
        }
      })
      .catch(e => {
        console.error(e)
      })
    //}
  },

  // hidrate with service name & queue nane
  hidrateAppointmentStatusWithNames(context) {
    let appointmentStatus = context.state.appointmentStatus
    let services = context.state.services
    //console.log('hidration started', appointmentStatus, services)
    for (let i = 0; i < appointmentStatus.length; i++) {
      let queueID = appointmentStatus[i].id_queue //verificar
      //console.log('searching for ', queueID, 'num services', services.length)
      for (let j = 0; j < services.length; j++) {
        //console.log('num queues', services[j].queues.length)
        for (let k = 0; k < services[j].queues.length; k++) {
          let queue = services[j].queues[k]
          //console.log(queue.id, queueID)
          if (queueID === queue.id) {
            //console.log('found queue!!! ')
            //atualiza nome e queue name
            appointmentStatus[i].queueName = queue.description
            appointmentStatus[i].serviceName = services[j].name
            break
          }
        }
      }
    }
  },

  //change the status of a group of appointments
  changeStatusOfAppointments(context, [groupID, status]) {
    let userAppointments = context.state.userAppointments
    for (let i = 0; i < userAppointments.length; i++) {
      if (userAppointments[i].group_id === groupID) {
        userAppointments[i].status = status
      }
    }
    // save changes locally
    this.dispatch('replaceDataLocally', ['appointments', userAppointments])
    //update userAppointments
    this.commit('setUserAppointments', userAppointments)
  },
  showAppointmentsConfirmationModal(context, groupID) {
    let userAppointments = context.state.userAppointments
    let result = []
    for (let i = 0; i < userAppointments.length; i++) {
      if (userAppointments[i].group_id === groupID) {
        result.push(userAppointments[i])
      }
    }
    //pode haver o caso do utilizador estar a confirmar as marcaççoes noutro dispositivo
    if (result.length > 0) {
      context.commit('setConfirmationResultRaw', result)
      context.commit('modalOpen', 'confirmation_screen')
    }
  },

  //LOCAL STORAGE
  //Add to storage
  replaceDataLocally(context, [key, rawData]) {
    //console.log('REPLACING DATA LOCALLY===')
    //console.log('key: ', key)
    //console.log('rawData: ', rawData)
    localStorage.setItem(key, JSON.stringify(rawData))
  },
  saveBatchDataLocally(context, [key, rawData]) {
    //console.log('SAVING DATA LOCALLY===')
    //console.log('key: ', key)
    //console.log('rawData: ', rawData)
    let cleanDataArray = []

    if (localStorage.getItem(key)) {
      cleanDataArray = JSON.parse(localStorage.getItem(key))
    }

    if (!Array.isArray(rawData)) {
      rawData = [rawData]
    }

    for (let i = 0; i < rawData.length; i++) {
      cleanDataArray.push(rawData[i])
    }

    //ORDER APPOINTMENTS ARRAY
    if (key === 'appointments') {
      cleanDataArray.sort((a, b) => (a.day > b.day ? 1 : a.day === b.day ? (a.hour > b.hour ? 1 : -1) : -1))
    }
    //console.log('cleanDataArray', cleanDataArray)
    if (cleanDataArray.length === 0) {
      //console.log('A guardar array vazio localmente')
      localStorage.setItem(key, '[]')
    } else {
      localStorage.setItem(key, JSON.stringify(cleanDataArray))
    }
  },
  setLocalStorage(context, [key, data]) {
    localStorage.setItem(key, JSON.stringify(data))
  },
  //NAVIGATION
  nextPage(context) {
    //console.log('next pressed!')
    if (context.getters.allowPressNext) {
      //console.log('next allowed')
      let currentModalPage = context.getters.currentModalPage
      //console.log('currentModalPage', currentModalPage)
      /**
       * MODAL PAGES
       */
      if (currentModalPage !== null) {
        if (currentModalPage === 'appointment_details_and_subject' && !context.getters.isLoggedIn) {
          context.commit('modalOpen', 'appointment_contacts')
        } else if (currentModalPage === 'needsAuthentication') {
          //console.log('open login!')
          context.dispatch('openLoginPage')
        } else if (currentModalPage === 'appointment_details_and_subject' && context.getters.isLoggedIn) {
          context.dispatch('makeAppointment')
        } else if (currentModalPage === 'smartoption_very_urgent') {
          if (context.getters.areServicesOpen && context.getters.isLoggedIn) {
            //Get tickets for selected services
            context.dispatch('getTickets')
          }
          //ignore
        } else if (
          currentModalPage === 'appointment_contacts' &&
          context.state.appointmentContactType === 'phone' &&
          context.state.smsSent === false
        ) {
          //é necessário validar o número de telemovel
          if (context.getters.isContactValid) {
            //Added country code!
            context.dispatch('sendSMSCode', '+351' + context.state.appointmentContactValue)
          }
        } else if (
          currentModalPage === 'appointment_contacts' &&
          context.state.appointmentContactType === 'phone' &&
          context.state.smsSent === true &&
          context.state.smsCode.length < 6
        ) {
          //blackhole... sms code is not valid
        } else if (currentModalPage === 'appointment_contacts') {
          context.dispatch('makeAppointment')
        } else if (
          currentModalPage === 'confirmation_screen' ||
          currentModalPage === 'confirmation_screen_tickets' ||
          currentModalPage === 'confirm_email' ||
          currentModalPage === 'appointment_status' ||
          currentModalPage === 'digital_ticket'
        ) {
          context.dispatch('resetSelectedServices')
          context.commit('resetNavigation')
          context.commit('setSelectedServices', [])
          context.commit('setAppointmentContactValue', '')
          context.commit('setAppointmentContactType', '')
          context.commit('updateAppointmentDetails', '')
          context.commit('updateAppointmentFullName', '')
          context.commit('updateAppointmentPhoneNumber', '')
          context.commit('updateAppointmentEmail', '')
          router.push({ path: '/' })
          //TODO: isPhoneValidated = false
        }
      } else {
        /**
         * NORMAL PAGES
         */
        let currentPage = context.getters.currentPage
        let numSelectedServices = context.state.selectedServices.length

        if (currentPage === 'home') {
          //console.log('service_selection_booking!!!')
          //TODO: context.dispatch('openPage', 'service_selection_booking')
          context.dispatch('openPage', 'subject_selection_screen')
        } else if (currentPage === 'subject_selection_screen' && context.state.selectedSubjectsMain.length > 0) {
          // Find queues and select them
          // Go to schedule selection
          //console.log('trying to select the right queues')

          let processedQueues = []

          for (let i = 0; i < context.state.selectedSubjectsMain.length; i++) {
            let selectedSubject = context.state.selectedSubjectsMain[i]
            //console.log('selected queue', selectedSubject)
            if (!processedQueues.includes(selectedSubject.queueID)) {
              let subjects = context.state.selectedSubjectsMain
                .filter(elem => elem.queueID === selectedSubject.queueID)
                .map(elem => elem.text)
              //console.log('concat subjects ', subjects)
              context.commit('selectService', {
                s: selectedSubject.serviceIndex, //service index
                q: selectedSubject.queueIndex, //queue index
                qid: selectedSubject.queueID, //queue id
                q_name: selectedSubject.queueName, //q_name
                s_name: selectedSubject.serviceName, //s_name
                queueLetter: selectedSubject.queueLetter,
                subjects: '', //not needed
                selectedSubject: subjects,
              })
              processedQueues.push(selectedSubject.queueID)
            }
          }

          numSelectedServices = context.state.selectedServices.length
          /*
          serviceName: services[i].name,
                queueName: services[i].queues[j].description,
          queueID:20
          queueIndex:0
          serviceID:8
          serviceIndex:1
          text:"assunto 1 "*/
          /* selectService(state, data) {
             let current = state.services[data.s]['queues'][data.q].selected
             let queues = state.services[data.s]['queues']
             if (current) {
               //** new select service
               queues[data.q].selected = false
               //** end - new select service
               state.selectedServices = state.selectedServices.filter(el => el.q !== data.q)
             } else {
               //state.services[data.s]['queues'][data.q].selected = true;
               queues[data.q].selected = true
               let selectServData = {
                 s: data.s,
                 q: data.q,
                 qid: data.qid,
                 q_name: data.q_name,
                 s_name: data.s_name,
                 subjects: data.subjects,
               }
  
               if (data.selectedSubject) {
                 selectServData['selectedSubject'] = data.selectedSubject
               }
  
               state.selectedServices.push(selectServData)
             }
             Vue.set(state.services[data.s], 'queues', queues) */
          if (numSelectedServices === 1) {
            context.dispatch('openPage', 'all_time_options')
          } else {
            context.dispatch('openPage', 'schedule_selection_type')
          }
          context.dispatch('getTimeSlots')
          //context.commit('modalOpen', 'appointment_details_and_subject')
        } else if (currentPage === 'service_selection_booking' && numSelectedServices > 0) {
          if (numSelectedServices === 1) {
            context.dispatch('openPage', 'all_time_options')
          } else {
            context.dispatch('openPage', 'schedule_selection_type')
          }
          context.dispatch('getTimeSlots')
        } else if (currentPage === 'service_selection_ticket' && numSelectedServices > 0) {
          context.dispatch('getTickets')
        } else if (
          (currentPage === 'schedule_selection_type' || currentPage === 'all_time_options') &&
          context.getters.numSelectedTimeSlots === numSelectedServices
        ) {
          // verify if we have subjects to choose
          context.commit('modalOpen', 'appointment_details_and_subject')
        }
      }
    }
  },
  goBack(context) {
    let currentModalPage = context.getters.currentModalPage
    if (currentModalPage !== null) {
      context.commit('modalBack')
    } else {
      context.commit('navigationBack')
    }
  },
  openPage(context, page) {
    context.commit('navigationOpen', page)
  },
  openLoginPage() {
    //context.dispatch('toogleDummyAuth')
    //console.log('open login')
    router.push({ path: 'login' })
    //TODO: CHANGE TO DEFINITIVE LOGIN PAGE
    //let login_redirect = "?came_from="+window.location.href; //https://apps-dev.uc.pt/qflowplus/client/
    //window.open(config.login_url+login_redirect,"_self")
  },
  //SMART OPTIONS
  selectSmartOptions(context, timeSlots) {
    //console.log('selectSmartOptions', timeSlots)
    for (let i = 0; i < timeSlots.length; i++) {
      let index = timeSlots[i].index
      context.dispatch('selectTimeSlot', index)
    }

    //go to next page
    context.commit('modalOpen', 'appointment_details_and_subject')
  },
})
