<template>
  <div class="openHours">
    <div v-if="isScheduleValid">
      <div v-if="weekDay >= 0" class="flex gap-4">
        <!-- Show only the selected day of the week -->
        <div v-if="weekDay === tomorrowIndex" class="week_day">
          {{ localeMessages['tomorrow'] }}
        </div>
        <div v-else class="week_day">{{ weekday(weekDay) }}</div>
        <div class="graphbox">
          <div class="graph-time">
            <div
              v-for="(start, e) in intervalSchedule[weekDay].start"
              :key="'schedule_interval_' + weekDay + '_' + e"
              class="interval-graph"
              :style="{ left: start + '%', width: intervalSchedule[weekDay].length[e] + '%' }"
            ></div>
          </div>
        </div>
        <div v-if="schedule[weekDay].length > 0" class="week_time">
          <span v-for="(hours_interval, h) in schedule[weekDay]" :key="'schedule_hours_' + h"
            >{{ hours_interval }} <span v-if="h % 2 === 0">-&nbsp;</span></span
          >
        </div>
      </div>
      <div
        v-for="(weekday_schedule, i) in schedule"
        v-else
        v-show="weekday_schedule.length > 0"
        :key="'weekday_schedule_' + i"
        class="flex gap-4"
      >
        <div class="week_day">{{ weekday(i) }}</div>
        <div class="graphbox">
          <div class="graph-time">
            <div
              v-for="(start, e) in intervalSchedule[i].start"
              :key="'schedule_interval_' + i + '_' + e"
              class="interval-graph"
              :style="{ left: start + '%', width: intervalSchedule[i].length[e] + '%' }"
            ></div>
          </div>
        </div>
        <div v-if="weekday_schedule.length > 0" class="week_time">
          <span v-for="(hours_interval, h) in weekday_schedule" :key="'schedule_hours_' + h"
            >{{ hours_interval }} <span v-if="h % 2 === 0">-&nbsp;</span></span
          >
        </div>
      </div>
    </div>
    <div v-else>
      Schedule Invalid
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpenHours',
  props: {
    schedule: {
      type: Array,
      default: function() {
        return []
      },
    },
    showNow: {
      type: Boolean,
      default: true,
    },
    weekDay: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    languageSelected: function() {
      return this.$store.state.language
    },
    localeMessages: function() {
      return this.$store.state.locales['time']
    },
    isScheduleValid: function() {
      let schedule = this.schedule
      if (schedule.length !== 7) {
        return false
      }
      for (let i = 0; i < schedule.length; i++) {
        if (schedule[i].length % 2 !== 0) {
          return false
        }
      }
      return true
    },
    intervalSchedule: function() {
      if (this.isScheduleValid) {
        let data = this.convertScheduleToIntervals(this.schedule)
        // console.log(data)
        return data
      } else {
        return []
      }
    },
    tomorrowIndex: function() {
      let weekday_now = new Date().getDay() - 1
      if (weekday_now < 0) {
        weekday_now = 6
      }
      let tomorrow = weekday_now + 1
      if (tomorrow > 6) {
        return 0
      }
      return tomorrow
    },
  },
  methods: {
    convertScheduleToIntervals(schedule) {
      let result = []
      for (let i = 0; i < schedule.length; i++) {
        result.push(this.weekdayInterval(schedule[i]))
      }
      return result
    },
    convertTimeToInt(time) {
      if (typeof time === 'string') {
        let min = 0
        let split = time.split(':')
        min += parseInt(split[1], 10)
        min += parseInt(split[0], 10) * 60
        return min - 240 //delete the first 4 hours
      } else {
        console.error('Time is not a string', time)
        return 0
      }
    },
    weekdayInterval: function(weekday_schedule) {
      //Consideramos que o dia começa às 4:00 e termina às 20:00
      let day_minutes = 1440
      let start = []
      let length = []
      let tmp = 0
      for (let i = 0; i < weekday_schedule.length; i++) {
        if (i % 2 === 0) {
          // start
          tmp = this.convertTimeToInt(weekday_schedule[i])
          start.push((tmp / day_minutes) * 100)
        } else {
          // length
          length.push(((this.convertTimeToInt(weekday_schedule[i]) - tmp + 240) / day_minutes) * 100) // + 240 elimina as ultimas 4 horas do dia
        }
      }
      return {
        start: start,
        length: length,
      }
    },
    weekday: function(index) {
      return this.localeMessages['weekdays'][index]
    },
  },
}
</script>

<style scoped>
.openHours .week_day {
  @apply font-bold h-6 py-2.5;
}
.openHours .week_time {
  @apply h-6 py-2.5;
}
.openHours .graphbox {
  position: relative;
  @apply flex-grow py-3;
}
.openHours .graph-time {
  position: relative;
  @apply h-6 bg-gray-200 w-full rounded-full;
}
.openHours .interval-graph {
  position: absolute;
  @apply h-6 bg-gray-700 top-0 rounded-full;
}
</style>
