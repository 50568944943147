<template>
  <TapbarBase>
    <template #buttons>
      <ButtonTapbar to="/" :exact="true" label="Início">
        <template #svg>
          <svg
            class="flex-no-shrink fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z"
            />
          </svg>
        </template>
      </ButtonTapbar>
      <ButtonTapbar to="/account" label="Conta">
        <template #svg>
          <fw-icon-account-box></fw-icon-account-box>
        </template>
      </ButtonTapbar>
    </template>
  </TapbarBase>
</template>

<script>
import TapbarBase from '@/fw-modules/fw-core-vue/ui/components/tapbars/TapbarBase'
import ButtonTapbar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonTapbar'

export default {
  components: {
    ButtonTapbar,
    TapbarBase,
  },
}
</script>
