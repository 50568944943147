<template>
  <div class="appointment-status-screen">
    <h1>
      <!-- Estado da Marcação -->
      {{ localeMessages['title'] }}
    </h1>

    <div v-if="appointmentData !== null && appointmentData.length > 0">
      <appointment
        v-for="(appointment, a) in appointmentData"
        :key="'appointment_' + a"
        :ticketnumber-desc="appointment.queue_name + appointment.ticket_number"
        :language="languageSelected"
        :day="appointment.day"
        :hour="appointment.hour"
        :service-name="appointment.serviceName"
        :queue-name="appointment.queueName"
        :status="appointment.status"
        :appointment-token="appointment.token"
        :cancelation-reason="appointment.motive"
      />
    </div>
    <div v-else>
      <span class="error-message-modal">
        <!-- A informação sobre a marcação não está de momento disponível.<br />Tenta de novo mais tarde -->
        {{ localeMessages['unnavialable_info'] }}
      </span>
    </div>
  </div>
</template>

<script>
import Appointment from '../components/Appointment'

export default {
  name: 'AppointmentStatusScreen',
  components: {
    appointment: Appointment,
  },
  computed: {
    languageSelected: function() {
      return this.$store.state.language
    },
    localeMessages: function() {
      return this.$store.state.locales['appointment_status']
    },
    appointmentData: function() {
      return this.$store.state.appointmentStatus
    },
  },
  /*mounted() {
    if (this.loading === false) {
      this.loading = true
      this.loadInfo()
    }
    /*let self = this
    this.$store.dispatch('getAppointmentStatus', appointmentTicketHash).then(result => {
      self.appointmentData = result
    })*/
  /*},
  methods: {
    loadInfo: function() {
      let appointmentTicketHash = this.$route.params.appointment_ticket_hash
      console.log('appointmentTicketHash', appointmentTicketHash)
      this.$store.commit('setNetworkLoading', true)
      return this.$store.state.api
        .getAppointmentByTicketHash(appointmentTicketHash)
        .then(result => {
          this.$store.commit('setNetworkLoading', false)
          this.appointmentData = result
        })
        .catch(e => {
          console.error(e)
          this.$store.commit('setNetworkLoading', false)
        })
    },
  },*/
}
</script>

<style scoped>
.appointment-status-screen h1 {
  @apply text-2xl font-bold mb-10 mt-3 text-center;
}
.appointment-status-screen h2 {
  @apply text-lg font-bold mb-1;
}
.appointment-status-screen .error-message-modal {
  @apply text-lg mt-10 text-gray-500 text-center;
}
</style>
