<template>
  <div class="smart-suggestions-screen">
    <!-- Select Service -->
    <div class="services-section">
      <div class="info-msg">
        <!-- LOCALE STRING: Seleccione as suas preferências de modo a encontrarmos o melhor horário. -->
        {{ localeMessages['message'] }}
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 justify-center items-stretch">
        <div class="smart-option" @click="smartOption('comfort')">
          <h3>
            <!-- LOCALE STRING: Conforto -->
            {{ localeMessages['confort_option'] }}
          </h3>
          <span>
            <!-- LOCALE STRING: Ver vagas de horário próximas umas das outras para ser atendido no mesmo dia. -->
            {{ localeMessages['confort_option_message'] }}
          </span>
        </div>

        <div class="smart-option" @click="smartOption('urgent')">
          <h3>
            <!-- LOCALE STRING: Urgente -->
            {{ localeMessages['urgent_option'] }}
          </h3>
          <span>
            <!-- LOCALE STRING: Ver as próximas vagas disponíveis -->
            {{ localeMessages['urgent_option_message'] }}
          </span>
        </div>

        <div
          v-if="ableToChangeToTicket"
          class="smart-option"
          :class="{ disabled: !loggedIn }"
          @click="smartOption('very_urgent')"
        >
          <h3>
            <!-- LOCALE STRING: Muito Urgente -->
            {{ localeMessages['very_urgent_option'] }}
          </h3>
          <span>
            <!-- LOCALE STRING: Quero ser atendido agora -->
            {{ localeMessages['very_urgent_option_message'] }}
          </span>
        </div>
      </div>

      <div class="smart-option see-all mt-4" @click="smartOption('see_all')">
        <h3>
          <!-- LOCALE STRING: Ver todos os horários -->
          {{ localeMessages['see_all_schedule'] }}
        </h3>
      </div>

      <!-- Section clear -->
      <div class="clear-both"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmartSuggestionsScreen',
  computed: {
    currentPage() {
      return this.$store.getters.currentPage
    },
    ableToChangeToTicket() {
      return this.$store.getters.veryUrgentOption
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn
    },
    localeMessages: function() {
      return this.$store.state.locales['smart_schedule_selection']
    },
  },
  mounted() {},
  methods: {
    smartOption(option) {
      console.log(option)
      if (option === 'comfort') {
        this.$store.dispatch('openPage', 'smart_options_comfort')
      } else if (option === 'urgent') {
        this.$store.dispatch('openPage', 'smart_options_urgent')
      } else if (option === 'very_urgent') {
        //TODO: Mostrar modal a indicar que irá mudar para senha e pedir ao user que se desloque aos serviços
        //console.log('#TODO: ', option)
        if (this.$store.getters.isLoggedIn) {
          this.$store.commit('modalOpen', 'smartoption_very_urgent')
        } else {
          this.$store.commit('modalOpen', 'needsAuthentication')
        }
      } else {
        this.$store.dispatch('openPage', 'all_time_options')
      }
    },
  },
}
</script>

<style scoped>
.smart-option.disabled {
  @apply text-gray-700 bg-gray-100;
}
.smart-option span {
  @apply text-gray-400;
}
.smart-option.see-all {
  @apply p-5;
}
.smart-option {
  @apply shadow-md p-10 cursor-pointer bg-white rounded-2xl;
}
.smart-option:hover {
  @apply shadow-xl;
}
.smart-option h3 {
  @apply font-bold text-lg;
}
.select-services-screen {
  @apply max-w-screen-md mx-auto;
}
.services-section {
  @apply text-left;
}
.service-box {
  @apply w-full;
  @apply sm:px-4 px-0 py-4;
}
.service-box h3 {
  @apply font-bold text-xl;
}
.queue {
  @apply flex justify-start my-2;
  @apply float-left sm:float-none mr-2 sm:mr-0;
}
.queue.selectable {
  @apply cursor-pointer select-none;
}
.queue.selectable:hover {
  @apply ring-4 ring-gray-300 ring-opacity-80;
}
.queue h4 {
  @apply self-center pl-4 font-medium;
  @apply hidden sm:inline-block;
}
.queue.selected h4 {
  @apply font-bold;
}
.dashboard {
  background-color: #212121;
  @apply rounded-2xl text-white text-left px-5 pt-3 pb-5;
}
.dashboard h3 {
  @apply font-bold mb-1;
}
.dashboard .dashboard-section {
  @apply sm:w-full sm:w-1/2 relative;
}
.dashboard .dashboard-section .see-more {
  @apply cursor-pointer absolute top-0.5 right-0.5 text-sm font-semibold select-none;
}
.dashboard .dashboard-section .see-more .caret-down {
  @apply h-3.5 inline ml-1;
}
.dashboard .time-box {
  @apply w-16 text-center font-bold;
  min-width: 4rem;
}
.dashboard .next-schedule {
  @apply flex py-2;
}
.dashboard .next-schedule h4 {
  @apply self-center pl-4 font-medium;
}
</style>
