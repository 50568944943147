import CoreMutations from '@/fw-modules/fw-core-vue/store/mutations'

import Vue from 'vue'
import messages from '@/i18n'

export default Object.assign(CoreMutations, {
  setLanguage(state, language) {
    if (['pt', 'en'].includes(language)) {
      state.locales = messages[language]
      state.selecteLanguage = language
    }
  },
  setTicketsConfirmation(state, data) {
    state.ticketsResult = data
  },
  /* SHOW ERROR MESSAGE */
  setShowError(state, data) {
    state.showError = data
  },
  setErrorTimer(state, data) {
    state.errorTimer = data
  },
  setErrorMessage(state, data) {
    state.errorMessage = data
  },

  //NETWORK
  setNetworkLoading(state, status) {
    state.networkLoading = status
  },
  systemReady(state, status) {
    state.systemReady = status
  },

  //data.s => service index in the array
  //data.q => queue index in the array
  //data.selected => true/false
  /*
  data.qid,
  data.q_name,
  data.s_name,
   */
  selectService(state, data) {
    let current = state.services[data.s]['queues'][data.q].selected
    let queues = state.services[data.s]['queues']
    if (current) {
      //** new select service
      queues[data.q].selected = false
      //** end - new select service
      state.selectedServices = state.selectedServices.filter(el => el.q !== data.q)
    } else {
      //state.services[data.s]['queues'][data.q].selected = true;
      queues[data.q].selected = true
      let selectServData = {
        s: data.s,
        q: data.q,
        qid: data.qid,
        q_name: data.q_name,
        s_name: data.s_name,
        subjects: data.subjects,
        queueLetter: typeof data.queueLetter !== 'undefined' ? data.queueLetter : '',
      }

      if (data.selectedSubject) {
        selectServData['selectedSubject'] = data.selectedSubject
      }

      state.selectedServices.push(selectServData)
    }
    Vue.set(state.services[data.s], 'queues', queues)
  },
  resetServices(state) {
    console.log('RESETING SERVICES')
    let services = state.services
    for (let s = 0; s < services.length; s++) {
      for (let q = 0; q < services[s]['queues'].length; q++) {
        services[s]['queues'][q]['selected'] = false
      }
    }
    state.services = services
  },
  /*resetSelectService(state, data) {
      const newRow = state.services[data.s].slice(0)
      // update the value
      newRow['queues'][data.q] = false;
      // update it in the grid
      Vue.set(state.services, data.s, newRow);
      /* let queues = state.services[data.s]['queues'];
      queues[data.q].selected = false;
      Vue.set(state.services[data.s], 'queues', queues);*/
  //state.services[data.s]['queues'][data.q].selected = false;
  //},
  setSelectedServices(state, data) {
    state.selectedServices = data
  },
  addSelectedSubjects(state, subject) {
    state.selectedSubjects.push(subject)
  },
  removeSelectedSubjects(state, index) {
    state.selectedSubjects.splice(index, 1)
  },
  toogleSelectedSubjectMain(state, subject) {
    let find = state.selectedSubjectsMain.findIndex(
      element => element.queueID === subject.queueID && element.text === subject.text,
    )
    if (find >= 0) {
      state.selectedSubjectsMain.splice(find, 1)
    } else {
      state.selectedSubjectsMain.push(subject)
    }
  },
  resetSelectedSubjectsMain(state) {
    state.selectedSubjectsMain.splice(0)
  },
  /*setSelectedServicesArray() {
  },*/
  setServices(state, data) {
    state.services = data
  },
  changeWindowsFocus(state, status) {
    state.windowsFocused = status
  },
  setQueues(state, data) {
    //data.s => service index
    //data.queues => array with the queues
    state.services[data.s]['queues'] = data.queues
  },
  setTimeSlots(state, data) {
    state.timeSlots = data
  },
  setTimeSlotsDayView(state, data) {
    state.timeSlotsDayView = data
  },

  setMapModalOpen(state, status) {
    state.mapModalOpen = status
  },

  allowHomepageServiceSelection(state) {
    state.homepageServicesSelectable = true
  },

  updateAppointmentDetails(state, text) {
    state.appointmentDetails = text
  },

  updateAppointmentEmail(state, text) {
    state.appointmentEmail = text
  },

  updateAppointmentFullName(state, text) {
    state.appointmentFullName = text
  },

  updateAppointmentPhoneNumber(state, text) {
    state.appointmentPhoneNumber = text
  },

  setAppointmentContactType(state, type) {
    state.appointmentContactType = type
  },
  setAppointmentContactValue(state, value) {
    state.appointmentContactValue = value
  },

  //set number of selected services/queues:
  setNumberOfSelectedServices(state, num) {
    state.numServicesSelected = num
  },
  //USER OBJECTS
  //set user tickets
  setUserTickets(state, tickets) {
    state.userTickets = tickets
  },
  //add user ticket
  addUserTicket(state, ticket) {
    state.userTickets.push(ticket)
  },
  //change user Ticket
  changeUserTicket(state, [index, ticket]) {
    Vue.set(state.userTickets, index, ticket)
  },
  //set user appointments
  setUserAppointments(state, appointments) {
    state.userAppointments = appointments
  },
  //add user appointment
  addUserAppointment(state, appointment) {
    state.userAppointments.push(appointment)
  },
  //change user appointment
  changeUserAppointment(state, [index, appointment]) {
    Vue.set(state.userAppointments, index, appointment)
  },
  //change SMS sent status
  setSMSsent(state, sent) {
    state.smsSent = sent
  },
  setSMScode(state, code) {
    state.smsCode = code
  },
  setSmsConfirmationID(state, confirmationID) {
    state.smsConfirmationID = confirmationID
  },
  setConfirmationResult(state, result) {
    //TODO: Hidrate results with serviceName and queueName
    if (!Array.isArray(result)) {
      result = [result]
    }

    /*
    timeSlots Data
    idQueue: 49
    queueName: "Nomes A e B | SEG. 28"
    selected: true
    serviceName: "1.ª Fase |Matrículas UC"
     */
    let selectedTimeSlots = state.timeSlots.filter(value => {
      return value.selected
    })

    /*
     idQueue: 1,
    day: "2021-07-22",
    time: "10:00",
    subjects: ["subject1", "subject2"],
    token: "dssgdfSDFGSGDsd34435",
    id: 213,
     */

    for (let s = 0; s < result.length; s++) {
      //queue_id | idQueue, because I'm pretty sure the backend guys will ask me to change this
      let queue_id = result[s].idQueue ? result[s].idQueue : result[s].queue_id
      let data_found = selectedTimeSlots.filter(e => {
        return e.idQueue === queue_id
      })
      console.log('queue_id: ', queue_id, selectedTimeSlots, data_found)
      if (data_found.length > 0) {
        result[s]['queueName'] = data_found[0]['queueName']
        result[s]['serviceName'] = data_found[0]['serviceName']
      }
    }

    state.appointmentResult = result
  },
  setConfirmationResultRaw(state, result) {
    state.appointmentResult = result
  },
  //result of appointment status
  setAppointmentStatus(state, result) {
    state.appointmentStatus = result
  },
  //set user intention
  setUserIntention(state, data) {
    state.userIntention = data
  },
  navigationBack(state) {
    if (state.navigationPath.length > 0) {
      state.navigationPath.pop()
    }
  },
  navigationOpen(state, page) {
    console.log('NAVIGATION OPEN: ', page)
    state.navigationPath.push(page)
  },
  modalOpen(state, page) {
    console.log('MODAL OPEN: ', page)
    state.modalPath.push(page)
  },
  modalBack(state) {
    if (state.modalPath.length > 0) {
      state.modalPath.pop()
    }
  },
  resetNavigation(state) {
    state.modalPath = []
    state.navigationPath = []
  },
  setAuth(state, authToken) {
    state.auth = authToken
  },
})
