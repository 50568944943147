import Nprogress from 'nprogress'

import store from '@/store'
import Vue from 'vue'

const router = {
  beforeResolve: (to, from, next) => {
    if (to.name) {
      Nprogress.start()
    }
    next()
  },

  afterEach: () => {
    Nprogress.done()
  },

  beforeEach: async (to, from, next) => {
    let continueFunc = async function() {
      // Valida se existe algum token registado no local storage
      const token = localStorage.getItem('session.token')
      if (token) {
        store.state.session.token = token
      }

      // Check websocket auth
      store.commit('checkWebsocket', { token: token })

      if (to.meta && to.meta.redirect) {
        const nextInfo = await to.meta.redirect(to, store.getters.isLoggedIn)
        if (nextInfo) {
          next(nextInfo)
          return
        }
      }

      let requireRoles = []
      let requiresAuth = false
      let requiresNoAuth = false
      let allowPlugin = false
      for (let record of to.matched) {
        const meta = record.meta

        if (meta.redirect) {
          const nextInfo = await meta.redirect(to, store.getters.isLoggedIn)
          if (nextInfo) {
            next(nextInfo)
            return
          }
        }

        if (meta.requiresRole) {
          requireRoles.push(meta.requiresRole)
        }
        if (meta.requiresNoAuth) {
          requiresNoAuth = true
        }
        if (meta.requiresAuth) {
          requiresAuth = true
        }
        if (meta.allowPlugin) {
          allowPlugin = true
        }
      }

      if (store.getters.isLoggedIn) {
        store.dispatch('setUser', { reload: false })
      }

      if (requireRoles.length || requiresAuth) {
        // Valida vistas que obrigam a um utilizador autenticado
        // Caso não aconteça, redirecionar o utilizador para a página de login

        if (store.getters.isLoggedIn) {
          if (!allowPlugin && !requiresNoAuth && !requiresAuth && store.getters.isPlugin) {
            const user = store.getters.getUser
            if (user && user.isAnonymous && token) {
              const tokenItems = token.split('-')
              if (tokenItems.length == 3) {
                next({
                  name: 'live',
                  params: {
                    key: tokenItems[tokenItems.length - 1],
                  },
                })
                return
              }
            }

            next({ name: 'logout' })
            return
          }

          /* TODO add
          else if (token) {
            // TODO we need to load the user or wait for store.dispatch('setUser', { reload: false })
            for (let role of requireRoles) {
              if (!store.state.session.user.roles.includes(role)) {
                next('/forbidden')
                return
              }
            }
          } */
        } else {
          next({
            path: '/login',
            query: { redirect: to.fullPath },
          })
          return
        }
      } else if (requiresNoAuth) {
        // Valida as vistas que não permitam utilizadores autenticados
        // Normalmente, apenas a vista de login, registo e recuperação de password nesta acl.
        // Caso aconteça, redireciona para a home.
        if (store.getters.isLoggedIn) {
          next('/')
          return
        }
      }

      next()
    }
    //Unsaved data warning
    if (store.state.dirtyData) {
      console.log('ROUTER: has dirty data')
      Vue.prototype.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Confirmar',
        title: 'Dados não guardados',
        message: 'A página tem dados que não foram guardados. Tem a certeza que quer mudar de página?',
        onConfirm: async () => {
          store.commit('setDirtyData', false)
          await continueFunc()
        },
        onCancel: () => {
          next(false)
        },
      })
    } else {
      await continueFunc()
    }
  },
}

export default router
