<template>
  <button
    v-if="queue"
    :class="{
      'bg-primary text-white': active,
      selected: selected,
      'opacity-50': disabled,
    }"
    class="queue-card bg-white h-full flex items-center justify-center flex-col p-2 shadow-lg hover:shadow-2xl rounded-xl cursor-pointer"
    :aria-label="queueDescription"
    @click="queueClicked(queueid)"
  >
    <div class="queue_positioner">
      <div class="flex items-center flex-col mt-10 mb-6 w-full">
        <ticket :queue="queue" :ticket="ticket" :post="post" :dense="false"></ticket>
      </div>
      <div class="text-center w-full font-semibold text-sm line-clamp-2 px-3 mb-5 h-10" style="overflow: hidden">
        {{ queueDescription }}
      </div>

      <div class="flex items-center flex-col p-2">
        <fw-button v-if="isLoggedIn && canGetTicket" type="primary" @click.native="getTicket(queueid)">
          <!-- Tirar Senha -->
          {{ localeMessagesCard['get_ticket'] }}
        </fw-button>
        <div v-else-if="canGetTicket === false" class="info-msg-sm">
          <!-- Não é possível tirar senha -->
          {{ localeMessagesCard['ticket_not_available'] }}
        </div>
        <div v-else-if="isLoggedIn === false" class="info-msg-sm">
          <!-- Inicie sessão para -->
          {{ localeMessagesCard['login_to_get_ticket'] }}
          <br />
          <!-- tirar senha -->
          {{ localeMessagesCard['login_to_get_ticket_part2'] }}
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import Ticket from '../components/Ticket'
export default {
  name: 'Queue',
  components: {
    ticket: Ticket,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    queueid: {
      type: Number,
      required: true,
    },
    queue: {
      type: String,
      required: true,
    },
    ticket: {
      type: Number,
      required: true,
    },
    post: {
      type: String,
    },
    queueDescription: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    canGetTicket: {
      type: Boolean,
      default: false,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localeMessages: function() {
      return this.$store.state.locales['ticket']
    },
    localeMessagesCard: function() {
      return this.$store.state.locales['home_page']['queue_card']
    },
    /*dense: function() {
      let len = this.ticketNumber.length + this.queue.length
      return len > 4
    },
    ticketNumber: function() {
      if (this.ticket >= 10 && this.ticket < 100) {
        return '0' + this.ticket
      } else if (this.ticket < 10) {
        return '00' + this.ticket
      } else {
        return '' + this.ticket
      }
    },*/
    postString: function() {
      if (this.wait) {
        return this.localeMessages['wait'] //'Aguarde'
      } else {
        if (this.post !== null && typeof this.post === 'string' && this.post !== '') {
          return this.localeMessages['post'] + ' ' + this.post //'Posto ' + this.post
        } else {
          return '- - -'
        }
      }
    },
  },
  methods: {
    queueClicked: function(id) {
      //console.log('queueClicked', id)
      this.$emit('selected', id)
    },
    getTicket: function(id) {
      // console.log('getticket', id)
      this.$emit('getticket', id)
    },
  },
}
</script>

<style scoped>
.info-msg-sm {
  @apply p-3 bg-gray-100 rounded text-gray-700 text-sm mx-3;
}
.queue-card {
  overflow: hidden;
  position: relative;
  height: 165px;
}
.queue-card .queue_positioner {
  position: absolute;
  top: 0px;
  transition: all 0.6s ease;
}
.queue-card.selected .queue_positioner {
  position: absolute;
  top: -94px;
}

.btn {
  @apply py-1 px-5 mt-2 font-bold w-full text-center text-sm h-12 ring-0;
  @apply text-black cursor-pointer select-none bg-white rounded-lg shadow;
  transition: all 100ms linear, width 0.3s ease-out;
}
.btn.btn-highlight {
  @apply text-white;
  background: rgba(32, 164, 149, 1);
}
</style>
