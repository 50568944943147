import '@/fw-modules/fw-core-vue/main'
import '@/style.scss'
import 'leaflet/dist/leaflet.css'

import '@/store/filters'

// Bug solution for markers not showing in the map
import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
