<template>
  <div class="validate-email-screen">
    <h2>
      <!-- Só mais um passo! -->
      {{ localeMessages['one_step_more'] }}
    </h2>
    <h1>
      <!-- Confirmar e-mail -->
      {{ localeMessages['title'] }}
    </h1>
    <div class="validate-email-icon">
      <svg
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
        stroke-width="1"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="css-i6dzq1"
      >
        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
        <polyline points="22,6 12,13 2,6"></polyline>
      </svg>
    </div>
    <span>
      <!-- Foi enviado um e-mail para -->
      {{ localeMessages['message_part1'] }} <b>{{ email }}</b
      >.<br />
      <!-- Foi enviado um e-mail para -->
      {{ localeMessages['message_part2'] }}
    </span>
    <button class="openWebmailButton" @click="openWebmail">
      <!-- Abrir -->
      {{ localeMessages['open_webmail'] }}
      {{ webmail }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ValidateEmailScreen',
  computed: {
    localeMessages: function() {
      return this.$store.state.locales['confirm_email']
    },
    email: function() {
      return this.$store.state.appointmentContactValue
    },
    emailDomain: function() {
      if (this.email.includes('@')) {
        return this.email.split('@')[1].toLowerCase()
      } else {
        return ''
      }
    },
    webmail: function() {
      if (this.emailDomain === 'gmail.com') {
        return 'GMAIL'
      } else if (this.emailDomain.endsWith('uc.pt')) {
        return 'Webmail UC'
      } else if (this.emailDomain === 'hotmail.com' || this.emailDomain === 'hotmail.fr') {
        return 'Hotmail'
      } else {
        return this.emailDomain
      }
    },
    webmailink: function() {
      if (this.emailDomain === 'gmail.com') {
        return 'https://mail.google.com/'
      } else if (this.emailDomain.endsWith('uc.pt')) {
        return 'https://apps.uc.pt/mail'
      } else if (
        this.emailDomain === 'live.com' ||
        this.emailDomain === 'hotmail.com' ||
        this.emailDomain === 'hotmail.fr'
      ) {
        return 'https://outlook.live.com/'
      } else {
        return 'https://' + this.emailDomain
      }
    },
  },
  methods: {
    openWebmail() {
      window.open(this.webmailink)
    },
  },
}
</script>

<style scoped>
.validate-email-screen {
  @apply text-center p-3 pt-10;
}
.validate-email-screen span {
  @apply text-left block;
}
.validate-email-screen .validate-email-icon {
  @apply text-gray-500 text-center my-16 flex justify-center;
  transform: scale(2);
}

.validate-email-screen h1 {
  @apply text-2xl font-bold mb-4 text-center;
}
.validate-email-screen h2 {
  @apply text-lg mb-2 text-center text-gray-600 font-normal mt-4;
}
.validate-email-screen .openWebmailButton {
  @apply bg-gray-200 text-gray-900 font-bold rounded-full px-6 py-2 mx-auto mt-16 cursor-pointer text-center;
}
</style>
