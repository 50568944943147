<template>
  <label class="option-checkbox" :for="'option_checkbox_' + checkboxID">
    <input :id="'option_checkbox_' + checkboxID" v-model="checkedValue" type="checkbox" @input="$emit('selected')" />
    {{ text }}
    <div class="checkmark"></div>
  </label>
</template>

<script>
//import Card from '@/fw-modules/fw-core-vue/ui/components/cards/Card'
export default {
  name: 'OptionCheckbox',
  /*components: {
    Card: Card,
  },*/
  props: {
    text: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkboxID: function() {
      return this.$vnode.key
    },
    checkedValue: {
      get: function() {
        return this.checked
      },
      // setter
      set: function() {
        //ignore set value
      },
    },
  },
}
</script>

<style scoped>
.option-checkbox {
  @apply py-2 pl-12 pr-2 font-medium select-none w-full h-full flex bg-white justify-center flex-col rounded-lg cursor-pointer relative;
}

.option-checkbox input:checked ~ .option-bg {
  background: #4fa295;
  border: solid 2px #4fa295;
}

.option-checkbox input ~ .option-bg {
  @apply absolute left-1 right-1 top-1 bottom-1;
}

.option-checkbox input {
  @apply absolute opacity-0 h-0 w-0 cursor-pointer;
}
.checkmark {
  @apply w-6 h-6 border-2 rounded-full border-gray-200 border-solid cursor-pointer absolute left-2;
}
/* When the checkbox is checked, add a blue background */
.option-checkbox input:checked ~ .checkmark {
  background: #4fa295;
  border: solid 2px #4fa295;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.option-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.option-checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
