import CoreGetters from '@/fw-modules/fw-core-vue/store/getters'
import config from '@/config'

function convertTimeToInt(time) {
  let min = 0
  let split = time.split(':')
  min += parseInt(split[1], 10)
  min += parseInt(split[0], 10) * 60
  return min
}

export default Object.assign(CoreGetters, {
  isContactValid: state => {
    let contactValue = state.appointmentContactValue
    if (state.appointmentContactType === 'phone') {
      return contactValue.length === 9 && contactValue.startsWith('9')
    } else if (state.appointmentContactType === 'email') {
      //from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(contactValue).toLowerCase())
    } else {
      return false
    }
  },
  nextButtonText: (state, getters) => {
    let localeMessages = state.locales['bottom_bar']

    let currentPage = state.navigationPath[state.navigationPath.length - 1]
    if (state.modalPath.length > 0) {
      let currentModalPage = state.modalPath[state.modalPath.length - 1]
      if (
        currentModalPage === 'appointment_contacts' ||
        (currentModalPage === 'appointment_details_and_subject' && getters.isLoggedIn)
      ) {
        if (
          currentModalPage === 'appointment_contacts' &&
          state.appointmentContactType === 'phone' &&
          state.smsSent === false
        ) {
          return localeMessages['sendSMScode'] //'Enviar código SMS'
        }
        //if autenticated we don't need more info
        return localeMessages['getAppointment'] //'Fazer marcação'
      } else if (currentModalPage === 'smartoption_very_urgent') {
        return localeMessages['getTickets'] // 'Tirar Senhas'
        //else, ignore
      } else if (currentModalPage === 'confirm_email') {
        return localeMessages['confirmLater'] //'Confirmar mais tarde'
      }
    } else if (currentPage === 'service_selection_ticket') {
      //return 'Tirar senhas'
      return localeMessages['getTickets'] // 'Tirar senhas'
    }

    return localeMessages['next'] //'Seguinte'
  },
  allowPressNext: (state, getters) => {
    if (state.networkLoading) {
      return false
    }
    //if (state.navigationPath.length > 0) {
    //Modal page evaluation
    if (state.modalPath.length > 0) {
      let currentModalPage = state.modalPath[state.modalPath.length - 1]
      console.log(currentModalPage, state.appointmentDetails.length)
      if (
        currentModalPage === 'appointment_details_and_subject' &&
        state.appointmentDetails.length > 3 &&
        (getters.isLoggedIn || state.appointmentFullName.length > 2)
      ) {
        return true
      } else if (currentModalPage === 'confirm_email' || currentModalPage === 'digital_ticket') {
        return true
      } else if (
        currentModalPage === 'appointment_contacts' &&
        getters.isLoggedIn === false &&
        state.smsSent &&
        state.smsCode.length != 6
      ) {
        return false
      } else if (currentModalPage === 'appointment_contacts' && getters.isLoggedIn === false) {
        //not logged in: appointment_contacts page only shows when not logged in
        return getters.isContactValid
      } else if (currentModalPage === 'smartoption_very_urgent' && getters.areServicesOpen && getters.isLoggedIn) {
        return true
      } else if (
        currentModalPage === 'needsAuthentication' ||
        currentModalPage === 'confirmation_screen' ||
        currentModalPage === 'confirmation_screen_tickets' ||
        currentModalPage === 'appointment_status'
      ) {
        return true
      } else {
        return false
      }
    }

    if (state.navigationPath.length === 0) {
      //current page is home
      return true
    }

    let currentPage = state.navigationPath[state.navigationPath.length - 1]
    let numSelectedServices = state.selectedServices.length
    let numSelectedTimeSlots = state.timeSlots.filter(value => {
      return value.selected
    }).length
    console.log(numSelectedTimeSlots, '==', numSelectedServices)
    if (currentPage === 'service_selection_booking' && numSelectedServices > 0) {
      return true
    } else if (currentPage === 'subject_selection_screen' && state.selectedSubjectsMain.length > 0) {
      return true
    } else if (currentPage === 'schedule_selection_type' && numSelectedTimeSlots === numSelectedServices) {
      return true
    } else if (currentPage === 'service_selection_ticket' && numSelectedServices > 0) {
      return true
    } else if (currentPage === 'all_time_options' && numSelectedTimeSlots === numSelectedServices) {
      return true
    } else {
      return false
    }
    //} else {
    //  return false
    //}
  },
  numSelectedTimeSlots: state => {
    return state.timeSlots.filter(value => {
      return value.selected
    }).length
  },
  availableSubjects: state => {
    let array = state.selectedServices.flatMap(el => {
      return el.subjects.split('\n')
    })
    return array.filter((item, index) => array.indexOf(item) === index)
  },
  currentPage: state => {
    if (state.navigationPath.length > 0) {
      return state.navigationPath[state.navigationPath.length - 1]
    } else {
      return 'home'
    }
  },
  /*getQueueLetter: state => {
    let self = this
    return function(queue_id) {
      console.log(queue_id)
    }
  },*/
  currentModalPage: state => {
    if (state.modalPath.length > 0) {
      return state.modalPath[state.modalPath.length - 1]
    } else {
      return null
    }
  },
  servicesTicketAllowed: state => {
    let services = state.services
    return services
      .map(serv => {
        let newserv = Object.assign({}, serv)
        newserv['queues'] = serv.queues.filter(queue => {
          return queue.active && queue.allowRemote
        })
        return newserv
      })
      .filter(serv => serv.queues.length > 0)
  },
  servicesScheduleAllowed: state => {
    let services = state.services
    return services
      .map(serv => {
        let newserv = Object.assign({}, serv)
        newserv['queues'] = serv.queues.filter(queue => {
          return queue.allowScheduling || queue.slotsFull
        })
        return newserv
      })
      .filter(serv => serv.queues.length > 0)
  },
  /*appoitnmentSubjects: state => {
    console.log('getAppoitnmentSubjects')
    let result = []
    let services = state.services
    for (let i = 0; i < services.length; i++) {
      console.log('getAppoitnmentSubjects1', services[i].queues)
      for (let j = 0; j < services[i].queues; j++) {
        console.log('getAppoitnmentSubjects2', services[i].queues[j].subjects)
        //let queue = services[i].queues[j].subjects
        //console.log('getAppoitnmentSubjects3')
        //c//onsole.log('queue subjects', queue.subjects)
        //if (queue.active && queue.allowScheduling) {
        //  console.log('getAppoitnmentSubjects4')
        //  console.log('queue.subjects', queue.subjects)
        //  result.concat(queue.subjects)
        //}
      }
    }
    return result.sort()
  },*/
  navigationText: (state, getters) => {
    let localeMessages = state.locales['navigation_text']
    let currentPage = getters.currentPage
    if (currentPage === 'service_selection_booking' || currentPage === 'service_selection_ticket') {
      return localeMessages['select_services'] // 'Seleccione os serviços'
    } else if (currentPage === 'subject_selection_screen') {
      //return 'Escolha um assunto'
      return localeMessages['selectSubject'] // 'Escolha um assunto'
    } else if (currentPage === 'all_time_options') {
      return localeMessages['select_schedule'] // return 'Escolha o horário'
    } else if (
      currentPage === 'schedule_selection_type' ||
      currentPage === 'smart_options_comfort' ||
      currentPage === 'smart_options_urgent'
    ) {
      return localeMessages['schedule_suggestions'] //return 'Sugestões de horários'
    } else {
      return ''
    }
  },
  //SMART OPTIONS
  comfortOptions: state => {
    //Comfort smart options
    //try to get appointments for all services in the same day (difference of 3 hours max)?

    let optionsFound = []

    let timeSlots = state.timeSlots.filter(el => el.isAvailable)
    console.log('timeSlots', timeSlots)
    /*
    day:"2021-08-13"
    disabled:false
    hour:"09:30:00"
    idQueue:49
    queueName:"Nomes A e B | SEG. 28"
    selected:false
    serviceName:"1.ª Fase |Matrículas UC"
     */
    let selectedServices = state.selectedServices
    /*
    q:0
    q_name:"Nomes A e B | SEG. 28"
    qid:49
    s:0
    s_name:"1.ª Fase |Matrículas UC"
    subjects:"Matriculas"
     */

    let currentOptions = {}

    for (let i = 0; i < timeSlots.length; i++) {
      if (optionsFound.length < 6) {
        //Select only 5 options of schedule
        let prevTimeSlot = null
        let timeSlot = timeSlots[i]
        let nextSlot = timeSlots[i + 1]

        //Ignora null timeslots & disabled ones
        if (timeSlot !== null && timeSlot['timeSlot'] !== true) {
          if (i === timeSlots.length - 1) {
            nextSlot = null
          }
          if (i > 1) {
            prevTimeSlot = timeSlots[i - 1]
          }
          //console.log('timeSlot', timeSlot)
          //console.log('nextSlot', nextSlot)
          //console.log('prevTimeSlot', prevTimeSlot)
          if (prevTimeSlot !== null && prevTimeSlot.day !== timeSlot.day) {
            //RESET OPTIONS: we didn't found all services in the same day!
            currentOptions = {}
          }

          //if the next timeslot is from a different service, we save the current option
          if (
            (nextSlot !== null && nextSlot.idQueue !== timeSlot.idQueue) ||
            (prevTimeSlot !== null && prevTimeSlot.idQueue !== timeSlot.idQueue)
          ) {
            /*
            day: "2021-08-31"
            disabled: false
            hour: "15:00:00"
            idQueue: 20
            queueName: "teste 1"
            selected: false
            serviceName: "Teste ines"
             */
            //they must not be at same time
            let usedTime = false
            for (const qid of Object.keys(currentOptions)) {
              if (currentOptions[qid].day === timeSlot.day && currentOptions[qid].hour === timeSlot.hour) {
                usedTime = true
              }
            }
            if (usedTime === false) {
              timeSlot['index'] = i
              currentOptions[timeSlot.idQueue] = timeSlot
            }
          }

          //if we have all the options we save it as option
          if (Object.keys(currentOptions).length === selectedServices.length) {
            let arrayCurrentOptions = []
            for (const qid of Object.keys(currentOptions)) {
              arrayCurrentOptions.push(currentOptions[qid])
            }

            //order by hour
            arrayCurrentOptions.sort(function(a, b) {
              return a.hour.localeCompare(b.hour)
            })

            optionsFound.push(arrayCurrentOptions)
            currentOptions = {}
          }
        }
      }
    }

    //console.log('currentOptions', currentOptions)
    //console.log('CONFORT RESULT:', optionsFound)

    return optionsFound
  },
  urgentOptions: state => {
    let optionsFound = []
    let option = {}
    let selectedServices = state.selectedServices
    let servicesFound = []
    let usedTimes = []

    let timeSlots = state.timeSlots

    for (let i = 0; i < timeSlots.length; i++) {
      let currentTimeSuggestion = timeSlots[i].day + ' ' + timeSlots[i].hour
      if (!servicesFound.includes(timeSlots[i].idQueue) && !usedTimes.includes(currentTimeSuggestion)) {
        option[timeSlots[i].idQueue] = timeSlots[i]
        //set index to be easier to find later
        option[timeSlots[i].idQueue].index = i
        usedTimes.push(currentTimeSuggestion)
        servicesFound.push(timeSlots[i].idQueue)

        if (servicesFound.length === selectedServices.length) {
          //we have found the first option for all services
          break
        }
      }
    }

    let finalOptions = []
    for (const qid of Object.keys(option)) {
      finalOptions.push(option[qid])
    }

    //order options
    finalOptions.sort(function(a, b) {
      return a.day.localeCompare(b.day) || a.hour.localeCompare(b.hour)
    })

    optionsFound.push(finalOptions)
    return optionsFound
  },
  veryUrgentOption: (state, getters) => {
    //verify if its possible to get tickets from the selected services
    let selectedServices = state.selectedServices
    let ticketsAllowed = getters.servicesTicketAllowed

    for (let i = 0; i < selectedServices.length; i++) {
      let queueid = selectedServices[i].qid
      let found = false
      //find queue id in the allowed ticket services
      while (!found) {
        for (let j = 0; j < ticketsAllowed.length; j++) {
          let queues = ticketsAllowed[j].queues
          for (let k = 0; k < queues.length; k++) {
            if (queues[k].id === queueid) {
              found = true
              break
            }
          }
          if (found) {
            break
          }
        }
        if (!found) {
          //we didnt found one of the services... this option is not possible
          return false
        }
      }
    }
    return true
  },
  timeSlotsDaySummary: state => day => {
    let timeSlots = state.timeSlots
    let summary = {}
    let summaryArray = []
    for (let i = 0; i < timeSlots.length; i++) {
      let timeslot = timeSlots[i]
      if (timeslot.day === day && typeof summary[timeslot.idQueue] === 'undefined') {
        summary[timeslot.idQueue] = timeslot
        summary[timeslot.idQueue].counter = 0
      } else if (timeslot.day === day) {
        summary[timeslot.idQueue].counter++
      }
    }
    for (const qid of Object.keys(summary)) {
      summaryArray.push(summary[qid])
    }
    return summaryArray
    //return state.things.find(thing => thing.id === id)
  },
  // Services Schedule
  areServicesOpen: () => {
    let now = new Date()
    let now_min = now.getMinutes() + now.getHours() * 60
    let week_day = now.getDay() - 1
    if (week_day < 0) {
      week_day = 6
    }
    console.log('weekday now ', week_day)
    let week_day_interval = config.student_hub_open_hours[week_day]
    console.log('today schedule ', week_day_interval)
    for (let i = 0; i < week_day_interval.length; i++) {
      if (i % 2 === 0) {
        let start = convertTimeToInt(week_day_interval[i])
        let end = convertTimeToInt(week_day_interval[i + 1])
        console.log('from ', start, ' to ', end)
        console.log('now_min ', now_min)
        if (now_min >= start && now_min <= end) {
          console.log('SERVICES ARE OPEN')
          return true
        }
      }
    }
    console.log('SERVICES ARE CLOSED')
    return false
  },
})
