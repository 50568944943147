import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlQflow
    return api
  },

  async getServices() {
    const response = await this.base().get('/1.0/get/services.json')
    return response.data
  },

  async getServiceInfo(serviceId, preview = true) {
    let data = {
      params: { id: serviceId, preview: preview },
    }
    const response = await this.base().get('/1.0/info/service.json', data)
    return response.data
  },

  async getWorkstations(serviceId) {
    let data = {
      params: { id: serviceId },
    }
    const response = await this.base().get('/1.0/get/workstations.json', data)
    return response.data
  },

  async getAttendancesInfo(serviceId, preview = true) {
    let data = {
      params: { id: serviceId, preview: preview },
    }
    const response = await this.base().get('/1.0/info/attendances.json', data)
    return response.data
  },

  async getAttendances(requestData) {
    let data = {
      params: requestData,
    }
    const response = await this.base().get('/1.0/get/attendances.json', data)
    return response.data
  },

  async getTicket(queueId, phoneNumber = null) {
    /*const data = {
      idQueue: queueId,
      contactNr: phoneNumber,
    }*/
    let bodyFormData = new FormData()
    bodyFormData.append('idQueue', queueId)
    if (phoneNumber !== null) {
      bodyFormData.append('contactNr', phoneNumber)
    }

    const response = await this.base().post('/1.0/add/call.json', bodyFormData)
    return response.data
  },

  //get senhas do utilizador
  async getUserTickets() {
    const response = await this.base().get('/1.0/get/user/calls.json')
    return response.data
  },

  async getQueueSlots(queueId) {
    let data = {
      params: { id: queueId },
    }
    const response = await this.base().get('/1.0/get/queue/slots.json', data)
    return response.data
  },

  async verifyPhoneNumber(phoneNumber) {
    let data = {
      phone_number: phoneNumber,
    }
    const response = await this.base().post('/2.0/verify_phonenumber.json', data)
    return response.data
  },

  async addScheduledCalls(requestData) {
    const response = await this.base().post('/2.0/add/scheduled_calls.json', requestData)
    return response.data
  },

  //Confirm appointment
  async confirmScheduledCalls(groupID) {
    let data = {
      params: {
        token: groupID,
      },
      //token: groupID,
    }
    const response = await this.base().get('/2.0/confirm/scheduled_calls.json', data)
    return response.data
  },

  //Cancel appointment
  async cancelScheduledCall(appointmentToken) {
    let data = {
      params: {
        token: appointmentToken,
      },
    }
    const response = await this.base().get('/1.0/cancel/scheduled_call.json', data)
    return response.data
  },

  //Get appointments by groupid
  async getAppointmentsByGroupID(groupID, endDate, status) {
    let data = {
      params: {
        idGroup: groupID,
        preview: '1',
      },
    }
    if (typeof status === 'string') {
      data['params']['status'] = status
    }
    if (typeof endDate === 'string') {
      data['params']['endDate'] = endDate
    }
    const response = await this.base().get('/1.0/get/scheduled_calls.json', data)
    return response.data
  },

  //Get all appointments of user
  async getAppointmentsOfUser() {
    let data = {
      params: {},
    }
    const response = await this.base().get('/1.0/get/scheduled_calls.json', data)
    return response.data
  },

  //Get appointment by appointment ticket hash
  async getAppointmentByTicketHash(ticketHash) {
    let data = {
      params: {
        ticketHash: ticketHash,
      },
    }
    const response = await this.base().get('/1.0/get/scheduled_call.json', data)
    return response.data
  },

  async getServiceAppointments(idService, startDate = null, endDate = null, preview = true) {
    let data = {
      params: {
        preview: preview,
        idService: idService,
      },
    }

    if (startDate) {
      data.params['startDate'] = startDate
    }

    if (endDate) {
      data.params['endDate'] = endDate
    }

    const response = await this.base().get('/1.0/get/scheduled_calls.json', data)
    return response.data
  },

  async getQueueAppointments(idQueue, startDate = null, endDate = null, preview = true) {
    let data = {
      params: {
        preview: preview,
        idQueue: idQueue,
      },
    }

    if (startDate) {
      data.params['startDate'] = startDate
    }

    if (endDate) {
      data.params['endDate'] = endDate
    }

    const response = await this.base().get('/1.0/get/scheduled_calls.json', data)
    return response.data
  },

  async annulAppointment(appointmentId, motive) {
    let bodyFormData = new FormData()
    bodyFormData.append('motive', motive)
    bodyFormData.append('id', appointmentId)
    const response = await this.base().post('/1.0/annul/scheduled_call.json', bodyFormData)
    return response.data
  },

  async callAppointment(idScheduledCall, idWorkstation) {
    let bodyFormData = new FormData()
    bodyFormData.append('idWorkstation', idWorkstation)
    bodyFormData.append('id', idScheduledCall)
    const response = await this.base().post('/1.0/call/scheduled_calls.json', bodyFormData)
    return response.data
  },

  async callNextTicket(idWorkstation, idQueues) {
    let bodyFormData = new FormData()
    bodyFormData.append('idWorkstation', idWorkstation)
    bodyFormData.append('idQueue', idQueues)
    const response = await this.base().post('/1.0/update/call/next.json', bodyFormData)
    return response.data
  },

  async startCall(ticketHash) {
    let bodyFormData = new FormData()
    bodyFormData.append('ticketHash', ticketHash)
    const response = await this.base().post('/1.0/update/call/start.json', bodyFormData)
    return response.data
  },

  async alertCall(ticketHash) {
    let bodyFormData = new FormData()
    bodyFormData.append('ticketHash', ticketHash)
    const response = await this.base().post('/1.0/update/call/alert.json', bodyFormData)
    return response.data
  },

  async noshowCall(ticketHash) {
    let bodyFormData = new FormData()
    bodyFormData.append('ticketHash', ticketHash)
    const response = await this.base().post('/1.0/update/call/noshow.json', bodyFormData)
    return response.data
  },

  async updateCall(data) {
    const response = await this.base().post('/1.0/update/call.json', data)
    return response.data
  },

  async endCall(ticketHash) {
    let bodyFormData = new FormData()
    bodyFormData.append('ticketHash', ticketHash)
    const response = await this.base().post('/1.0/update/call/end.json', bodyFormData)
    return response.data
  },

  async searchUsers(searchType, searchText) {
    let data = {
      params: {
        type: searchType,
        value: searchText,
      },
    }

    const response = await this.base().get('/1.0/search/users.json', data)
    return response.data
  },

  async getServicesStats() {
    const response = await this.base().get('/1.0/get/services/stats.json')
    return response.data
  },

  async addWorkstation(idService, name) {
    let bodyFormData = new FormData()
    bodyFormData.append('idService', idService)
    bodyFormData.append('name', name)
    const response = await this.base().post('/1.0/add/workstation.json', bodyFormData)
    return response.data
  },

  async updateWorkstation(idWorkstation, name) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', idWorkstation)
    bodyFormData.append('name', name)
    const response = await this.base().post('/1.0/update/workstation.json', bodyFormData)
    return response.data
  },

  async deleteWorkstation(idWorkstation) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', idWorkstation)
    const response = await this.base().delete('/1.0/delete/workstation.json', { data: bodyFormData })
    return response
  },

  async addService(data) {
    let bodyFormData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      if (typeof value != 'boolean' && !value) {
        continue
      }
      bodyFormData.append(key, value)
    }
    const response = await this.base().post('/1.0/add/service.json', bodyFormData)
    return response.data
  },

  async updateService(data) {
    let bodyFormData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      if (typeof value != 'boolean' && !value) {
        continue
      }
      bodyFormData.append(key, value)
    }

    const response = await this.base().post('/1.0/update/service.json', bodyFormData)
    return response.data
  },

  async getServiceDevices(idService) {
    let data = {
      params: {
        idService: idService,
      },
    }

    const response = await this.base().get('/1.0/get/devices.json', data)
    return response.data
  },

  async getQueueOperators(idQueue) {
    let data = {
      params: {
        id: idQueue,
      },
    }

    const response = await this.base().get('/1.0/get/queue/operators.json', data)
    return response.data
  },

  async updateQueueOperators(idQueue, operatorIds) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', idQueue)
    bodyFormData.append('operatorIds', operatorIds)
    const response = await this.base().post('/1.0/update/queue/operators.json', bodyFormData)
    return response.data
  },

  async getServiceOperators(idService) {
    let data = {
      params: {
        id: idService,
      },
    }

    const response = await this.base().get('/1.0/get/users.json', data)
    return response.data
  },

  async addServiceOperator(idService, operatorKey, type) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', idService)
    bodyFormData.append('key', operatorKey)
    bodyFormData.append('typeUser', type)
    const response = await this.base().post('/1.0/add/user.json', bodyFormData)
    return response.data
  },

  async deleteServiceOperator(idService, operatorKey) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', idService)
    bodyFormData.append('key', operatorKey)
    const response = await this.base().delete('/1.0/delete/user.json', { data: bodyFormData })
    return response
  },

  async getOperatorAbsences(operatorKey) {
    let data = {
      params: {
        userKey: operatorKey,
      },
    }
    const response = await this.base().get('/1.0/get/absences.json', data)
    return response.data
  },

  async addOperatorAbsences(operatorKey, startDate, endDate) {
    let bodyFormData = new FormData()
    bodyFormData.append('userKey', operatorKey)
    bodyFormData.append('startDate', startDate)
    bodyFormData.append('endDate', endDate)
    const response = await this.base().post('/1.0/add/absences.json', bodyFormData)
    return response.data
  },

  async deleteOperatorAbsences(absenceId) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', absenceId)
    const response = await this.base().delete('/1.0/delete/absences.json', { data: bodyFormData })
    return response.data
  },

  async addQueue(data) {
    let bodyFormData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      if (typeof value != 'boolean' && !value) {
        continue
      }
      bodyFormData.append(key, value)
    }
    const response = await this.base().post('/1.0/add/queue.json', bodyFormData)
    return response.data
  },

  async updateQueue(data) {
    let bodyFormData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      if (typeof value != 'boolean' && !value) {
        continue
      }
      bodyFormData.append(key, value)
    }
    const response = await this.base().post('/1.0/update/queue.json', bodyFormData)
    return response.data
  },

  async resetQueue(idQueue) {
    let bodyFormData = new FormData()
    bodyFormData.append('idQueue', idQueue)
    bodyFormData.append('resetUncalled', true)

    const response = await this.base().post('/1.0/update/queue/reset.json', bodyFormData)
    return response.data
  },

  async deleteQueue(idQueue) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', idQueue)
    const response = await this.base().delete('/1.0/delete/queue.json', { data: bodyFormData })
    return response
  },

  async getQueueSlotsHours(queueId) {
    let data = {
      params: { queueId: queueId },
    }
    const response = await this.base().get('/1.0/get/queue/slot_hours.json', data)
    return response.data
  },

  async addQueueSlot(idQueue, weekday, hour) {
    let bodyFormData = new FormData()
    bodyFormData.append('idQueue', idQueue)
    bodyFormData.append('weekday', weekday)
    bodyFormData.append('hour', hour)
    const response = await this.base().post('/1.0/add/queue/slots.json', bodyFormData)
    return response.data
  },

  async deleteQueueSlot(idSlot) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', idSlot)
    const response = await this.base().delete('/1.0/delete/queue/slots.json', { data: bodyFormData })
    return response
  },

  async exportScheduledCalls() {
    const response = await this.base().get('/1.0/export_pdf/scheduled_calls.json')
    return response.data
  },

  async getOperatorLunchHours(operatorKey) {
    let data = {
      params: { userKey: operatorKey },
    }
    const response = await this.base().get('/1.0/get/lunch_breaks.json', data)
    return response.data
  },

  async addOperatorLunchHours(operatorKey, startTime, endTime, appliesFrom, appliesTo) {
    let bodyFormData = new FormData()
    bodyFormData.append('userKey', operatorKey)
    bodyFormData.append('appliesFrom', appliesFrom)
    bodyFormData.append('startTime', startTime)
    bodyFormData.append('endTime', endTime)

    if (appliesTo) {
      bodyFormData.append('appliesTo', appliesTo)
    }
    const response = await this.base().post('/1.0/add/lunch_breaks.json', bodyFormData)
    return response.data
  },

  async deleteOperatorLunchHours(id) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', id)
    const response = await this.base().delete('/1.0/delete/lunch_breaks.json', { data: bodyFormData })
    return response
  },

  async getServiceWorkingHours(serviceId) {
    let data = {
      params: { idService: serviceId },
    }
    const response = await this.base().get('/1.0/get/working_hours.json', data)
    return response.data
  },

  async getQueueWorkingHours(queueId) {
    let data = {
      params: { idQueue: queueId },
    }
    const response = await this.base().get('/1.0/get/working_hours.json', data)
    return response.data
  },

  async addWorkingHour(serviceId, queueId, weekday, startTime, endTime) {
    let data = {
      idService: serviceId,
      idQueue: queueId,
      weekday: weekday,
      startTime: startTime,
      endTime: endTime,
    }
    const response = await this.base().post('/1.0/add/working_hours.json', data)
    return response.data
  },

  async updateWorkingHour(id, startTime, endTime) {
    let data = {
      id: id,
      startTime: startTime,
      endTime: endTime,
    }
    const response = await this.base().post('/1.0/update/working_hours.json', data)
    return response.data
  },

  async deleteWorkingHour(id) {
    let bodyFormData = new FormData()
    bodyFormData.append('id', id)
    const response = await this.base().delete('/1.0/delete/working_hours.json', { data: bodyFormData })
    return response
  },
}
