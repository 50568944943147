<template>
  <div class="select-subjects-screen mb-16">
    <h1>
      <!-- LOCALE STRING: Fazer Marcação -->
      {{ localeMessages['title'] }}
    </h1>
    <div v-if="availableSubjects.length > 0 && mainSubjects === 0">
      <div class="flex">
        <h2>
          <!-- LOCALE STRING: Escolha o(s) assunto(s) -->
          {{ localeMessages['select_subject'] }}
        </h2>
        <div class="rounded-full px-2 py-0.5 h-5 text-xs font-bold inline-block bg-gray-100 text-gray-400 m-2.5">
          <!-- LOCALE STRING: Opcional -->
          {{ localeMessages['optional'] }}
        </div>
      </div>
      <div class="info">
        <!-- LOCALE STRING: Ajude-nos a filtrar o seu pedido seleccionando um -->
        {{ localeMessages['message_subject_part1'] }}
        <br />
        <!-- LOCALE STRING: (ou vários) assunto(s) -->
        {{ localeMessages['message_subject_part2'] }}
      </div>
      <div class="subjects-box">
        <option-checkbox
          v-for="(subject, s) in availableSubjects"
          :key="'subject_box_' + s"
          @selected="selectSubject(subject)"
        >
          {{ subject }}
        </option-checkbox>
      </div>
    </div>
    <div v-if="!userLoggedIn" class="flex">
      <h2>
        {{ localeMessages['full_name'] }}
      </h2>
      <div class="rounded-full px-2 py-0.5 h-5 text-xs font-bold inline-block bg-gray-700 text-white m-2.5">
        {{ localeMessages['mandatory'] }}
      </div>
    </div>
    <input
      v-if="!userLoggedIn"
      v-model="fullName"
      class="mb-2"
      :class="{
        invalid: fullName.length < 2,
      }"
      :placeholder="localeMessages['full_name']"
    />

    <div v-if="userLoggedIn" class="mt-4">
      <h2>
        <!-- Indique-nos o seu número de telemóvel -->
        {{ localeMessages['message_option_phone'] }}
      </h2>

      <div class="relative">
        <input
          v-model="phoneNumber"
          class="mb-2 px-3 py-2 contact-info"
          :class="{
            invalid: !phoneValid,
          }"
          :placeholder="localeMessages['phone']"
        />
        <div class="absolute top-2.5 left-2.5 text-gray-500">+351</div>
      </div>
    </div>

    <div class="flex">
      <h2>
        <!-- LOCALE STRING: Descreva o assunto -->
        {{ localeMessages['add_details'] }}
      </h2>
      <div class="rounded-full px-2 py-0.5 h-5 text-xs font-bold inline-block bg-gray-700 text-white m-2.5">
        <!-- LOCALE STRING: Obrigatório -->
        {{ localeMessages['mandatory'] }}
      </div>
    </div>
    <div class="info">
      <!-- LOCALE STRING: Descreva detalhadamente o que prentende -->
      {{ localeMessages['message_details'] }}
    </div>
    <!-- LOCALE STRING: Como podemos ajudar? -->
    <textarea v-model="details" :placeholder="localeMessages['placeholder_details']"></textarea>

    <div v-if="userLoggedIn" class="text-xs mb-5 py-2 text-justify">
      <h2>
        Termos e condições
      </h2>
      Ao realizar um agendamento está a autorizar a UC a proceder ao tratamento dos dados pessoais fornecidos, dos quais
      confirma ser titular. Declara ainda tomar conhecimento das condições gerais de atendimento: O agendamento é
      realizado até ao dia anterior ou enquanto houver horários disponíveis. Se chegar após 10 minutos da hora, o
      atendimento poderá ser remarcado para outro dia e horário. O atendimento está sujeito ao cumprimento das normas de
      segurança e proteção aí indicadas. De acordo com a legislação tem prioridade quem tiver convocatória ou marcação
      prévia.
      <a href="https://www.uc.pt/protecao-de-dados/politica_de_privacidade" class="is-link underline"
        >[Política de Privacidade]</a
      >
    </div>
  </div>
</template>

<script>
import OptionCheckbox from '../components/OptionCheckbox'
export default {
  name: 'SelectSubjectScreen',
  components: {
    'option-checkbox': OptionCheckbox,
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    // Used for the flux of the user starting by selecting the subjects
    mainSubjects: function() {
      return this.$store.state.selectedSubjectsMain.length
    },
    localeMessages: function() {
      return this.$store.state.locales['add_appointment_details']
    },
    phoneNumber: {
      get() {
        return this.$store.state.appointmentPhoneNumber
      },
      set(value) {
        this.$store.commit('updateAppointmentPhoneNumber', value)
      },
    },
    details: {
      get() {
        return this.$store.state.appointmentDetails
      },
      set(value) {
        this.$store.commit('updateAppointmentDetails', value)
      },
    },
    fullName: {
      get() {
        return this.$store.state.appointmentFullName
      },
      set(value) {
        this.$store.commit('updateAppointmentFullName', value)
      },
    },
    availableSubjects: function() {
      return this.$store.getters.availableSubjects
    },
    phoneValid() {
      return this.phoneNumber.length === 9 && this.phoneNumber.startsWith('9')
    },
  },
  methods: {
    selectSubject: function(subj) {
      this.$store.dispatch('selectAppointmentSubject', subj)
    },
  },
}
</script>

<style scoped>
.select-subjects-screen {
  @apply text-left px-2 py-4;
}
.select-subjects-screen h1 {
  @apply text-2xl font-bold mb-4 text-center;
}
.select-subjects-screen h2 {
  @apply text-lg font-bold mb-0;
}
.select-subjects-screen .info {
  @apply text-sm mb-4 text-gray-500;
}
.select-subjects-screen .subjects-box {
  @apply font-bold mb-4;
}
.select-subjects-screen textarea,
.select-subjects-screen input {
  @apply w-full px-3 py-2 border-2 border-gray-200 mb-4;
}
.select-subjects-screen .contact-info {
  @apply pl-14 !important;
}
.select-subjects-screen textarea:focus,
.select-subjects-screen input:focus {
  outline-color: rgba(32, 164, 149, 1);
}

.select-subjects-screen textarea.invalid:focus,
.select-subjects-screen input.invalid:focus {
  outline-color: #d73939;
}
.select-subjects-screen textarea.invalid,
.select-subjects-screen input.invalid {
  outline-color: #d73939;
}
</style>
