var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-panel flex flex-col relative",class:[
    {
      'border-b border-opacity-20 border-gray-800 mb-5 pb-5': _vm.section,
      'sticky top-0 z-2 backdrop-blur pb-4 bg-opacity-80 border-b': _vm.sticky,
    },
    _vm.sticky ? _vm.stickyBgColor : null,
  ]},[_c('div',{staticClass:"flex gap-5 items-center"},[_c('div',{staticClass:"flex-1"},[(_vm.title)?_c('div',{staticClass:"flex items-center gap-2"},[(_vm.icon)?_c('fw-icon-' + _vm.icon,{tag:"component",staticClass:"text-gray-500 w-5 h-5",class:{ 'opacity-50': !_vm.featured, 'mt-5': _vm.section }}):_vm._e(),_c('fw-heading',{staticClass:"relative",class:{ 'opacity-50': !_vm.featured, 'mt-5': _vm.section },attrs:{"size":_vm.featured ? 'h2' : 'h3'}},[_vm._v(_vm._s(_vm.title || 'Bloco')+" "),(_vm.counter)?_c('fw-badge',{attrs:{"inline":"","type":"simple"}},[_vm._v(_vm._s(_vm.counter)+_vm._s(_vm.counterTotal ? ' / ' + _vm.counterTotal : ''))]):_vm._e(),_vm._t("svg")],2),(_vm.loading)?_c('div',[_c('fw-icon-loading',{staticClass:"w-5 h-5"})],1):_vm._e(),(_vm.checkboxActive)?_c('div',[_c('fw-icon-checkbox-circle',{staticClass:"w-5 h-5 text-primary"})],1):_vm._e(),_vm._t("after-heading")],2):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"text-gray-500 font-medium"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()]),(_vm.seeMorePath)?_c('div',[_c('fw-button',{attrs:{"type":"link"},nativeOn:{"click":function($event){return _vm.$router.push(_vm.seeMorePath)}}},[_vm._v(_vm._s(_vm.seeMoreTitle))])],1):_vm._e(),(_vm.$slots.toolbar)?_c('div',[_vm._t("toolbar")],2):_vm._e()]),(_vm.$slots.default)?_c('div',{staticClass:"rounded-xl flex-1 mt-2",class:[
      {
        'shadow-md hover:shadow-xl': _vm.shadow,
        'p-1 bg-white': _vm.boxed === 'xs',
        'p-4 bg-white': _vm.boxed === 'sm',
        'p-8 bg-white': _vm.boxed === 'md',
        'p-8 bg-white': _vm.boxed === 'lg',
        'p-10 bg-white': _vm.boxed === 'xl',
      },
      _vm.customClass,
    ]},[_vm._t("default")],2):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"mt-2"},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }