<template>
  <div class="appointment-box" :class="{ white: white }">
    <div class="time-box">
      <div v-if="ticketnumber !== ''" class="ticketnumber-timebox">{{ ticketnumber }}</div>
      <span>{{ day | humanDay(language) }}</span>
      <time-box :time="hour | humanHour" :white="white" />
    </div>
    <div class="font-medium self-center">
      <span v-if="ticketnumberDesc !== ''" class="font-bold"
        >{{ ticketnumberDesc }}
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="circle"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="separator-icon text-gray-400"
        >
          <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
        </svg>
      </span>
      {{ serviceName }}
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="circle"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="separator-icon text-gray-400"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
          class=""
        ></path>
      </svg>
      {{ queueName }}<br />
      <b-tooltip
        v-if="status === 'submitted'"
        :label="localeMessages['tooltip_how_to_confirm']"
        position="is-top"
        size="is-small"
        multilined
      >
        <div class="appointment_not_confirmed">
          <svg
            class="warning_icon"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="currentColor"
            stroke-width="3"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
          <!-- Não confimada -->
          {{ localeMessages['status_not_confirmed'] }}
        </div>
      </b-tooltip>
      <b-tooltip
        v-else-if="status === 'canceled'"
        :label="localeMessages['tooltip_how_to_confirm']"
        position="is-top"
        size="is-small"
        multilined
      >
        <div class="appointment_not_confirmed">
          <svg
            class="warning_icon"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="currentColor"
            stroke-width="3"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
          <!-- Canceled -->
          {{ localeMessages['status_canceled'] }}
        </div>
      </b-tooltip>
      <div
        v-else
        class="add_to_google_calendar"
        @click="addToGoogleCalendar(day, hour, serviceName, queueName, ticketnumberDesc)"
      >
        <!-- + Add to Google Calendar -->
        {{ localeMessages['add_google_calendar'] }}
      </div>
      <div
        v-if="appointmentToken && status !== 'canceled'"
        class="cancel_appointment_button"
        @click="cancelAppointment(appointmentToken)"
      >
        <!-- LOCALE STRING: Cancelar Marcação -->
        {{ localeMessages['cancel_appointment'] }}
      </div>
      <div v-if="status === 'canceled' && cancelationReason && cancelationReason !== null">
        <h2>
          <!-- Motivo de cancelamento -->
          {{ localeMessages['cancelation_message'] }}
        </h2>
        {{ cancelationReason }}
      </div>
    </div>
  </div>
</template>

<script>
import Time from './Time'
import config from '../config'

export default {
  name: 'Appointment',
  components: {
    'time-box': Time,
  },
  filters: {
    humanHour: function(hour) {
      if (!hour) return ''
      let hourSplit = hour.split(':')
      return hourSplit[0] + ':' + hourSplit[1]
    },
  },
  props: {
    ticketnumberDesc: {
      type: String,
      default: '',
    },
    ticketnumber: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: 'pt',
    },
    day: {
      type: String,
      required: true,
    },
    hour: {
      type: String,
      required: true,
    },
    queueName: {
      type: String,
      required: true,
    },
    serviceName: {
      type: String,
      required: true,
    },
    status: {
      type: String,
    },
    appointmentToken: {
      type: String,
    },
    white: {
      type: Boolean,
      default: false,
    },
    cancelationReason: {
      type: String,
    },
  },
  computed: {
    localeMessages: function() {
      return this.$store.state.locales['appointment']
    },
  },
  methods: {
    cancelAppointment(appointmentToken) {
      this.$store.dispatch('cancelAppointment', appointmentToken)
    },
    addZero(number) {
      return number < 10 ? '0' + number : number
    },
    addToGoogleCalendar(day, hour, serviceName, queueName, ticketNumber) {
      if (day && hour && serviceName && queueName) {
        //console.log(day)
        let location = encodeURIComponent('UC Student Hub - ' + config.student_hub_address.split('\n').join(' '))
        let date = new Date(day + 'T' + hour)
        let str_hour_start = 'T' + this.addZero(date.getUTCHours()) + this.addZero(date.getUTCMinutes()) + '00Z'
        let str_day =
          '' +
          this.addZero(date.getUTCFullYear()) +
          this.addZero(date.getUTCMonth() + 1) +
          this.addZero(date.getUTCDate())
        let endDate = new Date(date.getTime() + 15 * 60000) //add 15 minutes
        let str_hour_end = 'T' + this.addZero(endDate.getUTCHours()) + this.addZero(endDate.getUTCMinutes()) + '00Z'
        let str_date = encodeURIComponent(str_day + str_hour_start + '/' + str_day + str_hour_end)
        let title = encodeURIComponent('[' + ticketNumber + '] - ' + serviceName + ' - ' + queueName)
        let link =
          'http://www.google.com/calendar/event?action=TEMPLATE&dates=' +
          str_date +
          '&text=' +
          title +
          '&location=' +
          location +
          '&details='
        window.open(link, '_blank')
      }
    },
  },
}
</script>

<style scoped>
.appointment-box {
  @apply flex py-2 relative gap-x-4 text-left;
}
.appointment-box .time-box {
  @apply flex-shrink-0 w-16 text-center;
}
.appointment-box .ticketnumber-timebox {
  @apply font-bold leading-3;
}
.appointment-box h4 {
  @apply self-center pl-4 font-medium;
}

.appointment-box:hover .cancel_appointment_button {
  @apply block rounded-full px-2 text-xs font-bold inline-block bg-gray-100 hover:bg-red-500 hover:text-white text-gray-700 mt-1.5 px-2.5 py-1 cursor-pointer;
}

.appointment-box .add_to_google_calendar {
  @apply rounded-full px-2 mr-3 text-xs font-bold inline-block bg-gray-100 text-gray-700 mt-1.5 px-2.5 py-1 cursor-pointer;
}
.appointment-box .add_to_google_calendar:hover {
  @apply bg-gray-500 text-white;
}

.appointment-box .cancel_appointment_button {
  @apply hidden;
}

.appointment-box.white .add_to_google_calendar {
  @apply bg-gray-700 text-gray-300;
}

.appointment-box.white .cancel_appointment_button {
  @apply bg-gray-200;
}

.appointment-box.white .add_to_google_calendar:hover {
  @apply bg-gray-500 text-white;
}

.appointment-box .separator-icon {
  height: 0.4rem;
  width: 0.4rem;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}

.appointment_not_confirmed .warning_icon {
  transform: scale(0.6);
  margin-top: -2px;
  @apply inline-block;
}

.appointment_not_confirmed {
  @apply bg-gray-700 rounded-full text-gray-200 px-3 py-0 font-bold mt-1.5 text-sm mt-2 h-6 mr-3;
}

.not_confirmed_positioner {
  @apply absolute top-0.5 right-0;
}
</style>
