<template>
  <span class="flex h-2 w-2 absolute top-1 right-1">
    <span
      class="absolute inline-flex h-full w-full rounded-full opacity-75"
      :class="{ 'animate-ping': ping, 'bg-primary': color === 'primary', 'bg-white': color === 'white' }"
    ></span>
    <span
      class="relative inline-flex rounded-full h-2 w-2"
      :class="{ 'bg-primary': color === 'primary', 'bg-white': color === 'white' }"
    ></span>
  </span>
</template>

<script>
export default {
  props: {
    ping: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
