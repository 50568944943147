<template>
  <div class="home-screen-loading container mx-auto p-4 pb-60 max-w-3xl">
    <!-- Services Queues Section -->
    <div class="services-section">
      <div class="service-box">
        <div>
          <h3 class="text-loading"></h3>
        </div>

        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size1"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size2"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size1"></h4>
        </div>
      </div>

      <div class="service-box">
        <div>
          <h3 class="text-loading"></h3>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size1"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size2"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size1"></h4>
        </div>
      </div>

      <div class="service-box">
        <div>
          <h3 class="text-loading"></h3>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size1"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size2"></h4>
        </div>
        <div class="queue">
          <div class="ticket"></div>
          <h4 class="text-loading size1"></h4>
        </div>
      </div>

      <!-- Section clear -->
      <div class="clear-both"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeLoading',
}
</script>

<style scoped>
.open-arrow {
  transform: rotate(180deg);
}
.home-screen {
}
.services-section {
  @apply text-left;
}
.service-box {
  @apply w-full sm:w-1/2;
  @apply float-left sm:px-4 px-0 py-4;
}
.service-box h3 {
  @apply font-bold text-xl mb-0.5;
}
.queue {
  @apply flex justify-start my-2;
  @apply float-left sm:float-none mr-2 sm:mr-0;
}
.queue.selectable {
  @apply cursor-pointer select-none;
}
.queue.selectable:hover {
  @apply ring-4 ring-gray-300 ring-opacity-80;
}
.queue h4 {
  @apply self-center pl-4 font-medium;
  @apply hidden sm:inline-block;
}
.queue.selected h4 {
  @apply font-bold;
}
.dashboard {
  min-height: 126px;
  @apply bg-gray-400 rounded-2xl text-white text-left px-5 pt-3 pb-5 select-none mb-4 animate-pulse;
}
.ticket {
  @apply border-2 mr-0 border-gray-400 border-solid text-center font-bold rounded-xl tracking-tighter text-lg h-16 w-16 flex flex-col animate-pulse justify-center leading-6;
  /* force min size */
  min-width: 4rem;
  min-height: 4rem;
}
h4.text-loading {
  @apply ml-4;
}
.text-loading {
  height: 24px;
  min-width: 200px;
  display: inline-block;
  @apply rounded animate-pulse bg-gray-300;
}
.text-loading.size1 {
  min-width: 160px;
}
.text-loading.size2 {
  min-width: 250px;
}
.text-loading.size3 {
  min-width: 280px;
}
.text-loading.size4 {
  min-width: 320px;
}
.dashboard h3 {
  @apply font-bold mb-1;
}
.dashboard .dashboard-section {
  @apply sm:w-full sm:w-1/2 relative;
}
.dashboard .dashboard-section .see-more {
  @apply cursor-pointer absolute top-0.5 right-0.5 text-sm font-semibold select-none;
}
.dashboard .dashboard-section .see-more .caret-down {
  @apply h-3.5 inline ml-1;
}
.dashboard .time-box {
  @apply w-16 text-center font-bold flex-none;
  min-width: 4rem;
}
.dashboard .next-schedule {
  @apply flex py-2;
}
.dashboard .next-schedule h4 {
  @apply self-center pl-4 font-medium;
}
</style>
