<template>
  <div class="smart-options-screen">
    <!-- Select Service -->
    <div class="services-section">
      <div class="info-msg">
        <!-- LOCALE STRING: Encontrámos os seguintes horários que lhe podem interessar -->
        {{ localeMessages['message'] }}
      </div>

      <div v-for="(time, t) in timeSlots" :key="'time_' + t" class="suggestion-option pt-0">
        <div v-for="(subtime, st) in time" :key="'subtime_' + st" @click="select_option(time)">
          <h3 v-if="st === 0 || (time[st - 1] && time[st - 1].day !== subtime.day)" class="mt-3">
            {{ subtime.day | humanDay(languageSelected) }}
          </h3>

          <div
            class="time-selector"
            :class="{ disabled: subtime.disabled === true, selected: subtime.selected === true }"
          >
            <div class="time-box">
              <time-box
                v-if="subtime && subtime !== null && subtime.hour !== null"
                :time="subtime.hour.substr(0, 5)"
                :white="subtime.selected === true"
              ></time-box>
            </div>
            <div class="flex-1 pl-4 font-medium">
              <b>{{ subtime.serviceName }}</b> - {{ subtime.queueName }}
              <span v-if="subtime.selectedSubject && Array.isArray(subtime.selectedSubject)"
                >:
                <span v-for="(sub, s) in subtime.selectedSubject" :key="subtime.queuid + '_' + sub"
                  ><span v-if="s !== 0">, </span> {{ sub }}</span
                >
              </span>
            </div>
          </div>

          <div
            v-if="
              typeof timeSlots[t + 1] === 'undefined' ||
                (timeSlots[t + 1] && timeSlots[t + 1][0] && timeSlots[t + 1][0].day !== subtime.day)
            "
            class="clear-both"
          ></div>
        </div>
      </div>

      <!-- Section clear -->
      <div class="clear-both"></div>

      <div class="suggestion-option" @click="see_all">
        <h3 class="mt-3">
          <!-- LOCALE STRING: Ver todos os horários -->
          {{ localeMessages['see_all'] }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import Time from '../components/Time'
export default {
  name: 'SmartOptionsListScreen',
  components: {
    'time-box': Time,
  },
  filters: {
    //humanDay: function(date, fun) {
    /*if (!date) return ''
      // Check if it's today
      let todayDate = new Date()
      let today = todayDate.toISOString().slice(0, 10)
      if (date === today) {
        return 'Hoje'
      }
      // Check if it's tomorrow
      const tomorrowDate = new Date(todayDate)
      tomorrowDate.setDate(todayDate.getDate() + 1)
      let tomorrow = tomorrowDate.toISOString().slice(0, 10)
      if (date === tomorrow) {
        return 'Amanhã'
      }
      //Parse other dates
      let months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
      let parts = date.split('-')
      return parts[2] + ' ' + months[Number.parseInt(parts[1]) - 1]*/
    //return fun(date) //this.$store.getters.humanDay(date)
    //},
  },
  data: function() {
    return {
      timeSlots: [],
    }
  },
  computed: {
    languageSelected: function() {
      return this.$store.state.language
    },
    localeMessages: function() {
      return this.$store.state.locales['smart_schedule_results']
    },
    currentPage() {
      return this.$store.getters.currentPage
    },
  },
  mounted() {
    let options = this.get_options()
    if (options.length === 0) {
      //show error
      this.$buefy.toast.open({
        duration: 5000,
        message: this.localeMessages['error_message'],
        position: 'is-top',
        type: 'is-danger',
      })
      this.$store.commit('navigationBack')
      this.see_all()
    } else {
      this.timeSlots = options
    }
  },
  methods: {
    select_option(options) {
      //option is an array of time slots
      this.$store.dispatch('selectSmartOptions', options)
    },
    see_all() {
      this.$store.dispatch('openPage', 'all_time_options')
    },
    get_options() {
      let options
      if (this.currentPage === 'smart_options_comfort') {
        options = this.$store.getters.comfortOptions
        console.log('COMFORT', options)
      } else if (this.currentPage === 'smart_options_urgent') {
        options = this.$store.getters.urgentOptions
        console.log('URGENT', options)
      } else {
        console.log('Smartoption type not recognized')
        options = []
      }
      return options
    },
  },
}
</script>

<style scoped>
.smart-options-screen {
  @apply text-left;
}
.suggestion-option {
  @apply mb-3 p-6 pt-2 cursor-pointer select-none text-left bg-white shadow-md rounded-2xl;
}
.suggestion-option:hover {
  @apply shadow-xl z-10;
}
.smart-options-screen h3 {
  @apply font-bold py-0 text-xl;
}
.smart-options-screen .time-selector {
  @apply flex justify-start mt-2;
}
.smart-options-screen .time-selector .time-box {
  @apply w-16 font-bold flex-grow-0 max-w-xs;
}
.smart-options-screen .time-selector .big-time-display {
  @apply h-11;
  line-height: 44px;
}
</style>
