import ViewHome from '../views/ViewHome.vue'

export default [
  {
    path: '/',
    name: 'home',
    component: ViewHome,
    meta: { requiresAuth: false },
  },
  // Confirm group of appointments
  {
    name: 'confirm_appointments',
    path: '/confirm/:appointments_group_id',
    component: ViewHome,
  },
  // See status of a group of appointments
  {
    name: 'status_appointments',
    path: '/group/:appointments_group_id',
    component: ViewHome,
  },
  // See status of one appointment (possible canceled and with the motif of cancellation
  {
    name: 'status_appointment',
    path: '/status/:appointment_ticket_hash',
    component: ViewHome,
  },
  // Digital Ticket
  {
    name: 'digital_ticket',
    path: '/ticket/:ticket_hash/:ticket_number',
    component: ViewHome,
  },
]
