<template>
  <button class="btn" :class="{ 'btn-highlight': highlight, disabled: disabled, small: small }" @click="_onclick">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    _onclick() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.btn {
  @apply py-2 px-4 font-bold w-full text-center text-lg h-14;
  @apply text-black cursor-pointer select-none bg-white rounded-lg shadow;
  transition: all 100ms linear, width 0.3s ease-out;
}
.btn:hover {
  @apply ring-4 ring-gray-300 ring-opacity-80;
}
.btn:focus {
  @apply ring-4 ring-gray-300 ring-opacity-80;
}
.btn.btn-highlight {
  @apply text-white bg-primary;
}
.btn.disabled {
  opacity: 0.25 !important;
}
.btn.small {
  @apply py-1 px-3 text-base border-2;
}
</style>
