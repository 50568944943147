<template>
  <LayoutDefault>
    <template #main>
      <div class="w-full h-full flex flex-col justify-center items-center">
        <fw-icon-map-pin3 class="w-16 h-16"></fw-icon-map-pin3>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'

export default {
  components: {
    LayoutDefault,
  },
}
</script>
