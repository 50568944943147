import Vue from 'vue'
import locale from '../i18n/index'

Vue.filter('humanDay', (date, lang) => {
  if (!date) return ''
  let localeMessages = locale[lang]
  // Check if it's today
  let todayDate = new Date()
  let today = todayDate.toISOString().slice(0, 10)
  if (date === today) {
    return localeMessages['time']['today'] //'Hoje'
  }
  // Check if it's tomorrow
  const tomorrowDate = new Date(todayDate)
  tomorrowDate.setDate(todayDate.getDate() + 1)
  let tomorrow = tomorrowDate.toISOString().slice(0, 10)
  if (date === tomorrow) {
    return localeMessages['time']['tomorrow'] //'Amanhã'
  }
  //Parse other dates
  let months = localeMessages['time']['months_abbreviated'] //['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
  let parts = date.split('-')
  return parts[2] + ' ' + months[Number.parseInt(parts[1]) - 1]
})

Vue.filter('formatTimeString', date => {
  if (!date) return ''
  let dateStr = date.split(':')
  dateStr.pop()

  return dateStr.join(':')
})
