<template>
  <div class="home-screen">
    <!-- User Dashboard -->
    <div v-if="userTickets.length > 0 || userAppointments.length > 0" class="mb-10">
      <div class="stack-on-mobile" :class="{ forceStack: userTickets.length > 5 }">
        <div v-if="userTickets.length > 0">
          <fw-label>
            <!-- LOCALE STRING: As tuas senhas -->
            {{ localeMessages['dashboard']['your_tickets'] }}
          </fw-label>
          <div
            class="flex px-4 py-2 mt-2 rounded-xl flex-wrap gap-3 bg-gradient-to-r from-teal-400 to-teal-600 hover:to-teal-500"
          >
            <!-- TODO: bind ticket data to component
              createdDate: "2021-07-30T00:00:00"
              idQueue: 4
              previewDate: null
              previewTime: "00:07:40"
              queueDescription: "Licenciaturas"
              queueName: "A"
              serviceId: 2
              serviceName: "Demo"
              ticketHash: "dd616c3a3b161f25399223bd45ae4b21e1462643"
              ticketNumber: 1
             -->
            <ticket
              v-for="(ticket, t) in userTickets"
              :key="'user_ticket_' + t"
              :queue="ticket.queueName"
              :ticket="ticket.ticketNumber"
              :wait="true"
              :post="''"
              :white="true"
              :show-post="false"
            ></ticket>
          </div>
        </div>
        <div v-if="userAppointments.length > 0 && false" class="dashboard-section" @click="toogleAppointments">
          <h3>
            <!-- LOCALE STRING: Próxima marcação -->
            {{ localeMessages['dashboard']['next_appointment'] }}
          </h3>

          <div v-if="userAppointments.length > 1" class="see-more">
            <span class="rounded bg-white text-black py-0.5 px-2 mr-2"
              >{{ userAppointments.length
              }}<span v-if="!(userTickets.length > 0 && userAppointments.length > 0)">
                <!-- marcações -->
                {{ localeMessages['dashboard']['appointments'] }}
              </span></span
            >
            <!-- LOCALE STRING: Ver todas -->
            {{ localeMessages['dashboard']['see_all_appointments'] }}
            <svg
              :class="{ 'open-arrow': showAllAppointments }"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="caret-down"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              class="caret-down svg-inline--fa fa-caret-down fa-w-10 fa-3x h-4 w-4 inline"
            >
              <path
                fill="currentColor"
                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                class=""
              ></path>
            </svg>
          </div>

          <appointment
            :language="languageSelected"
            :day="userAppointments[0].day"
            :hour="userAppointments[0].hour"
            :service-name="userAppointments[0].serviceName"
            :queue-name="userAppointments[0].queueName"
            :status="userAppointments[0].status"
            :appointment-token="userAppointments[0].token"
            :ticketnumber-desc="'M' + userAppointments[0].ticket_number"
            :white="true"
          />
        </div>
      </div>

      <!-- All Appointments -->
      <div v-if="showAllAppointments && userAppointments.length > 0" class="mt-6 relative">
        <fw-label>
          <!-- LOCALE STRING: Todas as marcações -->
          {{ localeMessages['dashboard']['title_all_appointments'] }}
        </fw-label>
        <div class="flex flex-col px-4 py-2 mt-2 rounded-xl flex-wrap gap-3 bg-gray-100">
          <appointment
            v-for="(appointment, a) in userAppointments"
            :key="'appointment_' + a"
            :language="languageSelected"
            :day="appointment.day"
            :hour="appointment.hour"
            :service-name="appointment.serviceName"
            :queue-name="appointment.queueName"
            :status="appointment.status"
            :appointment-token="appointment.token"
            :ticketnumber-desc="
              typeof appointment.queueLetter === 'string'
                ? appointment.queueLetter + 'M' + appointment.ticket_number
                : 'M' + appointment.ticket_number
            "
            :white="true"
          />
        </div>
      </div>
    </div>

    <div v-if="false" class="w-full rounded-2xl bg-white shadow-md mb-5 md:flex overflow-hidden">
      <div
        class="flex-1 bg-center bg-cover h-40  md:h-auto"
        style="background-image: url('https://apps.uc.pt/sites/banners/images/b-694eac86d1/cover/Matriculas2022-banner-site.png');"
      ></div>
      <div class="p-5 flex-1">
        <fw-heading size="h1" class="mb-2">Matrículas 2022/2023</fw-heading>
        A matrícula dos/as candidatos/as colocados/as em 2022/2023 na Universidade de Coimbra pela Direcção-Geral do
        Ensino Superior (DGES) através do Concurso Nacional de Acesso (CNA),é realizada online ou presencialmente, mas
        sempre na plataforma informática InforEstudante. A matrícula presencial decorre no Student Hub, Edifício da FMUC
        do Polo 1 da UC, mediante prévo agendamento
        <a class="text-teal-500 font-semibold" href="https://apps.uc.pt/qflowplus/client/#/service/12">aqui</a>. Vê as
        condições em
        <a class="text-teal-500 font-semibold" href="https://www.uc.pt/academicos/matriculas"
          >www.uc.pt/academicos/matriculas</a
        >.
        <div class="flex pt-5 gap-5">
          <studenthub-button class="relative" @click="openLink('https://www.uc.pt/academicos/matriculas')">
            Mais informações
          </studenthub-button>
          <studenthub-button :highlight="true" @click="openLink('https://apps.uc.pt/qflowplus/client/#/service/12')">
            Agendar aqui
          </studenthub-button>
        </div>
      </div>
    </div>

    <fw-heading size="h1" class="-mb-2">{{ localeMessages['queues'] }}</fw-heading>

    <!-- Services Queues Section -->
    <Panel v-for="(service, s) in services" :key="'service_box_' + s" class="my-5" :title="service.name">
      <template v-if="service.workingHours && Object.keys(service.workingHours).length > 0" #after-heading>
        <b-dropdown aria-role="dialog" :hoverable="true">
          <template #trigger>
            <faicon icon="info-circle" size="sm" class="opacity-50" />
          </template>
          <div v-for="(value, key, index) in service.workingHours" :key="index" aria-role="listitem" class="mx-4 my-2">
            <p>
              <strong>{{ weekdays[key] }}</strong>
            </p>
            <p>
              <span>das {{ value[0].startTime | formatTimeString }} às {{ value[0].endTime | formatTimeString }}</span>
              <span v-if="value[1]">
                e das {{ value[1].startTime | formatTimeString }} às {{ value[1].endTime | formatTimeString }}</span
              >
            </p>
          </div>
        </b-dropdown>
      </template>

      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 md:gap-3 justify-center mb-5 -mt-2 items-stretch"
      >
        <div v-for="(queue, q) in service.queues" :key="'queue_' + q" class="relative">
          <b-dropdown
            v-if="
              queue.workingHours &&
                Object.keys(queue.workingHours).length > 0 &&
                allQueuesFromService(queue.workingHours)
            "
            aria-role="list"
            :hoverable="true"
            class="queue-schedule-info"
          >
            <template #trigger>
              <faicon
                icon="info-circle"
                size="sm"
                :class="{
                  'opacity-50': !queue.active,
                }"
              />
            </template>
            <div v-for="(value, key, index) in queue.workingHours" :key="index">
              <div v-if="allFromService(value)" aria-role="listitem" class="mx-4 my-2">
                <p>
                  <strong>{{ weekdays[key] }}</strong>
                </p>
                <p>
                  <span v-if="value[0] && !!value[0].fromService == false"
                    >das {{ value[0].startTime | formatTimeString }} às {{ value[0].endTime | formatTimeString }}</span
                  >
                  <span v-if="value[1] && !!value[1].fromService == false">
                    e das {{ value[1].startTime | formatTimeString }} às {{ value[1].endTime | formatTimeString }}</span
                  >
                </p>
              </div>
            </div>
          </b-dropdown>
          <QueueCard
            :disabled="!queue.active"
            class="queue w-full"
            :queueid="queue.id"
            :queue="queue.name"
            :ticket="queue.currentTicketNumber"
            :post="queue.workstationName"
            :queue-selected="queue.selected"
            :queue-description="queue.description"
            :selected="selectedQueue === queue.id"
            :can-get-ticket="queue.allowRemote && queue.active"
            :is-logged-in="isLoggedIn"
            @selected="selectQueue"
            @getticket="getTicketQueue"
          >
          </QueueCard>
        </div>
      </div>
    </Panel>

    <Panel v-if="false" class="my-5 hidden" :title="localeMessages['schedule']" featured>
      <OpenHours :schedule="schedule" :format="'week'" :show-now="true" />
    </Panel>

    <Panel class="my-5" :title="localeMessages['thespace']" featured>
      <div
        v-if="buildingCoverUrl !== null"
        class="aspect-w-4 aspect-h-2 w-full relative filter contrast-125 rounded-xl overflow-hidden mb-3"
      >
        <img v-if="buildingCoverUrl !== null" class="absolute h-full w-full object-cover" :src="buildingCoverUrl" />
        <div v-else class="absolute h-full w-full object-cover"></div>
      </div>
      <div class="mb-5 font-semibold text-md" v-html="localeMessages['address_description']"></div>
      <fw-label class="mt-5 text-left">
        <!-- Horário -->
        {{ localeMessages['schedule'] }}
      </fw-label>
      <div class="mb-5 text-sm" v-html="localeMessages['openhours_description']"></div>
      <fw-label class="mt-5 text-left">
        <!-- Morada -->
        {{ localeMessages['address'] }}
      </fw-label>
      <div class="text-left text-sm" v-html="address"></div>
      <div class="mt-5">
        <fw-button
          type="light"
          size="md"
          title="Ver no mapa"
          class="flex items-center"
          expanded
          @click.native="showMap"
        >
          <svg
            class="fill-current w-6 h-6 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z"
            />
          </svg>
          <!-- Ver localização no mapa -->
          {{ localeMessages['map_location'] }}
        </fw-button>
      </div>
    </Panel>
  </div>
</template>

<script>
import Ticket from '../components/Ticket'
import Appointment from '../components/Appointment'
import config from '@/config'
import OpenHours from '../components/OpenHours'
//import StudentHubMap from '../components/Map'

import Panel from '@/fw-modules/fw-core-vue/ui/components/panels/Panel'
import QueueCard from '../components/QueueCard'
import Button from '../components/Button'
export default {
  name: 'Home',
  components: {
    'studenthub-button': Button,
    ticket: Ticket,
    appointment: Appointment,
    OpenHours: OpenHours,
    Panel: Panel,
    QueueCard: QueueCard,
  },
  data: function() {
    return {
      buildingCoverUrl: config.student_hub_image,
      showAllAppointments: true,
      schedule: config.student_hub_open_hours,
      refreshTicketsTimer: null,
      selectedQueue: -1,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    address: function() {
      return config.student_hub_address.split('\n').join('<br>')
    },
    languageSelected: function() {
      return this.$store.state.language
    },
    localeMessages: function() {
      return this.$store.state.locales['home_page']
    },
    weekdays: function() {
      return this.$store.state.locales['weekdays']
    },
    services: function() {
      return this.$store.state.services
    },
    selectableServices: function() {
      return this.$store.state.homepageServicesSelectable
    },
    userTickets: function() {
      return this.$store.state.userTickets
    },
    userAppointments: function() {
      return this.$store.state.userAppointments
    },
  },
  mounted() {
    if (this.refreshTicketsTimer === null) {
      let refreshTime = 10000 //10 seconds
      let dispatch = this.$store.dispatch
      this.refreshTicketsTimer = setInterval(function() {
        dispatch('refreshServicesNewTickets')
      }, refreshTime)
    }
    this.createWindowsFocusListener()
    //this.$store.dispatch('refreshServicesNewTickets')
  },
  beforeDestroy() {
    if (this.refreshTicketsTimer !== null) {
      clearInterval(this.refreshTicketsTimer)
    }
    this.destroyWindowsFocusListener()
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank')
    },
    getTicketQueue: function(queueID) {
      console.log('method getTicketQueue')
      this.$store.dispatch('getTicketQueue', queueID)
    },
    selectQueue: function(queueID) {
      if (this.selectedQueue === queueID) {
        this.selectedQueue = -1
      } else {
        this.selectedQueue = queueID
      }
    },
    showMap() {
      console.log('showMap')
      this.$store.commit('setMapModalOpen', true)
    },
    destroyWindowsFocusListener: function() {
      window.removeEventListener('blur', this.changeFocusState(false), false)
      window.removeEventListener('focus', this.changeFocusState(true), false)
    },
    createWindowsFocusListener: function() {
      window.addEventListener('focus', this.changeFocusState(true), false)
      window.addEventListener('blur', this.changeFocusState(false), false)
    },
    changeFocusState(state) {
      this.$store.commit('changeWindowsFocus', state)
    },
    getDirections() {
      let link =
        'https://www.google.com/maps/dir/?api=1&destination=' +
        config.student_hub_gps[0] +
        ',' +
        config.student_hub_gps[1] +
        '&dir_action=navigate'
      window.open(link, '_blank')
    },
    cancelAppointment(appointmentToken) {
      this.$store.dispatch('cancelAppointment', appointmentToken)
    },
    toogleAppointments() {
      if (this.userAppointments.length > 1) {
        this.showAllAppointments = !this.showAllAppointments
      }
    },
    allQueuesFromService(schedule) {
      let res = false
      for (let weekday in schedule) {
        if (schedule[weekday].some(el => !!el.fromService == false)) res = true
      }
      return res
    },
    allFromService(schedule) {
      let res = false
      if (schedule.some(el => !!el.fromService == false)) res = true
      return res
    },
  },
}
</script>

<style scoped>
.open-arrow {
  transform: rotate(180deg);
}
.queue-schedule-info {
  position: absolute;
  z-index: 19;
  right: 10px;
  top: 10px;
}
.home-screen {
  @apply text-left;
}
.services-section {
  @apply text-left grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center mb-10 items-stretch;
}
.service-box {
  @apply w-full sm:w-1/2;
  @apply float-left sm:px-4 px-0 py-4;
}
.service-box h3 {
  @apply font-bold text-xl mb-3;
}
.aditional_info {
  @apply text-left;
}
.aditional_info h2 {
  @apply font-bold text-xl mb-3 mt-4;
}
.aditional_info h3 {
  @apply font-bold text-2xl mb-3 mt-16;
}
.queue {
  @apply flex justify-start my-2 self-start;
  @apply float-left sm:float-none mr-2 sm:mr-0;
}
.queue.selectable {
  @apply cursor-pointer select-none;
}
.queue.selectable:hover {
  @apply ring-4 ring-gray-300 ring-opacity-80;
}
.queue h4 {
  @apply self-center pl-4 font-medium;
  @apply hidden sm:inline-block;
}
.queue.selected h4 {
  @apply font-bold;
}
.dashboard {
  background-color: #212121;
  @apply rounded-2xl text-white text-left px-5 pt-3 pb-5 select-none mb-4;
}
.dashboard h3 {
  @apply font-bold mb-1;
}
.dashboard .dashboard-section {
  @apply flex-grow sm:w-full sm:w-1/2 relative;
}
.dashboard .dashboard-section .see-more {
  @apply cursor-pointer block absolute top-1 right-0.5 text-sm font-semibold select-none;
}
.dashboard .dashboard-section .see-more .caret-down {
  @apply h-3.5 inline ml-1;
}
.dashboard .time-box {
  @apply w-16 text-center font-bold flex-none;
  min-width: 4rem;
}

.address {
  @apply text-sm;
}
</style>
