<template>
  <div class="select-subjects-screen">
    <fw-heading size="h1">
      Fazer marcação
    </fw-heading>
    <!-- Select Service -->
    <div class="services-section mt-2">
      <div>
        <div class="flex gap-5 items-center mb-5">
          <fw-button class="flex gap-2 items-center" type="tab-active" size="md" @click.native="chooseBySubject">
            Escolher por assunto
          </fw-button>
          <fw-button class="flex gap-2 items-center" type="tab" size="md" @click.native="chooseByQueues">
            Escolher por Fila
          </fw-button>
        </div>
      </div>
      <fw-label>
        <!-- LOCALE STRING: Escolha os vários serviços em que quer tirar senha -->
        Quais os assuntos que pretende tratar?
      </fw-label>
      <div class="grid grid-cols-1 gap-3 justify-center mb-4 items-stretch mt-6">
        <option-card-checkbox
          v-for="(subject, s) in subjects"
          :key="'subject_' + s"
          :text="subject.text"
          @selected="selectSubject(subject)"
        >
        </option-card-checkbox>
      </div>

      <div v-if="nocontent" class="no-data-msg">
        Sem nenhum assunto disponível
      </div>
    </div>
  </div>
</template>

<script>
//import OptionCheckbox from '../components/OptionCheckbox'
import CardCheckbox from '../components/CardCheckbox'

export default {
  name: 'SelectSubjectScreen',
  components: {
    'option-card-checkbox': CardCheckbox,
  },
  computed: {
    localeMessages: function() {
      return this.$store.state.locales['select_services']
    },
    currentPage() {
      return this.$store.getters.currentPage
    },
    subjects() {
      let result = []
      let services = this.$store.state.services
      for (let i = 0; i < services.length; i++) {
        //console.log('getAppoitnmentSubjects1', services[i].queues)
        for (let j = 0; j < services[i].queues.length; j++) {
          if (
            services[i].queues[j].active &&
            (services[i].queues[j].allowScheduling || services[i].queues[j].slotsFull)
          ) {
            console.log(services[i].queues[j].subjects)
            let subjects = services[i].queues[j].subjects.split('\n')
            for (let k = 0; k < subjects.length; k++) {
              //console.log('usbService', services[i].queues[j].name)
              result.push({
                text: subjects[k],
                serviceID: services[i].id,
                serviceIndex: i,
                queueID: services[i].queues[j].id,
                queueIndex: j,
                serviceName: services[i].name,
                queueName: services[i].queues[j].description,
                queueLetter: services[i].queues[j].name,
              })
            }
          }
          //let queue = services[i].queues[j].subjects
          //console.log('getAppoitnmentSubjects3')
          //c//onsole.log('queue subjects', queue.subjects)
          //if (queue.active && queue.allowScheduling) {
          //  console.log('getAppoitnmentSubjects4')
          //  console.log('queue.subjects', queue.subjects)
          //  result.concat(queue.subjects)
          //}
        }
      }
      console.log('subjects', result)
      return result //.sort()
      //return this.$store.getters.appoitnmentSubjects
    },
    nocontent() {
      return this.subjects.length === 0
    },
  },
  mounted() {
    this.$store.commit('resetSelectedSubjectsMain')
  },
  methods: {
    chooseByQueues() {
      this.$store.commit('resetSelectedSubjectsMain')
      this.$store.dispatch('openPage', 'service_selection_booking')
    },
    selectSubject(subject) {
      console.log('selectSubject', subject)
      this.$store.commit('toogleSelectedSubjectMain', subject)
    },

    /*
      addSelectedSubjects(state, subject) {
    state.selectedSubjects.push(subject)
  },
  removeSelectedSubjects(state, index) {
    state.selectedSubjects.splice(index, 1)
  },
     */
  },
}
</script>

<style scoped>
.select-services-screen {
  @apply max-w-screen-md mx-auto;
}
.services-section {
  @apply text-left;
}
.choose-by-queues {
  @apply w-full;
  @apply sm:px-7 py-5 px-8;
  @apply bg-gray-50 rounded-2xl shadow-md mb-3 cursor-pointer text-gray-700;
}
.choose-by-queues:hover {
  @apply shadow-xl;
}
.icon-exchange {
  @apply text-gray-500 text-lg inline-block h-5 w-5 mr-4;
}
.service-box h3 {
  @apply font-bold text-xl;
}
.queue {
  @apply flex justify-start my-2;
  @apply float-left sm:float-none mr-2 sm:mr-0;
}
.queue.selectable {
  @apply cursor-pointer select-none;
}
.queue.selectable:hover {
  @apply ring-4 ring-gray-300 ring-opacity-80;
}
.queue h4 {
  @apply self-center pl-4 font-medium;
  @apply hidden sm:inline-block;
}
.queue.selected h4 {
  @apply font-bold;
}
.dashboard {
  background-color: #212121;
  @apply rounded-2xl text-white text-left px-5 pt-3 pb-5;
}
.dashboard h3 {
  @apply font-bold mb-1;
}
.dashboard .dashboard-section {
  @apply sm:w-full sm:w-1/2 relative;
}
.dashboard .dashboard-section .see-more {
  @apply cursor-pointer absolute top-0.5 right-0.5 text-sm font-semibold select-none;
}
.dashboard .dashboard-section .see-more .caret-down {
  @apply h-3.5 inline ml-1;
}
.dashboard .time-box {
  @apply w-16 text-center font-bold;
  min-width: 4rem;
}
.dashboard .next-schedule {
  @apply flex py-2;
}
.dashboard .next-schedule h4 {
  @apply self-center pl-4 font-medium;
}
</style>
