<template>
  <div class="needs-loggin-screen">
    <svg
      aria-hidden="true"
      height="40"
      focusable="false"
      data-prefix="fas"
      data-icon="lock"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      class="lock-icon"
    >
      <path
        fill="currentColor"
        d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
        class=""
      ></path>
    </svg>

    <h1>
      <!-- LOCALE STRING: Autentica-te -->
      {{ localeMessages['title'] }}
    </h1>

    <!-- LOCALE STRING: A emissão de senhas online está reservada à comunidade universitária. -->
    {{ localeMessages['message'] }}
    <svg
      class="animate-bounce mx-auto mt-12 w-6 h-6 text-amber-900"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
    </svg>
  </div>
</template>

<script>
import config from '../config.js'
export default {
  name: 'NeedsLoginScreen',
  data: function() {
    return {}
  },
  computed: {
    config: function() {
      return config
    },
    localeMessages: function() {
      return this.$store.state.locales['needs_authentication']
    },
  },
  methods: {},
}
</script>

<style scoped>
.lock-icon {
  font-size: 18px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-right: auto;
}
.needs-loggin-screen {
  @apply text-center px-2 pt-44 relative;
}
.needs-loggin-screen .time-box {
  @apply font-bold;
}
.needs-loggin-screen .appointment-result {
  @apply mb-2;
}
.needs-loggin-screen .input-outside {
  @apply relative;
}
.needs-loggin-screen textarea,
input {
  @apply w-full px-3 py-2 border-2 border-gray-200 mb-4;
}
.needs-loggin-screen textarea.prefixed,
input.prefixed {
  @apply pl-14;
}
.needs-loggin-screen textarea:focus,
input:focus {
  outline-color: rgba(32, 164, 149, 1);
}
.needs-loggin-screen textarea.invalid:focus,
input.invalid:focus {
  outline-color: #d73939;
}
.needs-loggin-screen textarea.invalid,
input.invalid {
  outline-color: #d73939;
}
.needs-loggin-screen h1 {
  @apply text-2xl font-bold mb-2 mt-8 text-center;
}
.needs-loggin-screen h2 {
  @apply text-lg font-bold mb-1;
}
</style>
