<template>
  <LayoutDefault>
    <template #main>
      <div class="w-full h-full flex flex-col gap-2 justify-center items-center max-w-prose mx-auto">
        <div class="p-5">
          <fw-icon-lock-solid class="w-12 h-12" />
        </div>
        <div class="text-lg">{{ $t('403Forbidden') }}</div>
        <fw-heading>{{ $t('noPermissions') }}</fw-heading>
        <div class="mt-5">
          <fw-button type="link-muted" @click.native="$router.go(-1)">{{ $t('backToPreviousPage') }}</fw-button>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'

export default {
  components: {
    LayoutDefault,
  },
}
</script>

<i18n>
{
  "en": {
    "403Forbidden": "(403) Forbidden",
    "noPermissions": "You are not allowed to access this resource.",
    "backToPreviousPage": "Back to previous page"
  },
  "pt": {
    "403Forbidden": "(403) Forbidden",
    "noPermissions": "Não tem permissões para aceder ao recurso solicitado.",
    "backToPreviousPage": "Voltar à página anterior"
  }
}
</i18n>
