<template>
  <div class="change-to-ticket-screen">
    <h1>Assunto Muito Urgente</h1>
    <span>
      Se tem muita urgência no assunto a tratar, sugerimos tirar senha em vez de fazer uma marcação.<br /><br />
      <div v-if="areServicesOpen" class="info-msg">
        Não se esqueça que poderá ser atendido(a) dentro de momentos. Por isso, desloque-se aos serviços antes de
        continuar.<br />
      </div>
      <div v-else class="info-msg mt-3">
        De momento os serviços estão fechados.<br />
        Tente de novo dentro do horário de abertura:
        <OpenHours class="mt-3" :week-day="nextAvailableDaySchedule" :schedule="schedule" />
      </div>
    </span>
  </div>
</template>

<script>
import config from '../config'
import OpenHours from '../components/OpenHours'
export default {
  name: 'ChangeToTicketScreen',
  components: {
    OpenHours: OpenHours,
  },
  data: function() {
    return {
      schedule: config.student_hub_open_hours,
    }
  },
  computed: {
    areServicesOpen() {
      return this.$store.getters.areServicesOpen
    },
    nextAvailableDaySchedule() {
      let weekday_now = new Date().getDay() - 1
      if (weekday_now < 0) {
        return 0
      } else {
        for (let i = weekday_now + 1; i < this.schedule.length; i++) {
          if (this.schedule[i].length > 0) {
            return i
          }
        }
        return 0
      }
    },
  },
}
</script>

<style scoped>
.change-to-ticket-screen h1 {
  @apply text-2xl font-bold mb-7 mt-10 text-center;
}
.change-to-ticket-screen {
  @apply p-1;
}
</style>
