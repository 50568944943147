<template>
  <div class="digital-ticket-screen">
    <h2>
      <!-- A tua senha -->
      {{ localeMessages['title'] }}
    </h2>

    <qrcode-vue class="digital_ticket_qr_code" :size="200" :value="ticketHash"></qrcode-vue>

    <h1>
      <!-- Ticket Number -->
      {{ ticketNumber }}
    </h1>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'DigitalTicketScreen',
  components: {
    QrcodeVue,
  },
  computed: {
    ticketHash() {
      return this.$route.params.ticket_hash
    },
    ticketNumber() {
      return this.$route.params.ticket_number
    },
    localeMessages: function() {
      return this.$store.state.locales['digital_ticket']
    },
  },
}
</script>

<style scoped>
.digital-ticket-screen h1 {
  @apply font-bold mb-10 mt-3 text-center;
  font-size: 35px;
}
.digital-ticket-screen h2 {
  @apply text-lg font-bold mt-10;
}
.digital-ticket-screen .digital_ticket_qr_code {
  @apply mt-10 mb-10 text-center flex justify-center;
}
.digital-ticket-screen .digital_ticket_qr_code canvas {
  @apply ml-10;
}
</style>
