<template>
  <div class="loading-screen">
    <div class="white-loader op1"></div>
    <div class="white-loader op2"></div>
    <div class="white-loader op3"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
}
</script>

<style scoped>
.loading-screen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-screen > div {
  width: 2vh;
  height: 2vh;
  border-radius: 100%;
  margin: 1vh;
  border: white solid 0.4vh;
  background-color: white;
}
.op1 {
  animation: loading 1.5s 0.25s step-start infinite;
}
.op2 {
  animation: loading 1.5s 0.5s step-start infinite;
}
.op3 {
  animation: loading 1.5s 0.75s step-start infinite;
}
.white-loader {
  background-color: white;
}
@keyframes loading {
  0%,
  100% {
    background-color: white;
  }
  50% {
    background-color: #212121;
  }
}
</style>
