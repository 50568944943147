<template>
  <div
    class="ticket"
    :class="{ noPost: showPost === false, selected: queueSelected, highlight: highlighted, white: white, dense: dense }"
  >
    <svg
      v-if="queueSelected"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="check"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="check-icon svg-inline--fa fa-check fa-w-16 fa-3x"
    >
      <path
        fill="currentColor"
        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        class=""
      ></path>
    </svg>
    <div v-else-if="showPost" class="text-box">
      <span class="queue-letter mr-1">{{ queue }}</span
      ><span>{{ ticketNumber }}</span>
    </div>
    <div v-if="showPost === false" class="border-2 border-white p-2 rounded-xl flex items-center gap-1">
      <span class="queue-letter">{{ queue }}</span>
      <span>{{ ticketNumber }}</span>
    </div>
    <div v-if="queueSelected === false && showPost" class="post h-5" :class="{ wait: wait }">{{ postString }}</div>
  </div>
</template>

<script>
export default {
  name: 'Ticket',
  props: {
    post: {
      type: String,
      default: '---',
    },
    ticket: {
      type: Number,
      default: 0,
    },
    queue: {
      type: String,
      default: '',
    },
    queueSelected: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    wait: {
      type: Boolean,
      default: false,
    },
    showPost: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    localeMessages: function() {
      return this.$store.state.locales['ticket']
    },
    dense: function() {
      let len = this.ticketNumber.length + this.queue.length
      return len > 4
    },
    ticketNumber: function() {
      if (this.ticket >= 10 && this.ticket < 100) {
        return '0' + this.ticket
      } else if (this.ticket < 10) {
        return '00' + this.ticket
      } else {
        return '' + this.ticket
      }
    },
    postString: function() {
      if (this.wait) {
        return this.localeMessages['wait'] //'Aguarde'
      } else {
        if (this.post !== null && typeof this.post === 'string' && this.post !== '') {
          return this.localeMessages['post'] + ' ' + this.post //'Posto ' + this.post
        } else {
          return '- - -'
        }
      }
    },
  },
}
</script>

<style scoped>
.ticket.white {
  @apply text-white border-white;
}
.ticket {
  @apply text-center font-bold tracking-tight text-lg flex flex-col justify-center;
  /* force min size */
  min-width: 4rem;
  min-height: 4rem;
}
.ticket .text-box {
  @apply text-2xl;
}
.ticket .queue-letter {
  @apply font-normal tracking-normal;
}
.ticket.noPost .text-box {
  @apply text-2xl;
}

.ticket .post {
  @apply text-sm font-normal;
}
.ticket .post.wait {
  @apply text-xs font-semibold;
}
.ticket.selected {
  @apply bg-black;
}
.ticket.selected .check-icon {
  @apply m-2 text-white;
}
.ticket.highlight {
  @apply bg-black text-white;
}
.ticket.dense {
  @apply text-base;
}
</style>
