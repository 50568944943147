<template>
  <div class="time" :class="{ white: white }">
    {{ time }}
  </div>
</template>

<script>
export default {
  name: 'Time',
  props: {
    white: {
      type: Boolean,
      default: false,
    },
    time: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.time {
  @apply max-w-xs text-white bg-black text-center w-full align-middle;
}
.time.white {
  @apply text-black bg-white;
}
</style>
