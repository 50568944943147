import Vue from 'vue'
import Vuex from 'vuex'

import messages from '@/i18n'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import CoreStoreDefaults from '@/fw-modules/fw-core-vue/store/defaults'
import ServiceQflow from '@/fw-modules/fw-core-vue/qflow/services/ServiceQflow'

Vue.use(Vuex)

const state = Object.assign(CoreStoreDefaults.state, {
  api: ServiceQflow,

  // Authentication
  auth: null,
  userTickets: [],
  userAppointments: [],

  services: [],

  // being able to select the services depend of the size of the screen
  // in mobile is forbidden to select services on the homepage
  homepageServicesSelectable: false,

  //service_selection_ticket => The user is selecting a service to get a ticket
  //service_selection_booking => The user is selecting a service to book appointment

  //schedule_selection_type => (for appointments)

  //schedule_selection_confort => (for appointments)
  //schedule_selection_urgent => (for appointments)
  //schedule_selection_all => (for appointments)

  //appointment_details_and_subject
  //appointment_contacts

  navigationPath: [],
  modalPath: [],

  // select timeslot + queue
  timeSlots: [],
  timeSlotsDayView: [],

  // Make Appointment //
  selectedServices: [],
  selectedTimeSlots: [],
  selectedSubjects: [],
  appointmentDetails: '',
  appointmentFullName: '',
  appointmentPhoneNumber: '',
  appointmentEmail: '',
  //For the flow of selecting the subjects first (the object is different to identify the service/queue)
  selectedSubjectsMain: [],

  appointmentContactType: '',
  appointmentContactValue: '',

  smsSent: false,
  smsCode: '',
  smsConfirmationID: '',
  appointmentResult: [], //to show in the confirmation page

  ticketsResult: [], //to show in the confirmation page

  //TODO: after checking the ownership of the phone number:
  isPhoneValidated: false,

  // network loading intdicator
  networkLoading: false,
  windowsFocused: true,

  /* ERROR MESSAGE */
  showError: false,
  errorMessage: '',
  errorTimer: null,

  /* Modal to show map of UC StudentHub */
  mapModalOpen: false,

  //system ready is true when info of all services and queues are loaded
  systemReady: false,

  /* APPOINTMENT(s) STATUS */
  appointmentStatus: null,

  /*
  Tirar senha user:
  [POST] https://api-dev.uc.pt/qflowplus/1.0/add/call.json
  FORM DATA:
  idQueue: 4
  REPLY JSON:
  createDate: "2021-07-14T00:00:00"
  estimatedDate: null
  estimatedTimeLeft: "00:04:30"
  queueDescription: "Licenciaturas"
  queueName: "A"
  serie: 1
  ticketHash: "a3f18564b4505022593fbf66e168879bc89415a7"
  ticketNumber: 1
   */

  /*
  Cancelar senha:
  https://api-dev.uc.pt/qflowplus/1.0/update/call/cancel.json
  Form data:
  idQueue: 4
  ticketHash: a3f18564b4505022593fbf66e168879bc89415a7
  Return:
  true
   */
  locales: messages['pt'],
  selecteLanguage: 'pt',
})
//state.locales = messages['pt']

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
})
