<template>
  <div class="add-details-screen">
    <h1>
      <!-- Fazer Marcação -->
      {{ localeMessages['add_appointment_contact']['title'] }}
    </h1>

    <div class="mt-4">
      <h2>
        <!-- Indique-nos o seu número de telemóvel -->
        {{ localeMessages['add_appointment_contact']['message_option_phone'] }}
      </h2>

      <div class="input-outside">
        <input
          v-model="phoneNumber"
          class="contact-input prefixed"
          :class="{
            invalid: !phoneValid,
          }"
          :placeholder="localeMessages['add_appointment_contact']['placeholder_phone_number']"
        />
        <div class="absolute top-2.5 left-2.5 text-gray-500">+351</div>
      </div>
    </div>

    <div v-if="auth === null" class="mt-4">
      <h2>
        <!-- Indique-nos o seu endereço de e-mail -->
        {{ localeMessages['add_appointment_contact']['message_option_email'] }}
      </h2>
      <div class="input-outside">
        <input
          v-model="email"
          class="contact-input"
          :class="{
            invalid: !emailValid,
          }"
          :placeholder="localeMessages['add_appointment_contact']['placeholder_email']"
        />
      </div>
      <div class="emails-suggestions-box">
        <div
          v-for="(emaildomain, e) in suggestedWebDomains"
          :key="'webdomain_sug_' + e"
          class="email-suggestion"
          @click="applyEmailSuggestion(emaildomain)"
        >
          {{ emaildomain }}
        </div>
      </div>
    </div>

    <div v-if="auth === null && emailValid && phoneValid">
      <h2>
        <!-- Como o podemos contactar? -->
        {{ localeMessages['add_appointment_contact']['subtitle'] }}
      </h2>
      <option-checkbox
        :key="'email_option'"
        :checked="contactOptionValue === 'email'"
        @selected="contactOption('email')"
      >
        <!-- Por e-mail -->
        {{ localeMessages['add_appointment_contact']['option_email'] }}
      </option-checkbox>
      <option-checkbox
        :key="'phone_option'"
        :checked="contactOptionValue === 'phone'"
        @selected="contactOption('phone')"
      >
        <!-- Por telemóvel -->
        {{ localeMessages['add_appointment_contact']['option_phone'] }}
      </option-checkbox>
    </div>

    <div v-if="smsSent && contactOptionValue === 'phone'">
      <div class="text-gray-500 text-sm mb-2 mt-1">
        <!-- Foi enviado um código por SMS para o número -->
        {{ localeMessages['add_appointment_contact']['message_sms_sent_part1'] }}
        <b>{{ contactValue }}</b
        >.<br />
        <!-- Por favor, insira-o abaixo: -->
        {{ localeMessages['add_appointment_contact']['message_sms_sent_part2'] }}
      </div>
      <input
        v-model="smsCode"
        class="contact-input"
        :class="{ invalid: smsCode.length !== 6 }"
        :placeholder="smsCodePlaceholder"
      />

      <div class="info-msg">
        <b
          >{{ localeMessages['add_appointment_contact']['sms_not_received'] }}
          <!-- Não recebeu o código SMS? --></b
        ><br />
        <div v-if="secondsToRetry === 0">
          <button class="mt-2 sms-button" @click="retrySMS">
            {{ localeMessages['add_appointment_contact']['ask_new_sms_code'] }}
            <!-- Pedir novo código SMS -->
          </button>
        </div>
        <div v-else>
          <!-- Peça um novo código dentro de -->
          {{ localeMessages['add_appointment_contact']['retry_sms_msg1']
          }}<b
            >{{ secondsToRetry }}
            <!-- segundos -->
            {{ localeMessages['add_appointment_contact']['retry_sms_msg2'] }}
          </b>
        </div>
      </div>
    </div>
    <div v-if="auth === null" class="text-xs mt-5 py-2 text-justify">
      <h2>
        Termos e condições
      </h2>
      Ao realizar um agendamento está a autorizar a UC a proceder ao tratamento dos dados pessoais fornecidos, dos quais
      confirma ser titular. Declara ainda tomar conhecimento das condições gerais de atendimento: O agendamento é
      realizado até ao dia anterior ou enquanto houver horários disponíveis. Se chegar após 10 minutos da hora, o
      atendimento poderá ser remarcado para outro dia e horário. O atendimento está sujeito ao cumprimento das normas de
      segurança e proteção aí indicadas. De acordo com a legislação tem prioridade quem tiver convocatória ou marcação
      prévia.
      <a href="https://www.uc.pt/protecao-de-dados/politica_de_privacidade" class="is-link underline"
        >[Política de Privacidade]</a
      >
    </div>
  </div>
</template>

<script>
import OptionCheckbox from '../components/OptionCheckbox'
import config from '../config'
export default {
  name: 'AddContactDetailsScreen',
  components: {
    'option-checkbox': OptionCheckbox,
  },
  data: function() {
    return {
      //contactValue: '',
      emailsTerminationsSuggestions: config.emailsWebdomainSuggestions,
      retrySmsTimer: null,
      secondsToRetry: 30,
    }
  },
  computed: {
    phoneNumber: {
      get() {
        return this.$store.state.appointmentPhoneNumber
      },
      set(value) {
        this.$store.commit('updateAppointmentPhoneNumber', value)
      },
    },
    email: {
      get() {
        return this.$store.state.appointmentEmail
      },
      set(value) {
        this.$store.commit('updateAppointmentEmail', value)
      },
    },
    localeMessages: function() {
      return this.$store.state.locales
    },
    smsCodePlaceholder: function() {
      return this.localeMessages['add_appointment_contact']['placeholder_sms_code'] // Código SMS
    },
    suggestedWebDomains: function() {
      if (this.email.includes('@')) {
        let webdomain = '@' + this.email.split('@')[1].toLowerCase()
        if (webdomain.length < 2) {
          return this.emailsTerminationsSuggestions.slice(0, 5)
        } else {
          return this.emailsTerminationsSuggestions
            .filter(element => {
              return element.startsWith(webdomain) && element !== webdomain
            })
            .slice(0, 5)
        }
      }
      return []
    },
    contactOptionValue: function() {
      return this.$store.state.appointmentContactType
    },
    // contactPlaceholder: function() {
    //   return this.contactOptionValue === 'phone'
    //     ? this.localeMessages['add_appointment_contact']['placeholder_phone_number'] //Número de telemóvel
    //     : this.localeMessages['add_appointment_contact']['placeholder_email'] //Endereço de e-mail
    // },
    auth: function() {
      return this.$store.state.auth
    },
    // isContactValid: function() {
    //   return this.$store.getters.isContactValid
    // },
    smsSent: function() {
      return this.$store.state.smsSent
    },
    smsCode: {
      get: function() {
        return this.$store.state.smsCode
      },
      // setter
      set: function(smsCode) {
        this.$store.commit('setSMScode', smsCode)
      },
    },
    contactValue: {
      get: function() {
        return this.$store.state.appointmentContactValue
      },
      // setter
      set: function(contactValue) {
        this.$store.commit('setAppointmentContactValue', contactValue)
      },
    },
    phoneValid() {
      return this.phoneNumber.length === 9 && this.phoneNumber.startsWith('9')
    },
    emailValid() {
      //from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.email).toLowerCase())
    },
  },
  watch: {
    smsSent() {
      //this.$store.commit('setAppointmentContactValue', newValue)
      this.startSmsTimer()
    },
  },
  methods: {
    retrySMS() {
      if (this.isContactValid) {
        // send new code
        this.secondsToRetry = 30
        this.$store.dispatch('sendSMSCode', '+351' + this.contactValue)
        this.startSmsTimer()
      }
    },
    startSmsTimer() {
      if (this.retrySmsTimer !== null) {
        clearInterval(this.retrySmsTimer)
      }
      this.retrySmsTimer = setInterval(() => {
        this.secondsToRetry -= 1
        if (this.secondsToRetry === 0) {
          clearInterval(this.retrySmsTimer)
        }
      }, 1000)
    },
    contactOption(option) {
      //this.$store.commit('setAppointmentContactType', option)
      this.$store.dispatch('changeContactTypeAppointment', option)
      if (option == 'email') {
        this.$store.commit('setAppointmentContactValue', this.email)
      } else {
        this.$store.commit('setAppointmentContactValue', this.phoneNumber)
      }
    },
    applyEmailSuggestion(webdomain) {
      this.email = this.email.split('@')[0] + webdomain
    },
  },
}
</script>

<style scoped>
.sms-button {
  @apply py-2 px-6 font-bold text-center h-10 shadow text-sm;
  @apply text-black cursor-pointer select-none bg-white rounded-lg ring-0;
}
.add-details-screen {
  @apply text-left px-2 py-4;
}
.add-details-screen .input-outside {
  @apply relative;
}
.add-details-screen textarea,
input {
  @apply w-full px-3 py-2 border-2 border-gray-200 mb-4;
}
.add-details-screen textarea.prefixed,
input.prefixed {
  @apply pl-14;
}
.add-details-screen textarea:focus,
input:focus {
  outline-color: rgba(32, 164, 149, 1);
}
.add-details-screen textarea.invalid:focus,
input.invalid:focus {
  outline-color: #d73939;
}
.add-details-screen textarea.invalid,
input.invalid {
  outline-color: #d73939;
}
.add-details-screen h1 {
  @apply text-2xl font-bold mb-4 text-center;
}
.add-details-screen h2 {
  @apply text-lg font-bold mb-1;
}
.email-suggestion {
  @apply rounded-full px-2 py-1 text-xs font-bold inline-block bg-gray-100 text-gray-600 m-1.5 cursor-pointer;
}
.emails-suggestions-box {
  @apply overflow-y-hidden h-10;
}
.contact-input {
  @apply mb-2;
}
</style>
