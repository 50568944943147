<template>
  <LayoutDefault :tapbar="false" :main-sidebar="false" :header-session-nav="true">
    <template #main-content>
      <!--
      <div>
        <BlockUCId v-if="userLoggedIn" class="p-4" />
      </div>
      -->
      <div>
        <!-- loading animation for homepage -->
        <home-loading v-if="currentPage === 'home' && networkLoading && systemReady === false"></home-loading>
        <!-- general network/loading animation -->
        <loading-screen v-else-if="networkLoading"></loading-screen>
        <!-- Main content -->
        <div v-else class="container mx-auto p-4 pb-60 max-w-5xl">
          <!-- homepage: queues + user dashboard -->
          <home v-if="currentPage === 'home'"></home>
          <!-- Select subject Screen -->
          <subject-selection-screen v-else-if="currentPage === 'subject_selection_screen'"></subject-selection-screen>
          <!-- Select one or multiple services page -->
          <service-selection-screen
            v-else-if="currentPage === 'service_selection_ticket' || currentPage === 'service_selection_booking'"
          ></service-selection-screen>
          <!-- Show all appointments slots -->
          <time-selection-screen v-else-if="currentPage === 'all_time_options'"></time-selection-screen>
          <!-- Select SmartOptions type: comfort, urgent or very urgent -->
          <smart-suggestions-type v-else-if="currentPage === 'schedule_selection_type'"></smart-suggestions-type>
          <!-- Select SmartOptions result -->
          <smart-suggestions-result
            v-else-if="currentPage === 'smart_options_comfort' || currentPage === 'smart_options_urgent'"
          ></smart-suggestions-result>
        </div>
        <!-- End main content -->
        <!-- BottomBar Navigation + modal screen -->
        <bottom-bar :services-selectable="servicesSelectable" :modal="currentModalPage !== null">
          <!-- Loading Animation for modal -->
          <loading-screen v-if="currentModalPage !== null && networkLoading"></loading-screen>
          <!-- Digital Ticket -->
          <digital-ticket-screen
            v-if="!networkLoading && currentModalPage === 'digital_ticket'"
          ></digital-ticket-screen>
          <!-- Confirm e-mail -->
          <appointment-status-screen
            v-if="!networkLoading && currentModalPage === 'appointment_status'"
          ></appointment-status-screen>
          <!-- SmartOptions Very Urgent - Change to ticket -->
          <change-to-ticket-screen
            v-if="!networkLoading && currentModalPage === 'smartoption_very_urgent'"
          ></change-to-ticket-screen>
          <!-- Confirm e-mail -->
          <confirm-email-screen v-if="!networkLoading && currentModalPage === 'confirm_email'"></confirm-email-screen>
          <!-- Appointment details + subject of appointment -->
          <select-subject-screen
            v-if="!networkLoading && currentModalPage === 'appointment_details_and_subject'"
          ></select-subject-screen>
          <!-- Appointment contact information -->
          <details-screen v-else-if="!networkLoading && currentModalPage === 'appointment_contacts'"></details-screen>
          <!-- Appointment confirmation screen -->
          <appointments-confirmation-screen
            v-else-if="!networkLoading && currentModalPage === 'confirmation_screen'"
          ></appointments-confirmation-screen>
          <!-- Ticket confirmation screen -->
          <tickets-confirmation-screen
            v-else-if="!networkLoading && currentModalPage === 'confirmation_screen_tickets'"
          ></tickets-confirmation-screen>
          <!-- Needs Authentication screen -->
          <needs-authentication-screen
            v-else-if="!networkLoading && currentModalPage === 'needsAuthentication'"
          ></needs-authentication-screen>
        </bottom-bar>
      </div>
    </template>
    <template #header-toolbar-end>
      <button
        class="relative align-middle md:hidden focus:outline-none rounded-full h-full p-2 flex items-center justify-center md:bg-gray-100 ml-3"
        @click="$router.push('/account')"
      >
        <fw-icon-account-box class="w-6 h-6"></fw-icon-account-box>
      </button>
      <b-dropdown ref="languageselector" aria-role="list" scrollable position="is-bottom-left">
        <div slot="trigger">
          <button
            class="text-black relative align-middle focus:outline-none rounded-full h-full p-2 flex items-center justify-center md:bg-gray-100 ml-3"
          >
            <fw-label class="text-gray-800 pl-1 pr-1">{{ selectedLanguage }}</fw-label>
            <svg
              class="fill-current w-4 h-4 mr-0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
            </svg>
          </button>
        </div>
        <b-dropdown-item custom aria-role="menuitem" paddingless>
          <fw-button
            type="simple"
            size="sm"
            class="w-full px-4 flex items-center gap-2"
            label="PT"
            @click.native="setLanguage('pt')"
          >
            Português (PT)
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item custom aria-role="menuitem" paddingless>
          <fw-button
            type="simple"
            size="sm"
            class="w-full px-4 flex items-center gap-2"
            label="EN"
            @click.native="setLanguage('en')"
          >
            English (EN)
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <template #modals>
      <MapModal
        :open="mapModalOpen"
        :geo-lat-long="{
          lat: config.student_hub_gps[0],
          long: config.student_hub_gps[1],
        }"
        @close="closeMapModal"
      />
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
//import BlockUCId from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUCIdQRCode'
//import TapbarMain from '@/components/tapbars/TapbarMain'
import MapModal from '@/fw-modules/fw-core-vue/ui/components/modals/ModalLeafletMap'

import BottomBar from '../components/BottomBar'
import Home from './Home'
import SelectServicesScreen from './SelectServicesScreen'
import SelectTimeScreen from './SelectTimeScreen'
import LoadingScreen from './LoadingScreen'
import SelectSubjectScreen from './SelectSubjectScreen'
import AddContactDetailsScreen from './AddContactDetailsScreen'
import ConfirmationScreen from './AppointmentConfirmationScreen'
import ConfirmationScreenTickets from './TicketConfirmationScreen'
import NeedsAuthenticationScreen from './NeedsAuthenticationScreen'
import HomeLoading from './HomeLoading'
import SmartSuggestionsScreen from './SmartScheduleSelectionScreen'
import SmartOptionsListScreen from './SmartScheduleResultScreen'
import ConfirmEmailScreen from './ConfirmEmailScreen'
import AppointmentStatusScreen from './AppointmentStatusScreen'
import DigitalTicketScreen from './DigitalTicketScreen'
import ChangeToTicketScreen from './ChangeToTicketScreen'
import SubjectSelectionScreen from './SelectSubjectMainScreen'
import config from '@/config'
export default {
  components: {
    LayoutDefault,
    //TapbarMain,
    //BlockUCId,
    SelectSubjectScreen,
    'bottom-bar': BottomBar,
    home: Home,
    'home-loading': HomeLoading,
    'service-selection-screen': SelectServicesScreen,
    'time-selection-screen': SelectTimeScreen,
    'loading-screen': LoadingScreen,
    'details-screen': AddContactDetailsScreen,
    'appointments-confirmation-screen': ConfirmationScreen,
    'needs-authentication-screen': NeedsAuthenticationScreen,
    'tickets-confirmation-screen': ConfirmationScreenTickets,
    'smart-suggestions-type': SmartSuggestionsScreen,
    'smart-suggestions-result': SmartOptionsListScreen,
    'confirm-email-screen': ConfirmEmailScreen,
    'appointment-status-screen': AppointmentStatusScreen,
    'digital-ticket-screen': DigitalTicketScreen,
    'change-to-ticket-screen': ChangeToTicketScreen,
    MapModal: MapModal,
    'subject-selection-screen': SubjectSelectionScreen,
  },

  data() {
    return {
      loading: false,
      modal: false,
      config: config,
    }
  },

  computed: {
    selectedLanguage() {
      return this.$store.state.selecteLanguage.toUpperCase()
    },
    mapModalOpen() {
      return this.$store.state.mapModalOpen
    },
    systemReady() {
      return this.$store.state.systemReady
    },
    user() {
      return this.$store.getters.getUser
    },
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    currentModalPage() {
      return this.$store.getters.currentModalPage
    },
    servicesSelectable() {
      return this.$store.state.homepageServicesSelectable
    },
    currentPage() {
      return this.$store.getters.currentPage
    },
    networkLoading() {
      return this.$store.state.networkLoading
    },
    navigationText() {
      return this.$store.getters.navigationText
    },
  },

  watch: {
    systemReady: function(newCalue, oldValure) {
      let prefLanguage = localStorage.getItem('prefLanguage')
      if (prefLanguage && prefLanguage !== this.selectedLanguage) {
        this.setLanguage(prefLanguage, false)
      }
      // this is triggered when all info about queues and services are loaded
      // to be used by methods depended of those values
      console.log('systemReady triggered', newCalue, oldValure)
      let currentRouteName = this.$route.name
      if (currentRouteName === 'status_appointments' && this.$route.params.appointments_group_id) {
        //Get status of a group of appointments
        this.$store.dispatch('getAppointmentStatusByGroup', this.$route.params.appointments_group_id)
        this.$store.commit('modalOpen', 'appointment_status')
      } else if (currentRouteName === 'status_appointment' && this.$route.params.appointment_ticket_hash) {
        //Get status of one appointment
        this.$store.dispatch('getAppointmentStatus', this.$route.params.appointment_token)
        this.$store.commit('modalOpen', 'appointment_status')
      } else if (
        currentRouteName === 'digital_ticket' &&
        this.$route.params.ticket_hash &&
        this.$route.params.ticket_number
      ) {
        this.$store.commit('modalOpen', 'digital_ticket')
      }
    },
  },

  mounted() {
    if (this.userLoggedIn) {
      this.$store.dispatch('setUser')
    }

    let currentRouteName = this.$route.name

    if (currentRouteName === 'confirm_appointments' && this.$route.params.appointments_group_id) {
      //Confirm appointments
      this.$store.dispatch('confirmAppointments', this.$route.params.appointments_group_id)
    } else if (currentRouteName !== 'status_appointment' && currentRouteName !== 'status_appointments') {
      // Reset navigation when coming home
      this.$store.commit('resetNavigation')
    }
    ServiceSettings.checkAppVersion()
  },
  beforeCreate() {
    this.$store.dispatch('start')
  },

  methods: {
    closeMapModal() {
      this.$store.commit('setMapModalOpen', false)
    },
    setLanguage(language, save = true) {
      this.$store.commit('setLanguage', language)
      if (save) {
        localStorage.setItem('prefLanguage', language)
        this.$refs.languageselector.toggle()
      }
    },
  },
}
</script>
